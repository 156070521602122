import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";
import { ApiError } from "../../utils/server-utils";
import { MainState } from "../main-model";
import {
  AnyTemplateAction,
  fetchTemplatesRequest,
  fetchTemplatesSuccess,
  fetchTemplatesError,
  duplicateTemplateRequest,
  duplicateTemplateError, duplicateTemplateSuccess
} from "./templates-actions";
import {
  fetchTemplateFromApi,
  fetchTemplatesFromApi
} from "./templates-service";
import { fetchProjectsRequest } from "../projects/projects-actions";

export const fetchTemplatesEpic: Epic<
  AnyTemplateAction,
  ActionType<typeof fetchTemplatesSuccess | typeof fetchTemplatesError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchTemplatesRequest)),
    switchMap(action => {
      const prom = fetchTemplatesFromApi();
      return from(prom).pipe(
        switchMap(templates => of(fetchTemplatesSuccess(templates))),
        catchError((error: ApiError) => {
          console.error(error);
          return of(fetchTemplatesError(String(error.data)));
        })
      );
    })
  );

export const duplicateTemplateEpic: Epic<
  AnyTemplateAction,
  //ActionType<typeof fetchProjectsRequest | typeof duplicateTemplateError>,
  any,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(duplicateTemplateRequest)),
    switchMap(action => {
      return from(fetchTemplateFromApi(action.payload.templateId)).pipe(
        switchMap(templateParts => of(duplicateTemplateSuccess(templateParts, action.payload.projectId, action.payload.projectLabel))),
        catchError((error: ApiError) => {
          console.error(error);
          return of(duplicateTemplateError(String(error.data)));
        })
      )
    })
  );

export const templatesEpic = combineEpics(
  fetchTemplatesEpic,
  duplicateTemplateEpic
);
