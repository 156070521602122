import React, { FunctionComponent } from "react";
import { QuestionTooltip } from "../../components/question-tooltip";
import {
  getQuestion,
  getComment,
  StepViewComponentProps
} from "../../model/steps/steps-model";
import { getSubstep } from "../../model/substeps/substeps-model";
import styles from "../../styles/modules/ns-step-view.module.scss";
import { useStepViewState } from "../../utils/step-views-utils";
import { OnlyOneOptionAlert } from "../only-one-option-alert";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";

export const VerticalListStepView: FunctionComponent<StepViewComponentProps & {
  radioMode?: boolean;
}> = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange,
  onSkip,
  radioMode
}) => {
  // const [possibleOptions, setPossibleOptions] = useState({});

  const [formState, onChange, error, pending] = useStepViewState(
    substepId,
    substeps,
    steps,
    selectedOptions,
    onOptionsChange,
    onSkip,
    radioMode
  );

  if (!substepId) {
    return null;
  }

  const formStateValues = Object.values(formState);
  const displayAlert = formStateValues.find(({ option }) => option.isNone);

  if (
    !pending &&
    (formStateValues.find(({ option }) => option.isWarning) ||
      formStateValues.length === 0 ||
      error)
  ) {
    return <NoOptionAlert show={true} />;
  }
  return (
    <div className={` position-relative ${styles.nsstepview}`}>
      <MaskingSpinner show={pending} />
      <h3 className="project_new mx-auto mb-4">
        <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
        <QuestionTooltip
          tooltipText={getComment(getSubstep(substepId, substeps).stepLevel, steps)}
          questionClassName={`ico-question d-inline-block ml-3`}
        />
      </h3>
      <OnlyOneOptionAlert formStateValues={formStateValues} />
      {formStateValues
        // .filter(({ option }) => !option.isNone)
        .map(check => (
          <div key={check.id} className="linecheckbox">
            <div className="linecheckbox_left">{check.label}</div>
            <div className="linecheckbox_right">
              <QuestionTooltip
                tooltipText={check.comment ?? null}
                questionClassName={`${styles["nsstepview_icoquestion"]} ico-question`}
              />
              <label className="linecheckbox_container htmlFor={check.id}">
                <input
                  type={radioMode ? "radio" : "checkbox"}
                  name="piece"
                  onChange={event => {
                    onChange(event.target.value, event.target.checked);
                  }}
                  checked={check.checked}
                  value={check.id}
                  id={check.id}
                />
                <span className="linecheckbox_container_checkmark"></span>
              </label>
            </div>
            <div className="linecheckbox_container_clear"></div>
          </div>
        ))}
    </div>
  );
};
