import { MainState } from "../main-model";
import { AnyI18NAction, POPULATE } from "./i18n-actions";

export interface I18NState {
  texts: { [index: string]: string };
  pictures: { [index: string]: string };
}

export const initialState = {
  texts: {},
  pictures: {}
};

export const translate = (code: string, state: MainState) => {
  const translation = state.i18n.texts[code];
  return translation || code;
};

export const translate2 = (code: string, state: I18NState) => {
  const translation = state.texts[code];
  return translation || code;
};

export const i18nReducer = (
  state: I18NState = initialState,
  action: AnyI18NAction
) => {
  switch (action.type) {
    case POPULATE:
      return action.payload.textsAndPictures;
  }

  return state;
};
