import React, {FunctionComponent, useEffect} from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Dispatch} from "redux";
import {Breadcrumb} from "../components/breadcrumb";
import stepViews from "../components/steps-views";
import {MainState} from "../model/main-model";
import {setNewProjectData} from "../model/projects/projects-actions";
import {
    AnyStepOptions,
    getComment,
    getQuestion,
    getStepForLevel,
    getStepList
} from "../model/steps/steps-model";
import styles from "../styles/modules/new-project-content.module.scss";
import {PropsType} from "../utils/ts-utils";
import {QuestionTooltip} from "../components/question-tooltip";
import {progressSubstepInit} from "../model/progressBar/progress-bar-actions";
import {Tra} from "../components/tra";
import {getSubstep} from "../model/substeps/substeps-model";

type OwnProps = {} & RouteComponentProps<{}>; //RouteComponentProps<{ projectId: string; substep: string }>;

const mapStateToProps = (state: MainState) => ({
  // project: selector(state, props.match.params.projectId),
  newProjectData: state.projects.newProjectData,
  steps: getStepList(state.steps),
  substeps: state.substeps.byId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateNewProjectData: (projectName: string, opts: AnyStepOptions[] | null) =>
    dispatch(setNewProjectData(projectName, opts)),
  initProgressbar: () => dispatch(progressSubstepInit())
});

export const Component: FunctionComponent<PropsType<OwnProps,
  typeof mapStateToProps,
  typeof mapDispatchToProps>> = ({
                                   match,
                                   steps,
                                   history,
                                   updateNewProjectData,
                                   initProgressbar,
                                   newProjectData,
                                   substeps
                                 }) => {

  useEffect(() => {
    initProgressbar();
  }, [initProgressbar]);

  useEffect(() => {
    updateNewProjectData("", null);
  }, [updateNewProjectData]);

  if (!steps) {
    return null;
  }
  // const [formState, updateFormState] = useState<{
  //   name: string,
  //   options: AnyStepOptions | null}>({
  //     name: '',
  //     options: null
  //   });

  // const update = () => {
  //   console.log('input', inputState);
  //   if (inputState === "" || !optionsState) {
  //     return;
  //   }
  //   updateNewProjectData(inputState, optionsState);
  //   //setSubstepOptions(substep.id, opts)
  // };

  const updateName = (name: string) => {
    // if (!name) {
    //   return;
    // }

    updateNewProjectData(
      name,
      newProjectData ? newProjectData.selectedOptions : null
    );
  };

  const updateOptions = (opts: AnyStepOptions[]) => {
    updateNewProjectData(newProjectData ? newProjectData.name : "", opts);
  };

  // console.log(match.params, project)
  // if (!project) {
  //   history.push("/");
  //   return null;
  // }

  // const substep = getSubstep(match.params.substep, substeps);

  // if (!substep) {
  //   const defaultSubstep =
  //     getCurrentSubstepForProject(project.id, substeps) ||
  //     getRootSubstep(project.id, substeps);
  //   return (
  //     <Redirect to={`/projects/${project.id}/substeps/${defaultSubstep.id}`} />
  //   );
  // }
  const rootStepLevel = 0;
  const ViewComponent =
    stepViews[getStepForLevel(rootStepLevel, steps).viewType];
  return (
    <div className={styles.newproject}>
      <Breadcrumb/>
      <h2><Tra code="title-project-new" /></h2>
      <div className={styles.newproject_new}>
          <p className={styles.right_start_text}>
              <Tra code="text-project-new" />
          </p>
        <label className={styles.newproject_label}><Tra code="lbl-form-project-name" /></label>
        <input
          className={`${styles["newproject_input"]} form-control`}
          type="text"
          onChange={evt => updateName(evt.target.value)}
        />
      </div>
      <h3 className={`${styles["newproject_title"]} project_new m-auto`}>
        <div dangerouslySetInnerHTML={{ __html: getQuestion(0, steps) }} />
        <QuestionTooltip
          tooltipText={getComment(0, steps)}
          questionClassName={`${styles["newproject_icoquestion"]} ico-question`}
        />
      </h3>
      <ViewComponent
        substepId={null}
        substeps={substeps}
        steps={steps}
        selectedOptions={[]}
        onOptionsChange={updateOptions}
      />
    </div>
  );
};

export const NewProjectContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
