import {Indexed} from "../../utils/collection-utils";
import {HydraCollection} from "../../utils/hydra-utils";
import {apiGet, apiPost} from "../../utils/server-utils";
import {
  AnyStepOptions,
  Brand,
  MeasuringstepOptions,
  PIOption,
  Step
} from "../steps/steps-model";
import {getProjectSubstepDatas, Substep} from "./substeps-model";

export const saveSubparts = (
  projectId: string,
  backendProjectId: string,
  substeps: Indexed<Substep>,
  steps: Step[]
) => {
  const data = getProjectSubstepDatas(projectId, substeps, steps, true);
  const dataByType = data.map(subpart =>
    subpart.reduce<{ [index: string]: AnyStepOptions }>(
      (acc, val) =>
        val
          ? {
            ...acc,
            [val.type]: val
          }
          : acc,
      {}
    )
  );

  const dataBodies = dataByType.map(byType => {
    return {
      codePI: (byType["PI"] as PIOption).codePI,
      project: backendProjectId,
      labelPI: byType["PI"].label,
      optionPI: byType["PI"] ? byType["PI"].value : undefined,
      optionTP: byType["TP"] ? byType["TP"].value : undefined,
      optionNS: byType["NS"] ? byType["NS"].value : undefined,
      optionES: byType["ES"] ? byType["ES"].value : undefined,
      optionTE: byType["TE"] ? byType["TE"].value : undefined,
      optionMA: byType["MA"] ? byType["MA"].value : undefined,
      optionPR: byType["PR"] ? byType["PR"].value : undefined,
      optionPO: byType["PO"] ? byType["PO"].value : undefined,
      paramArea: byType["MS"]
        ? (byType["MS"] as MeasuringstepOptions).data
        : undefined,
      collection: byType["MO"] ? byType["MO"].value : undefined,
      product: byType["CO"] ? byType["CO"].value : undefined
    };
  });

  console.log("data bodies", dataBodies);

  return Promise.all(
    dataBodies.map(body => apiPost<typeof body, {}>(`/project_parts`, body))
  )
};

export const convertDataToRecap = (substepCreationData: Substep[][]) => {
  let stepListForRecap: StepListForRecap = [];
  // eslint-disable-next-line array-callback-return
  substepCreationData.map(x => {
    let stepForRecap: StepForRecap = {
      id: "",
      labelPI: "",
      codePI: 0,
      optionPI: "",
      optionTP: "",
      optionNS: "",
      optionES: "",
      optionTE: "",
      optionMA: "",
      optionPR: "",
      optionPO: "",
      optionMO: "",
      area: 0,
      paramArea: {total: 0, in: [], out: [], unitSystem: null},
      product: "",
      projectId: ""
    };
    stepForRecap.id = x.find(e => e.stepLevel === 11)!.parent;
    stepForRecap.labelPI = x.find(e => e.stepLevel === 1)!.creationData!.label;
    stepForRecap.codePI = (x.find(e => e.stepLevel === 1)!
      .creationData as PIOption).codePI;
    stepForRecap.optionPI = x.find(e => e.stepLevel === 1)!.creationData!.value;
    stepForRecap.optionTP = x.find(e => e.stepLevel === 2)!.creationData!.value;
    stepForRecap.optionNS = x.find(e => e.stepLevel === 3)!.creationData!.value;
    stepForRecap.optionES = x.find(e => e.stepLevel === 4)!.creationData!.value;
    stepForRecap.optionTE = x.find(e => e.stepLevel === 5)!.creationData!.value;
    stepForRecap.optionMA = x.find(e => e.stepLevel === 6)!.creationData!.value;
    stepForRecap.optionPR = x.find(e => e.stepLevel === 7)!.creationData!.value;
    stepForRecap.optionPO = x.find(e => e.stepLevel === 8)!.creationData!.value;
    stepForRecap.optionMO = x.find(e => e.stepLevel === 9)!.creationData!.value;
    let area = (x.find(e => e.stepLevel === 10)!
      .selectedOptions![0] as MeasuringstepOptions)!.data!.total; // FIXME
    stepForRecap.paramArea = (x.find(e => e.stepLevel === 10)!
        .selectedOptions![0] as MeasuringstepOptions)!.data!; // FIXME
    stepForRecap.product = x.find(e => e.stepLevel === 10)!.creationData!.value;
    stepListForRecap.push(stepForRecap);
    stepForRecap.area = Math.ceil(area*100)/100;
  });

  return stepListForRecap;
};

export const convertDataToProgressBar = (substepCreationData: Substep[]) => {
  let stepForToProgressBar: StepForRecap = {
    id: "",
    codePI: 0,
    labelPI: "",
    optionPI: "",
    optionTP: "",
    optionNS: "",
    optionES: "",
    optionTE: "",
    optionMA: "",
    optionPR: "",
    optionPO: "",
    optionMO: "",
    area: 0,
    paramArea: {total: 0, in: [], out: [], unitSystem: null},
    product: "",
    projectId: ""
  };

  stepForToProgressBar.id = Object.values(substepCreationData).find(
    e => e.stepLevel === 1
  )?.id;
  stepForToProgressBar.labelPI = Object.values(substepCreationData).find(
    e => e.stepLevel === 1
  )?.creationData?.label;
  stepForToProgressBar.optionPI = Object.values(substepCreationData).find(
    e => e.stepLevel === 1
  )?.creationData?.value;
  stepForToProgressBar.optionTP = Object.values(substepCreationData).find(
    e => e.stepLevel === 2
  )?.creationData?.value;
  stepForToProgressBar.optionNS = Object.values(substepCreationData).find(
    e => e.stepLevel === 3
  )?.creationData?.value;
  stepForToProgressBar.optionES = Object.values(substepCreationData).find(
    e => e.stepLevel === 4
  )?.creationData?.value;
  stepForToProgressBar.optionTE = Object.values(substepCreationData).find(
    e => e.stepLevel === 5
  )?.creationData?.value;
  stepForToProgressBar.optionMA = Object.values(substepCreationData).find(
    e => e.stepLevel === 6
  )?.creationData?.value;
  stepForToProgressBar.optionPR = Object.values(substepCreationData).find(
    e => e.stepLevel === 7
  )?.creationData?.value;
  stepForToProgressBar.optionPO = Object.values(substepCreationData).find(
    e => e.stepLevel === 8
  )?.creationData?.value;
  stepForToProgressBar.optionMO = Object.values(substepCreationData).find(
    e => e.stepLevel === 9
  )?.creationData?.value;
  stepForToProgressBar.area = (Object.values(substepCreationData).find(
    e => e.stepLevel === 11
  )?.creationData as MeasuringstepOptions)?.data?.total;
  stepForToProgressBar.product = Object.values(substepCreationData).find(
    e => e.stepLevel === 10
  )?.creationData?.value;
  stepForToProgressBar.projectId = Object.values(substepCreationData).find(
    e => e.stepLevel === 1
  )?.projectId;

  return stepForToProgressBar;
};

export type StepForRecap = {
  id: string | null | undefined;
  codePI: number | null | undefined;
  labelPI: string | null | undefined;
  optionPI: string | null | undefined;
  optionTP: string | null | undefined;
  optionNS: string | null | undefined;
  optionES: string | null | undefined;
  optionTE: string | null | undefined;
  optionMA: string | null | undefined;
  optionPR: string | null | undefined;
  optionPO: string | null | undefined;
  optionMO: string | null | undefined;
  area: number | null | undefined;
  paramArea: { total: number | null | undefined, in: Array<Object>, out: Array<Object>; unitSystem: string | null | undefined};
  product: string | null | undefined;
  projectId: string | null | undefined;
};
export type StepListForRecap = StepForRecap[];

export const getBrandsFromApi = () => {
  return apiGet<HydraCollection<Brand>>("/brands?order[sortOrder]=asc").then(
    response => response.data["hydra:member"]
  );
};
