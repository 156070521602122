import React, { useEffect, useRef, useState } from "react";
import {
  getComment,
  getQuestion,
  isStdStepOptions,
  ServerOption,
  StepViewComponent
} from "../../model/steps/steps-model";
import { fetchOptionsForStep } from "../../model/steps/steps-service";

import styles from "../../styles/modules/es-step-view.module.scss";
import { QuestionTooltip } from "../question-tooltip";
import { usePromise } from "../../utils/react-utils";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";
import { getSubstep } from "../../model/substeps/substeps-model";

export const ESStepView: StepViewComponent = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange
}) => {
  type FormState = {
    selectedIndex?: number;
    max?: number;
    possibleOptions?: ServerOption[];
  };

  const [formState, setFormState] = useState<FormState>({});

  const inputEl = useRef<HTMLInputElement>(null);

  const [options, error, pending] = usePromise(
    () => fetchOptionsForStep(substepId, substeps, steps),
    null,
    [substepId]
  );

  useEffect(() => {
    if (!options) {
      return;
    }
    if (!isStdStepOptions(options)) {
      throw new Error("wrong option type");
    }

    if(formState.selectedIndex === undefined) {

    const opts = selectedOptions;
    const selectedIndex = opts
      ? options.findIndex(curr => curr.id === opts[0].id)
      : 0;

      setFormState({
        selectedIndex,
        max: options.length - 1,
        possibleOptions: options
      });

      onOptionsChange([options[selectedIndex]]);
    }
  }, [formState.selectedIndex, onOptionsChange, options, selectedOptions]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState: FormState = {
      ...formState,
      selectedIndex: Number(event.target.value)
    };
    setFormState(newState);

    if (newState.possibleOptions && newState.selectedIndex) {
      onOptionsChange([newState.possibleOptions[newState.selectedIndex]]);
    } else if (newState.possibleOptions) {
      onOptionsChange([newState.possibleOptions[0]]);
    }
  };

  return (
    <div className={`${styles["es-step-view"]} position-relative`}>
      <NoOptionAlert
        show={!pending && ((options?.length === 0 ?? true) || error)}
      />
      <MaskingSpinner show={pending} />
      <div className={`${styles["title_container"]}`}>
        <h3 className="project_new mx-auto mb-4">
          <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
          <QuestionTooltip
            tooltipText={getComment(
              getSubstep(substepId, substeps).stepLevel,
              steps
            )}
            questionClassName={`${styles["tpstepview_icoquestion"]} ico-question d-inline-block ml-3`}
          />
        </h3>
      </div>
      {formState.max ? (
        <input
          ref={inputEl}
          className="custom-range form-range"
          type="range"
          onChange={onChange}
          value={formState.selectedIndex}
          min="0"
          max={formState.max}
        />
      ) : null}
      {/* <p>
        {formState.possibleOptions
          ? formState.possibleOptions[formState.selectedIndex || 0].label
          : null}
      </p> */}
      <div className={`${styles["labels_container"]}`}>
        <NoOptionAlert
          show={
            !pending &&
            formState &&
            formState.possibleOptions &&
            (Object.values(formState.possibleOptions).length === 0 || error)
          }
        />
        {formState && formState.possibleOptions
          ? Object.values(formState.possibleOptions).map((check, index) => {
              let labelStyle = null;
              switch (index) {
                case 0:
                  labelStyle = "support-state_information--new";
                  break;
                case 1:
                  labelStyle = "support-state_information--good";
                  break;
                case 2:
                  labelStyle = "support-state_information--old";
                  break;
              }
              const displayedCommentStyle =
                formState.selectedIndex !== index ? "not-displayed" : null;
              return (
                <div key={check.id}>
                  {check.label}
                  <div
                    className={`${styles["support-state_information"]} ${
                      labelStyle ? styles[labelStyle] : ""
                    } ${
                      displayedCommentStyle ? styles[displayedCommentStyle] : ""
                    }`}
                  >
                    <span>{check?.comment}</span>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {/* {Object.keys(formState).map(key => (
        <div key={key}>
          <input
            type="checkbox"
            name="piece"
            onChange={onChange}
            checked={formState[key]}
            value={key}
            id={key}
          />
          <label htmlFor={key}>{key}</label>
        </div>
      ))} */}
    </div>
  );
};
