import React from "react";
import {Link, useHistory} from "react-router-dom";
import { SubstepEntryComponent } from "../containers/substeps-list";
import styles from "../styles/modules/sidebar-substep-entry.module.scss";
import { getStepForCode } from "../model/steps/steps-model";
import { TranslationContext } from "../containers/i18n-container";
import {I18NState} from "../model/i18n/i18n-model";
import {MenuPopupContext} from "../containers/menu-popup-context";

export const SidebarSubstepEntry: SubstepEntryComponent = ({
  substep,
  isCurrent,
  isSelected,
  projectName,
  steps
}) => {
  const selectedClass = isSelected ? "substeps-list__entry--selected" : "";
  let stepLabel = null;
  if (substep.creationData && substep.creationData.type) {
      stepLabel = getStepForCode(substep.creationData.type, steps).label + " : ";
  }

  const shortenName = (name: string | undefined) => {
    if (name) {
      return name.length > 22 ? name.substr(0, 20) + '...' : name;
    }
    return '';
  }

  const substepLabel = shortenName(substep?.creationData?.label);
  return (
    <MenuPopupContext.Consumer>
      {({showMenuPopup, toggleMenuPopup}) => (
        <TranslationContext.Consumer>
          {({texts}: I18NState) =>
            <div className={styles.sidebarsubstepentry}>
            <Link
              className={`substeps-list__entry ${selectedClass}`}
              style={{ fontWeight: isCurrent ? 'bold' : 'normal' }}
              to={`/projects/${substep.projectId}/substeps/${substep.id}`}
              onClick={() => {
                toggleMenuPopup(false);
              }}
            >
          {substepLabel || shortenName(projectName ?? '') || texts['lbl-project-new']}
          </Link>
          </div>
        }
        </TranslationContext.Consumer>
      )}
    </MenuPopupContext.Consumer>
  );
};
