import {ActionType, createAction} from "typesafe-actions";
import {StepForRecap} from "../substeps/substeps-service";
import {SubstepProgressOrder} from "./progress-bar-service";

export const PROGRESS_SUBSTEP_REQUEST = "PROGRESS_SUBSTEP_REQUEST";
export const PROGRESS_SUBSTEP_SUCCESS = "PROGRESS_SUBSTEP_SUCCESS";
export const PROGRESS_SUBSTEP_ERROR = "PROGRESS_SUBSTEP_ERROR";
export const PROGRESS_SUBSTEP_INIT = "PROGRESS_SUBSTEP_INIT";

export const progressSubstepRequest = createAction(
  PROGRESS_SUBSTEP_REQUEST,
  action => (data: StepForRecap) => action(data)
);
export const progressSubstepSuccess = createAction(
  PROGRESS_SUBSTEP_SUCCESS,
  action => (data: SubstepProgressOrder[]) => action(data)
);

export const progressSubstepError = createAction(
  PROGRESS_SUBSTEP_ERROR,
  action => (data: any) => action(data)
);

export const progressSubstepInit = createAction(
  PROGRESS_SUBSTEP_INIT,
  action => () => action()
);

export const all = {
  progressSubstepRequest,
  progressSubstepSuccess,
  progressSubstepError,
  progressSubstepInit
};

export type AnyProgressBarAction = ActionType<typeof all>;
