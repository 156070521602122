import React from "react";
import { QuestionTooltip } from "../../components/question-tooltip";
import { getQuestion, getComment, StepViewComponent } from "../../model/steps/steps-model";
import { getSubstep } from "../../model/substeps/substeps-model";
import stylesPi from "../../styles/modules/pi-step-view.module.scss";
import styleTP from "../../styles/modules/tp-step-view.module.scss";
import { useStepViewState } from "../../utils/step-views-utils";
import { OnlyOneOptionAlert } from "../only-one-option-alert";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";

export const TPStepView: StepViewComponent = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange,
  onSkip
}) => {
  const [formState, onChange, error, pending] = useStepViewState(
    substepId,
    substeps,
    steps,
    selectedOptions,
    onOptionsChange,
    onSkip
  );

  if (substepId === null) {
    return null;
  }

  const formStateValues = Object.values(formState);

  return (
    <div className="position-relative">
      <NoOptionAlert
        show={!pending && (Object.values(formState).length === 0 || error)}
      />
      <MaskingSpinner show={pending} />
      <h3 className="project_new mx-auto mb-4">
        <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
        <QuestionTooltip
          tooltipText={getComment(getSubstep(substepId, substeps).stepLevel, steps)}
          questionClassName={`${stylesPi["tpstepview_icoquestion"]} ico-question d-inline-block ml-3`}
        />
      </h3>
      <OnlyOneOptionAlert formStateValues={formStateValues} />
      <div className={`${styleTP["ui-tp-grid"]}`}>
        {Object.values(formState).map(check => (
          <div className="" key={check.id}>
            <div
              className={`${stylesPi["stepview_block"]} ${check.checked ? stylesPi["stepview_block_active"] : ''} ${styleTP["stepview_block-matiere"]} control custom-checkbox"`}
            >
              <div className="">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="piece"
                  onChange={event =>
                    onChange(event.target.value, event.target.checked)
                  }
                  checked={check.checked}
                  value={check.id}
                  id={check.id}
                />
                <label htmlFor={check.id} className={stylesPi.stepview_label}>
                  <span
                    className={stylesPi.stepview_bg}
                    style={{
                      backgroundImage: check.picture
                        ? `url(${check.picture})`
                        : "none"
                    }}
                  ></span>
                  {check.label}
                  {/* <span className=""></span> */}
                </label>
              </div>
              {/* <div className="linecheckbox_container_clear"></div> */}
            </div>
            <QuestionTooltip
              tooltipText={check.comment ?? null}
              questionClassName={`${stylesPi.stepview_ico} ico-question ico-xs`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
