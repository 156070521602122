import React from "react";
import { FunctionComponent } from "react";
import { BottomStepNameContainer } from "../containers/bottom-step-name-container";

export const BottomBar: FunctionComponent = ({ children }) => (
  <div className="main-footer">
    <BottomStepNameContainer />
    <div className="d-flex justify-content-between main-footer-container">{children}</div>
  </div>
);
