import {SubstepProgressOrder} from "./progress-bar-service";
import {
  AnyProgressBarAction,
  progressSubstepSuccess,
  progressSubstepInit
} from "./progress-bar-actions";
import {getType} from "typesafe-actions";
import {Substep} from "../substeps/substeps-model";
import {Indexed} from "../../utils/collection-utils";

export interface progresBarState {
  progresBarState: SubstepProgressOrder[];
}

export const getChildren = (id: string, substeps: Indexed<Substep>) => {
  return Object.values(substeps).filter(
    (p, dx) => p.parent === id
  );
};

export const getParent = (parent: string | null, substeps: Indexed<Substep>) => {
  return Object.values(substeps).filter(
    (p, idx) => p.id === parent
  )[0];
};

export const getCurrentOption = (substep: SubstepProgressOrder, substeps: Indexed<Substep>, progressFromStart: SubstepProgressOrder []) => {
  let isDone = progressFromStart.filter(
      (p, idx) => p.code === substep.code
  )[0]?.done;

  let label;

  switch (substep.code) {
    case 'NS':
    case 'ES':
    case 'TE':
    case 'MA':
    case 'PR':
    case 'PO':
    case 'MO':
    case 'CO':
      if (isDone) {
        label = substep.option;
      }
      break;
    case 'PI':
    case 'TP':
    default:
      label = ''
  }

  let tmp = Object.values(substeps).filter(
      (p, idx) => {
        return (p.projectId === substep.projectId && p.name === substep.option);
      }
  )
  if (tmp.length) {
    progressFromStart.filter((p, idx) => p.code === substep.code)[0].parent = tmp[0].parent;
  }

  return label;
};

export const getBottomLabel = (substepCode: string | undefined | null, progressBarState: SubstepProgressOrder[]) => {
  switch (substepCode) {
    case "PI":
      return progressBarState[0]?.option;
    case "TP":
      return progressBarState[1]?.option;
    case "NS":
      return progressBarState[2]?.option;
    case "ES":
      const resultES = progressBarState.filter(
        (p, idx) => p.code === "NS" || p.code === "ES"
      );
      return resultES[0]?.option + " > " + resultES[1]?.option;
    case "TE":
      return progressBarState.filter(
        (p, idx) => p.code === "TE"
      )[0]?.option;
    case "MA":
      const resultMA = progressBarState.filter(
        (p, idx) => p.code === "MA"
      );

      if (resultMA.length === 0) {
        return progressBarState.filter(
          (p, idx) => p.code === "PR"
        )[0]?.option;
      }

      return resultMA[0]?.option;
    case "PR":
      return progressBarState.filter(
          (p, idx) => p.code === "MA"
      )[0]?.option;

    case "PO":
      return progressBarState.filter(
          (p, idx) => p.code === "MA"
      )[0]?.option;

    case "MO":
      const resultMO = progressBarState.filter(
        (p, idx) => p.code === "MO"
      );

      if (resultMO.length === 0) {
        return progressBarState.filter(
            (p, idx) => p.code === "MA"
        )[0]?.option;
      }

      return resultMO[0].option;
    case "CO":
      ;return progressBarState.filter(
        (p, idx) => p.code === "MA"
      )[0]?.option;
    default:
      return "";
  }
};

export const progresBarReducer = (
  state: progresBarState = {progresBarState: []},
  action: AnyProgressBarAction
) => {
  switch (action.type) {
    case getType(progressSubstepSuccess):
      return {
        ...state,
        progresBarState: action.payload
      };
    case getType(progressSubstepInit) :
      return {...state, progresBarState: []};
    default:
      return state;
  }
};
