import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, AutoSizer } from "react-virtualized";
import {getAdminProjects, getAdminUsers} from "../model/administration/administration-service";
import { USER_ROLES } from "../model/authentication/authentication-model";
import { MainState } from "../model/main-model";
import { usePromise } from "../utils/react-utils";
import { HeaderContainer } from "./header-container";
import { AdminPage } from "../components/admin-page";
import { fetchProjectFromApi } from "../model/projects/projects-service";
import {fetchProjectRequest, fetchProjectsSuccess} from "../model/projects/projects-actions";
import {authUserRoleRequest} from "../model/authentication/authentication-actions";

const contains = (needle: string, stack: string) => {
  return stack !== null && stack.toLowerCase().includes(needle.toLowerCase());
};

export const AdminPageContainer = () => {
  const user = useSelector(
    (state: MainState) => state.authentication.data?.user
  );
  const adminEdit = useSelector(
    (state: MainState) => state.authentication.adminEdit
  );

  const dispatch = useDispatch();

  const [filterProjectValue, updateFilterProjectValue] = useState<null | string>(null);

  const [filterUserValue, updateFilterUserValue] = useState<null | string>(null);

  const isAdmin = user?.roles.includes(USER_ROLES.ROLE_ADMIN) || user?.roles.includes(USER_ROLES.ROLE_SUPERADMIN);

  const [projectsData] = usePromise(getAdminProjects, [], []);

  const [usersData] = usePromise(getAdminUsers, [], [adminEdit]);

  if (!isAdmin) {
    return null;
  }

  const filteredProjectsData =
    filterProjectValue === null || filterProjectValue === ''
      ? projectsData.slice(0, 10)
      : projectsData.filter(entry => {
          return [
            entry.firstName,
            entry.lastName,
            entry.email,
            entry.label,
            entry.hash
          ].reduce<boolean>((acc, val) => {
            return acc || contains(filterProjectValue, val);
          }, false);
        });

  const filterProject = (text: string) => {
    updateFilterProjectValue(text);
  };

  const onProjectEntryClick = (id: number) => {
    dispatch(fetchProjectRequest(id.toString()));
  };

  const filteredUsersData =
    filterUserValue === null || filterUserValue === ''
      ? usersData.slice(0, 5)
      : usersData.filter(entry => {
        return [
          entry.firstName,
          entry.lastName,
          entry.email
        ].reduce<boolean>((acc, val) => {
          return acc || contains(filterUserValue, val);
        }, false);
      });

  const filterUser = (text: string) => {
    updateFilterUserValue(text);
  };

  const onUserEntryClick = (id: number, role: string[]) => {
    dispatch(authUserRoleRequest(id, role));
  };

  return (
    <AdminPage
      projectsData={filteredProjectsData}
      totalProjects={projectsData.length}
      usersData={filteredUsersData}
      totalUsers={usersData.length}
      onProjectSearch={filterProject}
      onUserSearch={filterUser}
      onProjectEntryClick={onProjectEntryClick}
      onUserEntryClick={onUserEntryClick}
    />
  );
};
