import queryString from "query-string";
import React, { Component, FormEvent } from "react";
import { Alert, ButtonToolbar } from "react-bootstrap";
import { connect } from "react-redux";
import {Link, RouteComponentProps, useHistory} from "react-router-dom";
import { Dispatch } from "redux";
import line from "../../assets/login_line.png";
import { CarouselHome } from "../../components/carousel-home";
import { EmailCheck } from "./email-check";
import { Tra } from "../../components/tra";
import {
  authLoginRequest,
  authToDefault
} from "../../model/authentication/authentication-actions";
import { I18NState } from "../../model/i18n/i18n-model";
import { MainState } from "../../model/main-model";
import styles from "../../styles/modules/login.module.scss";
import { PropsType } from "../../utils/ts-utils";
import { HeaderContainer } from "../header-container";
import { TranslationContext } from "../i18n-container";
import { Register } from "./register";
import {ReactComponent as IconSave} from "../../assets/save-icon.svg";
import {USER_ROLES} from "../../model/authentication/authentication-model";

type OwnProps = RouteComponentProps;

export type LoginState = {
  email: string;
  password: string;
};

export type ComponentState = {
  email: string;
  password: string;
};
class login extends Component<
  PropsType<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>,
  ComponentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.routeChange = this.routeChange.bind(this);
  }

  handleForm = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      email: this.state.email,
      password: this.state.password
    };

    const parsedQueryString = queryString.parse(this.props.location.search);
    if (
      parsedQueryString.redirect &&
      typeof parsedQueryString.redirect === "string"
    ) {
      this.props.setLogin(data, parsedQueryString.redirect);
    } else {
      this.props.setLogin(data);
    }
  };

  componentDidMount() {
    if (!this.props.lang) {
      this.props.history.push("/");
    }
  }

  handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    this.setState(state => ({ ...state, [name]: value }));
  };

  routeChange = (path: string)=> {
    this.props.history.push(path);
  }

  render() {
    const error = this.props.loginErrors;
    const isAdmin = this.props.user?.roles.includes(USER_ROLES.ROLE_ADMIN) || this.props.user?.roles.includes(USER_ROLES.ROLE_SUPERADMIN)
    return (
      <TranslationContext.Consumer>
        {({ texts, pictures }: I18NState) => (
          <div
            className={`${styles["login-container container"]} container-fluid`}
          >
            <HeaderContainer />
            <div className={`${styles["container_row"]} row`}>
              <CarouselHome />
              <img
                className={styles.left_logo}
                src={pictures["img-pano-logo"]}
                alt=""
              />
              <div className={`${styles["right"]} col-lg-6 col-md-12`}>
                <div className="row">
                  <div className={`${styles["right_start"]} col-12`}>
                    <h2 className={styles.right_start_title}>
                      <Tra code="lbl-home-start" />
                    </h2>
                    <p className={styles.right_start_text}>
                      <Tra code="text-form-language" />
                    </p>
                    { isAdmin ? (
                      <button className="btn btn-primary" onClick={() => this.routeChange('/admin')}>
                        <span>Accéder au mode admin</span>
                      </button>
                    ) : (
                      <React.Fragment>
                        <button className="btn btn-primary" onClick={() => this.routeChange('/new-project')}>
                          <Tra code="btn-new-project" />
                        </button>{" "}
                        <button className="btn btn-primary" onClick={() => this.routeChange('/main')}>
                        <Tra code="btn-project-new-from-template" />
                        </button>
                      </React.Fragment>
                    ) }
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`${styles["right_account_left"]} col-lg-6 col-md-12`}
                  >
                    <img
                      src={line}
                      alt=""
                      className={styles.right_account_line}
                    />
                    <form onSubmit={this.handleForm}>
                      <h3>
                        <Tra code="title-form-login" />
                      </h3>
                      {error && error.data ? (
                        <Alert variant="warning" className={styles["ui-error"]}>
                          <Tra code={error.data.message} />{" "}
                        </Alert>
                      ) : (
                        ""
                      )}
                      <div className="form-group">
                        <label className={styles.right_account_label}>
                          <Tra code="lbl-form-email" />
                        </label>
                        <input
                          type="text"
                          name="email"
                          placeholder={texts["lbl-form-placeholder-email"]}
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label className={styles.right_account_label}>
                          <Tra code="lbl-form-password" />
                        </label>
                        <input
                          type="password"
                          name="password"
                          onChange={this.handleInput}
                          className="form-control"
                        />
                      </div>
                      <input
                        type="submit"
                        value={texts["btn-form-login"]}
                        className="btn btn-primary"
                      />
                      <EmailCheck />
                    </form>
                  </div>
                  <div
                    className={`${styles["right_account_right"]} col-lg-6 col-md-12`}
                  >
                    <h3>
                      <Tra code="title-form-register" />
                    </h3>
                    <ButtonToolbar
                      className={`${styles["right_account_right_btn-toolbar"]}`}
                    >
                      <Register />
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={`${styles["right_text"]} row`}>
                  <div className="col-12">
                    <p>
                      {" "}
                      <Tra code="text-form-login-info" />{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </TranslationContext.Consumer>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLogin: (
    data: Pick<ComponentState, "email" | "password">,
    redirect: string | null = null
  ) => {
    return dispatch(authLoginRequest(data, redirect));
  },
  // setAccesGuset: () => {
  //   return dispatch(authGuestRequest());
  // },
  setDefaultAuth: () => {
    return dispatch(authToDefault());
  }
});

const mapStateToProps = (state: MainState) => ({
  loginErrors: state.authentication.errors?.login,
  lang: state.authentication.currentLanguage,
  user: state.authentication.data?.user
});

export default connect(mapStateToProps, mapDispatchToProps)(login);
