import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { Breadcrumb } from "../components/breadcrumb";
import stepViews from "../components/steps-views";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import {
  AnyStepOptions,
  createBlankStepOption,
  getStepForLevel,
  getStepList,
  Step
} from "../model/steps/steps-model";
import {
  setSubstepOptions,
  validateSubstep
} from "../model/substeps/substeps-actions";
import {
  getCurrentSubstepForProject,
  getSubsetWParents,
  getSubstep,
  makeUniqueProjectSubstepsSelector,
  Substep,
  getSubsetDataWParentsDataForRecap
} from "../model/substeps/substeps-model";
import styles from "../styles/modules/subproject-content.module.scss";
import { Indexed } from "../utils/collection-utils";
import { PropsType } from "../utils/ts-utils";
import { ProgressBar } from "../components/progress-bar";

type OwnProps = RouteComponentProps<{ id: string; substep: string }>;

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  const substepsSelector = makeUniqueProjectSubstepsSelector();
  return (state: MainState, props: OwnProps) => ({
    project: selector(state, props.match.params.id),
    steps: getStepList(state.steps),
    substeps: state.substeps.byId,
    substeps_: substepsSelector(state, props.match.params.id)
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setSubstepOptions: (substepId: string, opts: AnyStepOptions[]) => {
    dispatch(setSubstepOptions(substepId, opts));
  },
  onSkip: (substepId: string, substeps: Indexed<Substep>, steps: Step[], options?: AnyStepOptions[]) => {
    console.log("skipping", getSubstep(substepId, substeps).stepLevel, options);
    dispatch(
      setSubstepOptions(substepId, options || [
        createBlankStepOption(
          getStepForLevel(getSubstep(substepId, substeps).stepLevel, steps).code
        )
      ])
    );
    dispatch(validateSubstep(substepId, steps));
  }
});

export const Component: FunctionComponent<PropsType<
  OwnProps,
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps
>> = ({
  match,
  project,
  steps,
  history,
  setSubstepOptions,
  substeps,
  substeps_,
  onSkip
}) => {
  if (!steps) {
    return null;
  }
  if (!project) {
    console.error("project not found");
    return <Redirect to="/main" />;
  }

  const substep = getSubstep(match.params.substep, substeps);

  if (!substep) {
    const defaultSubstep = getCurrentSubstepForProject(project.id, substeps);
    console.log("default substep not found");

    console.log("in this case", project.id, defaultSubstep);

    if (!defaultSubstep) {
      return <Redirect to={`/recap/${project.id}`} />;
    }

    return (
      <Redirect to={`/projects/${project.id}/substeps/${defaultSubstep.id}`} />
    );
  }

  // console.log("substep was found", substep.stepLevel);

  const ViewComponent =
    stepViews[getStepForLevel(substep.stepLevel, steps).viewType];
  const showBarProgress = (level: number) => {
    return (
      <ProgressBar
        selectedSubstepId={substep.id}
        substepCurrent={getSubsetDataWParentsDataForRecap(
          substep.id,
          substeps
        )}
      ></ProgressBar>
    );
  };

  const allSubsteps = getSubsetWParents(substep.id, substeps).reverse();
  const titleSubstep = allSubsteps[2] || allSubsteps[1] || substep;
  const title = titleSubstep ? titleSubstep.name : "";

  return (
    <div className={styles.subprojectcontent}>
      <Breadcrumb
        substeps={substeps}
        selectedSubstepId={substep.id}
        project={project}
      />
      <h1 className="mb-4">{title}</h1>
      {showBarProgress(substep.stepLevel)}
      <div className="pt-5">
        <ViewComponent
          substepId={substep.id}
          substeps={substeps}
          steps={steps}
          selectedOptions={substep.selectedOptions}
          onOptionsChange={opts => setSubstepOptions(substep.id, opts)}
          onSkip={e => onSkip(substep.id, substeps, steps)}
        />
      </div>
    </div>
  );
};

export const SubprojectContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
