import { apiGet } from "../../utils/server-utils";
import {HydraCollection, HydraMember} from "../../utils/hydra-utils";

export type AdminProjectData = {
  email: string;
  firstName: string;
  hash: string;
  id: number;
  isArchived: boolean;
  isDone: boolean;
  isEdited: boolean;
  label: string;
  lastName: string;
  dateCreation: string;
  dateEdition: string;
};

export const getAdminProjects = () =>
  apiGet<AdminProjectData[]>("/projects-admin").then(response => response.data);

export type AdminUserData = HydraMember & {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  language: {
    label: string;
  };
  unitSystem: {
    label: string;
  };
  shopEmails: string[];
};

export const getAdminUsers = () =>
  apiGet<HydraCollection<AdminUserData>>("/users").then(response => response.data['hydra:member']);
