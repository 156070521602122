import React from "react";
import styles from "../styles/modules/masking-spinner.module.scss";
import { Spinner } from "react-bootstrap";

export const MaskingSpinner = ({ show, text }: { show: boolean, text?: string }) =>
  show ? (
    <div className={styles.masking_spinner_holder}>
      <Spinner className="mr-1" role="status" animation="border"></Spinner>
      {text}
    </div>
  ) : null;
