import { ActionType, createAction } from "typesafe-actions";
import { AnyStepOptions, StepList } from "../steps/steps-model";
import { ServerProject, ServerProjectPart } from "./projects-service";

/**************** save project **********************/

export const SAVE_PROJECT_REQUEST = "SAVE_PROJECT_REQUEST";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_ERROR = "SAVE_PROJECT_ERROR";

export type SaveProjectRequestPayloadOptions = {
  projectLabel?: string;
  shouldEdit?: boolean;
  shouldDuplicate?: boolean;
  shouldArchive?: boolean;
  shouldSetAsTemplate?: boolean;
  shouldDelete?: boolean;
  shouldRedirect?: boolean;
};
export const saveProjectRequest = createAction(
  SAVE_PROJECT_REQUEST,
  action => (id: string, options: SaveProjectRequestPayloadOptions) =>
    action({
      id,
      options
    })
);

export const saveProjectSuccess = createAction(
  SAVE_PROJECT_SUCCESS,
  action => (id: string, data: ServerProject, pdfDownloadURL: string | null, cartUrl: string | null, shouldRedirect?: boolean) =>
    action({
      id,
      data,
      pdfDownloadURL,
      cartUrl,
      shouldRedirect
    })
);

export const saveProjectError = createAction(
  SAVE_PROJECT_ERROR,
  action => (id: string, error: string, projectBackendId?: string) =>
    action({
      id,
      error,
      projectBackendId
    })
);

/**************** duplicate project **********************/

export const DUPLICATE_PROJECT_REQUEST = "DUPLICATE_PROJECT_REQUEST";
export const DUPLICATE_PROJECT_SUCCESS = "DUPLICATE_PROJECT_SUCCESS";
export const DUPLICATE_PROJECT_ERROR = "DUPLICATE_PROJECT_ERROR";

export const duplicateProjectRequest = createAction(
  DUPLICATE_PROJECT_REQUEST,
  action => (id: string, label: string) =>
    action({
      id,
      label
    })
);

export const duplicateProjectSuccess = createAction(
  DUPLICATE_PROJECT_SUCCESS,
  action => (copiedProjectId: string, newId: string) =>
    action({
      copiedProjectId,
      newId
    })
);

export const duplicateProjectError = createAction(
  DUPLICATE_PROJECT_ERROR,
  action => (id: string) =>
    action({
      id
    })
);

/**************** finish project **********************/

// export const FINISH_PROJECT_REQUEST = "FINISH_PROJECT_REQUEST";
// export const FINISH_PROJECT_SUCCESS = "FINISH_PROJECT_SUCCESS";
// export const FINISH_PROJECT_ERROR = "FINISH_PROJECT_ERROR";

// export const finishProjectRequest = createAction(
//   FINISH_PROJECT_REQUEST,
//   action => (id: string) =>
//     action({
//       id
//     })
// );

// export const finishProjectSuccess = createAction(
//   FINISH_PROJECT_SUCCESS,
//   action => (id: string) =>
//     action({
//       id
//     })
// );

// export const finishProjectError = createAction(
//   FINISH_PROJECT_ERROR,
//   action => (id: string) =>
//     action({
//       id
//     })
// );

/**************** set new project data *******************/

export const SET_NEW_PROJECT_DATA = "SET_NEW_PROJECT_DATA";

export const setNewProjectData = createAction(
  SET_NEW_PROJECT_DATA,
  action => (projectName: string, options: AnyStepOptions[] | null) =>
    action({
      projectName,
      options
    })
);

/**************** fetch projects **********************/

export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_ERROR = "FETCH_PROJECTS_ERROR";

export const fetchProjectsRequest = createAction(
  FETCH_PROJECTS_REQUEST,
  action => (id?: string) =>
    action({id})
);
export const fetchProjectsSuccess = createAction(
  FETCH_PROJECTS_SUCCESS,
  action => (data: {
    projectsData: ServerProject[];
    subpartsData?: ServerProjectPart[];
    backendIdRedirect?: string;
  }) =>
    action(data)
);
export const fetchProjectsError = createAction(
  FETCH_PROJECTS_ERROR,
  action => (error: string) =>
    action({
      error
    })
);

/**************** fetch project **********************/

export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_ERROR = "FETCH_PROJECT_ERROR";

export const fetchProjectRequest = createAction(
  FETCH_PROJECT_REQUEST,
  action => (id: string) =>
    action({id})
);
export const fetchProjectSuccess = createAction(
  FETCH_PROJECT_SUCCESS,
  action => (data: {
    projectsData: ServerProject[];
    subpartsData: ServerProjectPart[];
  }) =>
    action(data)
);
export const fetchProjectError = createAction(
  FETCH_PROJECT_ERROR,
  action => (error: string) =>
    action({
      error
    })
);

/**************** add project **********************/

export const ADD_PROJECT_REQUEST = "ADD_PROJECT";

export const addProjectRequest = createAction(
  ADD_PROJECT_REQUEST,
  action => (
    label: string,
    options: AnyStepOptions[],
    id: string,
    steps: StepList
  ) =>
    action({
      label,
      options,
      id,
      steps
    })
);

const all = {
  fetchProjectsRequest,
  fetchProjectsSuccess,
  fetchProjectsError,

  fetchProjectRequest,
  fetchProjectSuccess,
  fetchProjectError,

  addProjectRequest,

  // finishProjectRequest,
  // finishProjectSuccess,
  // finishProjectError,

  duplicateProjectRequest,
  duplicateProjectSuccess,
  duplicateProjectError,

  saveProjectRequest,
  saveProjectSuccess,
  saveProjectError,

  setNewProjectData
};

export type AnyProjectAction = ActionType<typeof all>;
// export type AnyProjectAction = FetchProjectsRequestAction | FetchProjectsSuccessAction | FetchProjectsErrorAction | AddProjectRequestAction | AddProjectSuccessAction | AddProjectErrorAction;
