import React from "react";
import { Alert } from "react-bootstrap";
import {Tra} from "./tra";

export const NoOptionAlert = ({ show }: { show: boolean }) =>
  show ? (
    <Alert variant="warning">
      <Tra code="alert-no-option" />
    </Alert>
  ) : null;
