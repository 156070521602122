import React, { FunctionComponent, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { populate } from "../model/i18n/i18n-actions";
import { I18NState, initialState } from "../model/i18n/i18n-model";
import { getI18NData } from "../model/i18n/i18n-service";
import { MainState } from "../model/main-model";
import { usePromise } from "../utils/react-utils";

export const TranslationContext = React.createContext<I18NState>(initialState);

export const useT = () => {
  const translations = useContext(TranslationContext);

  return {
    t: (code: string) => translations.texts[code] ?? (process.env.NODE_ENV === 'production' ? '' : `***${code}***`),
    pic: (code: string) => translations.pictures[code],
    ht: (code: string) =>
      React.createElement("span", {
        dangerouslySetInnerHTML: {
          __html: translations.texts[code] ?? (process.env.NODE_ENV === 'production' ? '' : `***${code}***`)
        }
      })
  };
};

export const I18NContainer: FunctionComponent<{}> = ({ children }) => {
  const dispatch = useDispatch();

  const translations = useSelector((state: MainState) => state.i18n);
  const token = useSelector(
    (state: MainState) => state.authentication.data?.token
  );

  const locale = useSelector(
    (state: MainState) => state.authentication.currentLocale
  );


  const [data] = usePromise(
    () => {
      return getI18NData();
    },
    {
      texts: {},
      pictures: {}
    },
    [token, locale]
  );

  useEffect(() => {
    if (Object.values(data.texts).length > 0) {
      dispatch(populate(data));
    }
  }, [data, dispatch]);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};
