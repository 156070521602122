import { combineEpics, Epic } from "redux-observable";
import {
  progressSubstepSuccess,
  AnyProgressBarAction,
  progressSubstepError,
  progressSubstepRequest
} from "./progress-bar-actions";
import { ActionType, isActionOf } from "typesafe-actions";
import { MainState } from "../main-model";
import { switchMap, filter, map, catchError } from "rxjs/operators";
import { from, of } from "rxjs";
import { progressBarService } from "./progress-bar-service";

export const progressBarEpic: Epic<
  AnyProgressBarAction,
  ActionType<typeof progressSubstepSuccess | typeof progressSubstepError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(progressSubstepRequest)),
    switchMap(action =>
      from(progressBarService(action.payload)).pipe(
        map(progressSubstepSuccess),
        catchError(error => {
          console.log("error", error);
          return of(progressSubstepError(error.toString()));
        })
      )
    )
  );

export const progressBarEpics = combineEpics(progressBarEpic);
