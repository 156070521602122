import { combineEpics } from "redux-observable";
import { projectsEpic } from "./projects/projects-epics";
import { templatesEpic } from "./templates/templates-epics";
import { substepsEpic } from "./substeps/substeps-epics";
import { authenticationEpic } from "./authentication/authentication-epics";
import { stepsEpic } from "./steps/steps-epics";
import { progressBarEpics } from "./progressBar/progress-bar-epics";
// import { navigationEpic } from "./nav/nav-epics";

export const mainEpic = combineEpics(authenticationEpic,projectsEpic, templatesEpic, substepsEpic, stepsEpic,progressBarEpics /*navigationEpic*/);
