import jwtDecode from "jwt-decode";
import { getType } from "typesafe-actions";
import { ApiError } from "../../utils/server-utils";
import {
  AnyAuthenticationAction,
  authEditInfosSuccess,
  authEditInfosError,
  authEmailCheckError,
  authEmailCheckRequest,
  authEmailCheckSuccess,
  authGuestRequest,
  authGuestSuccess,
  authLanguagesRequest,
  authLanguagesSuccess,
  authLangValidateRequest,
  authLoginError,
  authLoginRequest,
  authLoginSuccess,
  authLogoutRequest,
  authLogoutSuccess,
  authPasswordEditSuccess,
  authPasswordEditError,
  authPasswordResetSuccess,
  authPasswordResetError,
  authPicturesSuccess,
  authPreferenceEditSuccess,
  authPreferenceEditError,
  authRegisterError,
  authRegisterRequest,
  authRegisterSuccess,
  authToDefault,
  authUnitSystemSuccess,
  authDefaultUnitSystemSuccess,
  authCountriesSuccess,
  authDepartmentsSuccess,
  authProfessionsSuccess,
  authDeleteAccountError,
  authUserRoleSuccess,
  authUserRoleError
} from "./authentication-actions";
import { ServerLanguages } from "./authentication-service";
import { string } from "prop-types";
import {EditProfilePreferencesState} from "../../containers/authentication/edit-profile";

export const USER_ROLES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SUPERADMIN: 'ROLE_SUPERADMIN'
} as const;

export type UserRoles = typeof USER_ROLES[keyof typeof USER_ROLES];

export type EntityLanguage = {
  "@id": string;
  "@type": string;
  TranslatedLabel: string | null;
  code: string | null;
  hotlineNumber: number;
  id: number;
  isDefault: boolean;
  label: string | null;
  urlCorporateWebsite: string | null;
};
export type EntityUnitSystem = {
  "@id": string;
  "@type": string;
  id: number;
  label: string;
  isDefault: boolean;
  sortOrder: number;
};
export type EntityCountry = {
  "@id"?: string;
  "@type"?: string;
  id: number;
  label: string;
  TranslatedLabel?: string | null;
  isDefault?: boolean;
};
export type EntityDepartment = {
  "@id"?: string;
  "@type"?: string;
  id: number;
  label: string;
};
export type EntityProfession = {
  "@id"?: string;
  "@type"?: string;
  id: number;
  label: string;
};
export type EntityReference = string;
export type HydraCollectionResponse = {
  "hydra:totalItems": number;
  "@id": EntityReference;
  "@type": EntityReference;
  "@context": EntityReference;
  "hydra:member": [];
  "hydra:search": {};
};

export interface User {
  // language:string
  "@id": string;
  firstname: string;
  lastname: string;
  email: string;
  language: string;
  locale: string;
  unitSystem: string;
  roles: UserRoles[];
  shopEmails: [string];
  phoneNumber?: any;
  country?: string;
  department?: string;
  profession?: string;
}

export interface UserData {
  token?: string;
  user: User;
}

export interface AuthenticationState {
  pictures?: HydraCollectionResponse;
  languages?: ServerLanguages;
  unitSystems: EntityUnitSystem[];
  countries: EntityCountry[];
  departments: EntityCountry[];
  professions: EntityCountry[];
  isValidLang?: boolean;
  data: UserData | null;
  guest: boolean;
  errors?: {
    register?: ApiError,
    login?: ApiError,
    check?: ApiError,
    reset?: ApiError,
    data?: ApiError,
    prefs?: ApiError,
    delete?: ApiError
  };
  result?: {
    register?: boolean,
    login?: boolean,
    check?: boolean,
    reset?: boolean,
    data?: boolean,
    prefs?: boolean,
    delete?: boolean
  };
  registerPending: boolean;
  currentLocale?: string;
  currentLanguage?: string;
  currentUnitSystem?: string;
  currentCountry?: string;
  shopEmails?: [string];
  adminEdit?: {
    userId: number,
    roles: string[],
    result: boolean,
    errors?: ApiError
  }
}

export const isValidToken = (token: string) =>
  Date.now() < jwtDecode<{ exp: number }>(token).exp * 1000;

export const hasValidToken = (authState: AuthenticationState) => {
  return Boolean(
    authState.data && authState.data.token && isValidToken(authState.data.token)
  );
};

export const getToken = (authState: AuthenticationState): string | null =>
  authState.data && authState.data.token ? authState.data.token : null;

export const authUpdateUserLanguage = (
  authState: AuthenticationState,
  data: any
) => {
  authState.currentLocale = data.language.code;
  authState.currentLanguage = data.language['@id'];
  authState.currentUnitSystem = data.unitSystem['@id'];
  return data;
}


const authenticationReducer = (
  state: AuthenticationState = {
    guest: false,
    data: null,
    errors: undefined,
    result: undefined,
    languages: undefined,
    registerPending: false,
    unitSystems: [],
    pictures: undefined,
    isValidLang: false,
    countries: [],
    departments: [],
    professions: []
  },
  action: AnyAuthenticationAction
): AuthenticationState => {
  switch (action.type) {
    case getType(authLanguagesRequest):
      return {
        ...state,
        guest: false,
        errors: undefined
      };

    case getType(authPicturesSuccess):
      return {
        ...state,
        guest: false,
        pictures: action.payload,
        errors: undefined
      };

    case getType(authLanguagesSuccess):
      return {
        ...state,
        languages: action.payload,
        guest: false
      };

    case getType(authUnitSystemSuccess):
      return {
        ...state,
        unitSystems: action.payload["hydra:member"],
        guest: false,
        errors: undefined
      };

    case getType(authDefaultUnitSystemSuccess):
      return {
        ...state,
        currentUnitSystem: action.payload["hydra:member"][0]['@id'] ?? undefined,
        guest: false,
        errors: undefined
      };

    case getType(authCountriesSuccess):
      return {
        ...state,
        countries: action.payload["hydra:member"],
        guest: false,
        errors: undefined
      };

    case getType(authDepartmentsSuccess):
      return {
        ...state,
        departments: action.payload["hydra:member"],
        guest: false,
        errors: undefined
      };

    case getType(authProfessionsSuccess):
      return {
        ...state,
        professions: action.payload["hydra:member"],
        guest: false,
        errors: undefined
      };

    case getType(authLangValidateRequest):
      return {
        ...state,
        guest: false,
        isValidLang: true,
        currentLocale: action.payload.code ?? undefined,
        currentLanguage: action.payload["@id"],
        errors: undefined
      };

    case getType(authLoginRequest):
      return {
        ...state,
        guest: false,
        errors: undefined,
        result: undefined
      };

    case getType(authLoginSuccess):
      return {
        ...state,
        data: action.payload.data,
        currentLocale: action.payload.data.user.locale ?? undefined,
        currentLanguage: action.payload.data.user.language ?? undefined,
        currentUnitSystem: action.payload.data.user.unitSystem ?? undefined,
        guest: false,
        errors: undefined,
        result: {
          login: true
        }
      };

    case getType(authLoginError):
      return {
        ...state,
        guest: false,
        data: null,
        errors: {
          login: action.payload.errors
        },
        result: undefined
      };

    case getType(authRegisterRequest):
      return {
        ...state,
        errors: undefined,
        result: undefined,
        registerPending: true,
        guest: false,
        data: null
      };

    case getType(authRegisterSuccess):
      return {
        ...state,
        guest: false,
        registerPending: false,
        errors: undefined,
        result: {
          register: undefined
        }
      };

    case getType(authRegisterError):
      return {
        ...state,
        guest: false,
        registerPending: false,
        errors: {
          register: action.payload.errors
        },
        result: undefined
      };

    case getType(authLogoutSuccess):
      return {
        ...state,
        guest: false,
        errors: undefined,
        result: undefined
      };

    case getType(authLogoutRequest):
      return {
        ...state,
        guest: false,
        data: null,
        errors: undefined,
        result: undefined
      };

    case getType(authGuestRequest):
      return {
        ...state,
        guest: false,
        errors: undefined,
        result: undefined
      };

    case getType(authGuestSuccess):
      return {
        ...state,
        guest: true,
        errors: undefined,
        result: undefined
      };

    case getType(authEmailCheckRequest):
      return {
        ...state,
        errors: undefined,
        result: undefined
      };

    case getType(authEmailCheckSuccess):
      return {
        ...state,
        errors: undefined,
        result: {
          check : true
        }
      };

    case getType(authEmailCheckError):
      return {
        ...state,
        errors: {
          check: action.payload.errors
        },
        result: undefined
      };

    case getType(authPasswordEditError):
      return {
        ...state,
        errors: {
          reset: action.payload.errors
        },
        result: undefined
      };

    case getType(authPasswordEditSuccess):
      return {
        ...state,
        errors: undefined,
        result: {
          reset: true
        }
      };

    case getType(authPasswordResetError):
      return {
        ...state,
        errors: {
          reset: action.payload.errors
        },
        result: undefined
      };

    case getType(authPasswordResetSuccess):
      return {
        ...state,
        errors: undefined,
        result: {
          reset: true
        }
      };

    case getType(authPreferenceEditSuccess):
      return {
        ...state,
        errors: undefined,
        result: {
          prefs: true
        }
      };

    case getType(authPreferenceEditError):
      return {
        ...state,
        errors: {
          prefs: action.payload.errors
        },
        result: undefined
      };

    case getType(authEditInfosError):
      return {
        ...state,
        errors: {
          data: action.payload.errors
        },
        result: undefined
      };

    case getType(authEditInfosSuccess):
      return {
        ...state,
        errors: undefined,
        result: {
          data: true
        }
      };

    case getType(authToDefault):
      return {
        ...state,
        data: null,
        errors: undefined,
        result: undefined
      };

    case getType(authDeleteAccountError):
      return {
        ...state,
        errors: {
          delete: action.payload.errors
        },
        result: undefined
      };

    case getType(authUserRoleSuccess):
      return {
        ...state,
        errors: undefined,
        adminEdit: {
          userId: 1,
          roles: [],
          result: true
        }
      };

    case getType(authUserRoleError):
      return {
        ...state,
        errors: undefined,
        result: undefined,
        adminEdit: {
          userId: 1,
          roles: [],
          result: false,
          errors: action.payload.errors
        }
      };

    default:
      return state;
  }
};

export default authenticationReducer;
