export type PropsType<
  OwnProps,
  MapStateToProps extends (...args: any) => {},
  MapDispatchToProps extends (...args: any) => any
> = OwnProps & ReturnType<MapStateToProps> & ReturnType<MapDispatchToProps>;

export type PropsTypeNoDispatch<
  OwnProps,
  MapStateToProps extends (...args: any) => any
> = OwnProps & ReturnType<MapStateToProps>;

export type PropsTypeNoState<
  OwnProps,
  MapDispatchToProps extends (...args: any) => any
  > = OwnProps & ReturnType<MapDispatchToProps>;

export const isNotNil = <T>(val: T | null | undefined): val is T =>
  val !== null && val !== undefined;
