import { History } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
// import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { EmailCheck } from "./containers/authentication/email-check";
import { LanguageChoice } from "./containers/authentication/language-choice";
import login from "./containers/authentication/login";
import resetPassword from "./containers/authentication/reset-password";
import GestResetRoute from "./containers/authentication/routes/gest-reset-route";
import GestLangRoute from "./containers/authentication/routes/guest-lang-choise-route";
import { I18NContainer } from "./containers/i18n-container";
import { Main } from "./containers/main";
import { saveInSessionStorage } from "./storage-manager";
import { navigateOnAction } from "./middleware/navigation-middleware";
import { AnyMainAction } from "./model/main-actions";
import { mainEpic } from "./model/main-epics";
import { mainReducer, MainState } from "./model/main-model";
import "./styles/main.scss";
import { serverGlobals } from "./utils/server-utils";
import { AdminPageContainer } from "./containers/admin-page-container";
import { Page404 } from "./containers/page-404";

let routerHistory: History;

const composeEnhancers =
  // @ts-ignore
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const epicMiddleware = createEpicMiddleware<
  AnyMainAction,
  AnyMainAction,
  MainState
>();

const middlewares = [epicMiddleware, navigateOnAction(() => routerHistory)];

//temp :
// localStorage.removeItem('state');

const savedState = sessionStorage.getItem("state");
/* eslint-disable no-underscore-dangle */
export const store = createStore(
  mainReducer /* preloadedState, */,
  savedState ? JSON.parse(savedState) : undefined,
  composeEnhancers(applyMiddleware(...middlewares))
);
/* eslint-enable */

saveInSessionStorage(store);

window.addEventListener("beforeunload", event => {
  if (
    Object.values(store.getState().projects.byId).find(
      project => project.backendId === null
    )
  ) {
    event.preventDefault();
    event.returnValue = "";
  }
});

epicMiddleware.run(mainEpic);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <I18NContainer>
        <Router>
          <Route
            render={({ history }: any) => {
              routerHistory = history;
              serverGlobals.routerHistory = history;

              return (
                <Switch>
                  <Redirect from="/" exact to="/languages" />
                  <Route path="/login" component={login} />
                  <Route path="/admin" component={AdminPageContainer} />
                  <Route path="/emailCheck" component={EmailCheck} />
                  <GestLangRoute path="/languages" component={LanguageChoice} />
                  <GestResetRoute
                    path="/reset-password"
                    component={resetPassword}
                  />
                  <Route path="/404" component={Page404} />
                  <Route component={Main} />;
                </Switch>
              );
            }}
          />
        </Router>
      </I18NContainer>
    </Provider>
  );
};

export default App;
