import React from "react";
import { Link } from "react-router-dom";
import { HeaderContainer } from "../containers/header-container";
import {useT} from "../containers/i18n-container";
import {useSelector} from "react-redux";
import {MainState} from "../model/main-model";
import {USER_ROLES} from "../model/authentication/authentication-model";

export const MainUI = ({
  aside,
  main,
  footer
}: {
  aside: JSX.Element;
  main: JSX.Element;
  footer: JSX.Element;
}) => {
  const { pic } = useT();

  const user = useSelector(
    (state: MainState) => state.authentication.data?.user
  );
  const isAdmin = user?.roles.includes(USER_ROLES.ROLE_ADMIN) || user?.roles.includes(USER_ROLES.ROLE_SUPERADMIN);

  return (
    <div className="ui">
      <HeaderContainer />
      <aside className="main-sidebar">
        <div className="main-sidebar_logo">
          { isAdmin ? (
            <Link to={{ pathname: "/admin" }}>
              <img src={pic('img-menu-logo')} alt="" />
            </Link>
          ) : (
            <Link to={{ pathname: "/main" }}>
              <img src={pic('img-menu-logo')} alt="" />
            </Link>
          ) }
        </div>
        {aside}
      </aside>
      <div className="main-body">{main}</div>
      <footer>{footer}</footer>
    </div>
  )
}
