import { FunctionComponent } from "react";
import { getType } from "typesafe-actions";
import { Indexed } from "../../utils/collection-utils";
import { HydraMember } from "../../utils/hydra-utils";
import { Substep, getSubstep } from "../substeps/substeps-model";
import {
  AnyStepsAction,
  fetchStepsError,
  fetchStepsRequest,
  fetchStepsSuccess
} from "./steps-actions";
import equals from "ramda/src/equals";
import { isNotNil } from "../../utils/ts-utils";
// import { stepTypes } from "./step-functions";

export const PI_VIEW = "PI";
export const TP_VIEW = "TP";
export const DEFAULT_VIEW = "DEFAULT_VIEW";
export const MA_VIEW = "MA";
export const ES_VIEW = "ES";
export const RADIO_VERTICAL_VIEW = "RADIO_VERTICAL_VIEW";
export const MS_VIEW = "MS";
export const CO_VIEW = "CO";

export type AnyStepView =
  | typeof PI_VIEW
  | typeof TP_VIEW
  | typeof DEFAULT_VIEW
  | typeof ES_VIEW
  | typeof MS_VIEW
  | typeof RADIO_VERTICAL_VIEW
  | typeof MA_VIEW
  | typeof CO_VIEW;

export type Step = {
  id: string;
  viewType: AnyStepView;
  label: string;
  code: string;
  question: string;
  comment: string;
};

export type StepList = Array<Step>;

export type StepsState = {
  steps: Step[] | null;
  dirty: boolean;
  error: string | null;
};

export const isSecondToLastStep = (stepLevel: number, steps: StepList) =>
  stepLevel === steps.length - 1;

export const isLastStep = (stepLevel: number, steps: StepList) =>
  stepLevel === steps.length;

export interface StepType {
  getNewSubstepConfigurations: (
    options: AnyStepOptions
  ) => {
    name: string;
  }[];
}

export const stepTypesMap = [
  "PI",
  "TP",
  "NS",
  "ES",
  "TE",
  "MA",
  "PR",
  "PO",
  "MO",
  "CO",
  "MS"
];

export const getStepType = (stepLevel: number) => stepTypesMap[stepLevel];

export const getStepLevelForType = (type: string) =>
  stepTypesMap.indexOf(type) + 1;

export const getStepList = (stepsState: StepsState) => stepsState.steps;

// export const createNewSubsteps = (parentSubstep: Substep, substeps: SubstepState, steps: StepList) => {
//   const step = getStepForLevel(parentSubstep.stepLevel, steps);

//   const last = isLastStep(parentSubstep.stepLevel, steps);
//   const newSubsteps = last ? [] : parentSubstep.options.map(option => createNewSubstep(getNewSubstepId(substeps), parentSubstep.projectId, parentSubstep.stepLevel + 1, parentSubstep.id));

//   return newSubsteps;
// }

// export const getStepFunctions = (stepLevel: number, steps: StepList) => {
//   return stepTypes[getStepForLevel(stepLevel, steps).stepFunctions];
// }

export const getNewSubstepConfigurations = (
  options: AnyStepOptions[] | null,
  stepLevel: number,
  steps: StepList
): { name: string; creationData: AnyStepOptions }[] => {
  // const step = getStepForLevel(stepLevel, steps);

  // if (step.displaySubstep && options && isStdStepOptions(options)) {
  //   const newSubstepNames = options.map(option => ({ name: String(option) }));

  //   return newSubstepNames;
  // } else {
  //   return [];
  // }

  if (!options || isLastStep(stepLevel, steps)) {
    return [];
  }

  if (options.length === 0) {
    return [];
  }

  if (isMeasuringStepOption(options[0])) {
    return [{ name: "measuring", creationData: options[0] }];
  }

  if (isStdStepOptions(options)) {
    const newSubstepNames = options.map(option => ({
      name: String(option.labelShort ?? option.label),
      creationData: option
    }));
    return newSubstepNames;
  }

  return [];
};

export const areSameOptions = (a: AnyStepOptions[], b: AnyStepOptions[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const idsA = a.map(({ id }) => id);
  const idsB = b.map(({ id }) => id);

  const sortedA = idsA.sort();
  const sortedB = idsB.sort();

  return equals(sortedA, sortedB);
};

export const getOptionsFromAIncludedInB = (
  a: AnyStepOptions[],
  b: AnyStepOptions[]
) => {
  return a.reduce<AnyStepOptions[]>((acc, val) => {
    const result = b.find(opt => opt.id === val.id && (opt.type !== 'PI' || opt.codePI === val.codePI));
    return isNotNil(result) ? [...acc, result] : acc
  }, []);
};

export const getOptionsFromANotIncludedInB = (
  a: AnyStepOptions[],
  b: AnyStepOptions[]
) => {
  const included = getOptionsFromAIncludedInB(a, b);
  return a.filter(entry => !included.find(incVal => incVal.id === entry.id && (incVal.type !== 'PI' || incVal.codePI === entry.codePI)));
};

export const getOptionsFromBNotIncludedInA = (
  a: AnyStepOptions[],
  b: AnyStepOptions[]
) => {
  const included = getOptionsFromAIncludedInB(a, b);
  return b.filter(entry => !included.find(incVal => incVal.id === entry.id && (incVal.type !== 'PI' || incVal.codePI === entry.codePI)));
};

export const areOptionsFromAIncludedInB = (a: AnyStepOptions[], b: AnyStepOptions[]) => {
  return getOptionsFromAIncludedInB(a, b).length === a.length;
};

export const areOptionsEmpty = (options: AnyStepOptions[] | null) => {
  if (options === null) {
    return true;
  }

  if (isStdStepOptions(options) && options.length === 0) {
    return true;
  } else if (options.length && isMeasuringStepOption(options[0])) {
    return Number(options[0].value) <= 0;
  }

  return false;
};

export type Brand = HydraMember & {
  "@type": string;
  code: string;
  comment?: string | null;
  label?: string;
  picture?: string | null;
  sortOrder?: number;
};
export type ProductsBrand = {
  " @id": string;
  "@type": string;
  brand: Brand;
};

export interface StepOption {
  id: string;
  label: string;
  labelShort?: string;
  value: string | null;
  type: string;
  sortOrder: number | null;
  isNone?: true;
  isWarning?: true;
  data?: {
    total: number;
    unitSystem: string;
    in: {
      length: number;
      height: number;
    }[];
    out: {
      length: number;
      height: number;
    }[];
  };
  codePI?:number
}

export interface ServerOption extends StepOption {
  picture?: string;
  comment?: string;
}

export type PIServerOption = ServerOption & {
  isInterior: boolean | null;
  isExterior: boolean | null;
};

export type MAServerOption = ServerOption & {
  product: HydraMember & {
    brand: HydraMember;
  };
};

export type AnyServerOption = ServerOption | PIServerOption | MAServerOption;

export const optionIsPIServerOption = (
  option: ServerOption | PIServerOption
): option is PIServerOption => {
  return "isExterior" in option;
};
// export interface MOption {
//   id:string
// }
export type Products = {
  label: string | null;
  comment: string | null;
  chartRow: number | null;
  chartColumn: number | null;
  picture: string | null;
};

export type COOption = StepOption & {
  groupLabel: string | null;
  chartSize: number | null;
  comment: string | null;
  chartRow: number | null;
  chartColumn: number | null;
  picture: string | null;
  sortOrder: number | null;
  archived: boolean | null;
};

export type PIOption = StepOption & {
  codePI: number;
  isInterior: boolean | null;
  isExterior: boolean | null;
};

export type MeasuringstepOptions = StepOption & {
  data: {
    total: number;
    unitSystem: string;
    in: {
      length: number;
      height: number;
    }[];
    out: {
      length: number;
      height: number;
    }[];
  };
};
export type AnyStepOptions =
  | StepOption
  | MeasuringstepOptions
  | PIOption
  | COOption;

export const isStdStepOptions = (
  options: AnyStepOptions[]
): options is StepOption[] => {
  return (
    options.length === 0 || (options as StepOption[])[0].value !== undefined
  );
};

export const isPIOption = (option: AnyStepOptions): option is PIOption =>
  (option as PIOption).codePI !== undefined;

export const isCOOption = (option: AnyStepOptions): option is COOption =>
  (option as COOption).groupLabel !== undefined;

export const isMeasuringStepOption = (
  option: AnyStepOptions | null
): option is MeasuringstepOptions =>
  (option as MeasuringstepOptions).data &&
  Boolean((option as MeasuringstepOptions).data.unitSystem);

export const createBlankStepOption = (type: string): StepOption => ({
  id: "blank",
  label: "blank",
  value: null,
  sortOrder: null,
  isNone: undefined,
  type
});

export const getStepForLevel = (stepLevel: number, steps: StepList) =>
  steps[stepLevel];

export const getStepForCode = (stepCode: string, steps: StepList) =>
    steps.filter((step: Step) => step.code === stepCode)[0]

export type StepViewComponentProps = {
  substepId: string | null;
  substeps: Indexed<Substep>;
  steps: StepList;
  selectedOptions: AnyStepOptions[] | null;
  // project: ProjectState;
  onOptionsChange: (options: AnyStepOptions[]) => void;
  onSkip?: (
    substepId: string,
    steps: StepList,
    options?: AnyStepOptions[]
  ) => void;
  radioMode?: boolean;
  onfiltreBrands?: {
    checked: boolean;
    option: AnyStepOptions;
    label: string;
    id: string;
  }[];
  currentUnitSystem?: string;
};

export type StepViewComponent = FunctionComponent<StepViewComponentProps>;

export const getQuestion = (stepLevel: number, steps: StepList): string => {
  return steps[stepLevel].question;
};

export const getComment = (stepLevel: number, steps: StepList): string => {
  return steps[stepLevel].comment;
};

export const getLabel = (stepLevel: number, steps: StepList): string => {
  return steps[stepLevel].label;
};

export const getViewType = (code: string) => {
  switch (code) {
    case "PI":
      return PI_VIEW;
    case "TP":
      return TP_VIEW;
    case "TE":
      return TP_VIEW;
    case "PO":
    case "PR":
      return RADIO_VERTICAL_VIEW;
    case "ES":
      return ES_VIEW;
    case "MS":
      return MS_VIEW;
    case "CO":
      return CO_VIEW;
    case "MA":
      return MA_VIEW;
    case "MO":
      return TP_VIEW;
    default:
      return DEFAULT_VIEW;
  }
};

export const shouldSkip = (substepId: string, substeps: Indexed<Substep>) => {
  console.log(getStepType(getSubstep(substepId, substeps).stepLevel));
  switch (getStepType(getSubstep(substepId, substeps).stepLevel)) {
    case "PO":
    case "PR":
    case "MO":
      return true;

    default:
      return false;
  }
};

export const stepsReducer = (
  state: StepsState = {
    dirty: false,
    error: null,
    steps: null
  },
  action: AnyStepsAction
): StepsState => {
  switch (action.type) {
    case getType(fetchStepsRequest):
      return {
        ...state,
        dirty: true
      };

    case getType(fetchStepsSuccess):
      return {
        ...state,
        dirty: false,
        error: null,
        steps: action.payload
      };

    case getType(fetchStepsError):
      return {
        ...state,
        dirty: false,
        error: action.payload.error
      };
  }

  return state;
};
//   {
//     id: "PI",
//     viewType: PI_VIEW,
//     label: "Pièce",
//     code: "PI"
//   },
//   {
//     id: "TP",
//     viewType: TP_VIEW,
//     label: "Type de projet",
//     code: "TP"
//   },
//   {
//     id: "NS",
//     viewType: DEFAULT_VIEW,
//     label: "Nature du support",
//     code: "NS"
//   },
//   {
//     id: "ES",
//     viewType: ES_VIEW,
//     label: "Etat du support",
//     code: "ES"
//   },
//   {
//     id: "MS",
//     viewType: MS_VIEW,
//     label: "Mesure",
//     code: "MS"
//   }
// ];
