import { ActionType, createAction } from "typesafe-actions";

export const POPULATE = "POPULATE";

export const populate = createAction(
  POPULATE,
  action => (textsAndPictures: {
    texts: { [index: string]: string };
    pictures: { [index: string]: string };
  }) => action({ textsAndPictures })
  //  action => (texts: ServerText[]) => action({ texts })
);

export type AnyI18NAction = ActionType<typeof populate>;
