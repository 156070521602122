import cuid from "cuid";
import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import {catchError, filter, mapTo, switchMap} from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";
import { ApiError } from "../../utils/server-utils";
import { MainState } from "../main-model";
import {
  // fetchProjectsSuccessAction,
  AnyProjectAction,
  duplicateProjectError,
  duplicateProjectRequest,
  duplicateProjectSuccess,
  fetchProjectsError,
  // AddProjectSuccessAction,
  // addProjectSuccessAction,
  // FetchProjectsErrorAction,
  fetchProjectsRequest,
  fetchProjectsSuccess,
  fetchProjectRequest,
  fetchProjectSuccess,
  fetchProjectError,
  saveProjectError,
  saveProjectRequest,
  saveProjectSuccess
} from "./projects-actions";
import {
  fetchProjectsFromApi,
  isSubpartSavingError,
  saveProject,
  duplicateProject, fetchProjectFromApi
} from "./projects-service";

export const fetchProjectsEpic: Epic<
  AnyProjectAction,
  ActionType<typeof fetchProjectsSuccess | typeof fetchProjectsError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchProjectsRequest)),
    switchMap(action => {
      const prom = fetchProjectsFromApi(action.payload.id);
      return from(prom).pipe(
        switchMap(projects => of(fetchProjectsSuccess(projects))),
        catchError((error: ApiError) => {
          console.error(error);
          return of(fetchProjectsError(String(error.data)));
        })
      );
    })
  );

export const fetchProjectEpic: Epic<
  AnyProjectAction,
  ActionType<typeof fetchProjectSuccess | typeof fetchProjectError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchProjectRequest)),
    switchMap(action => {
      const prom = fetchProjectFromApi(action.payload.id);
      return from(prom).pipe(
        switchMap(data => of(fetchProjectSuccess(data))),
        catchError((error: ApiError) => {
          console.error(error);
          return of(fetchProjectError(String(error.data)));
        })
      );
    })
  );

export const saveProjectSuccessEpic: Epic<
  AnyProjectAction,
  any,
  // FetchProjectsSuccessAction | FetchProjectsErrorAction,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveProjectSuccess)),
    switchMap(action => {
      return of(fetchProjectsRequest());
    })
  );

export const saveProjectEpic: Epic<
  AnyProjectAction,
  AnyProjectAction,
  // FetchProjectsSuccessAction | FetchProjectsErrorAction,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(saveProjectRequest)),
    switchMap(action => {
      const prom = saveProject(
        action.payload.id,
        action.payload.options,
        state$.value
      );
      return from(prom).pipe(
        switchMap(({ data, pdfDownloadURL, cartUrl }) =>
          of(saveProjectSuccess(
            action.payload.id,
            data,
            pdfDownloadURL,
            cartUrl,
            action.payload.options.shouldRedirect
            )
          )
        ),
        catchError((error: any) => {
          console.error(error);
          return of(
            saveProjectError(
              action.payload.id,
              error,
              isSubpartSavingError(error) ? error.projectBackendId : undefined
            )
          );
        })
      );
    })
  );

export const duplicateProjectEpic: Epic<
  AnyProjectAction,
  ActionType<typeof fetchProjectsRequest | typeof duplicateProjectError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(duplicateProjectRequest)),
    switchMap(action => {
      return from(duplicateProject(action.payload.id, action.payload.label, state$.value)).pipe(
        switchMap(action => {
          return of(fetchProjectsRequest());
        }),
        catchError((error: ApiError) => {
          console.error(error);
          return of(duplicateProjectError(String(error.data)));
        })
      );
    })
  );

//temporaire : interfacer avec le serveur
// export const finishProjectEpic: Epic<
//   AnyProjectAction,
//   AnyProjectAction,
//   // FetchProjectsSuccessAction | FetchProjectsErrorAction,
//   MainState
// > = (action$, state$) =>
//   action$.pipe(
//     filter(isActionOf(finishProjectRequest)),
//     switchMap(action => {
//       return of(finishProjectSuccess(action.payload.id), saveProjectRequest(action.payload.id));
//     })
//   );


export const projectsEpic = combineEpics(
  fetchProjectsEpic,
  fetchProjectEpic,
  saveProjectEpic,
  saveProjectSuccessEpic,
  duplicateProjectEpic
);
