import {StepForRecap} from "../substeps/substeps-service";
import {apiPost} from "../../utils/server-utils";

export type SubstepProgressOrder = {
  "@id": string | null;
  "@type": string;
  code: string | null;
  sortOrder: number | undefined;
  label: string;
  done: boolean;
  option: any;
  projectId: string;
  parent: any;
  thumbnail: string | null;
};

export type SubstepsProgressOrder = SubstepProgressOrder[];
export const progressBarService = (substepsToProgressData: StepForRecap) => {
  let pBarDatas = JSON.parse(
    JSON.stringify(substepsToProgressData, function (key, value) {
      return value === undefined ? null : value;
    })
  );
  return apiPost<StepForRecap, SubstepsProgressOrder>(
    "/project_progress",
    pBarDatas
  ).then(response => response.data);
};
