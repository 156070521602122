import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { MainState } from "../../../model/main-model";

const GestLangRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props =>
        rest.isValidLang ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state: MainState) => {
  return {
    data: state.authentication.data,
    isValidLang: state.authentication.isValidLang
  };
};

export default connect(mapStateToProps)(GestLangRoute);
