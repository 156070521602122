import prop from "ramda/src/prop";
import sortBy from "ramda/src/sortBy";
import { Indexed } from "../../utils/collection-utils";
import { HydraCollection, HydraMember } from "../../utils/hydra-utils";
import { apiGet } from "../../utils/server-utils";
import {
  getSubsetDataWParentsData,
  getSubstep,
  Substep
} from "../substeps/substeps-model";
import {
  AnyServerOption,
  COOption,
  getStepForLevel,
  getViewType,
  PIOption,
  ProductsBrand,
  ServerOption,
  Step,
  StepList
} from "./steps-model";
import {ascend, sortWith} from "ramda";

export const fetchSteps = () => {
  return apiGet<
    HydraCollection<
      {
        code: string;
        sortOrder: number;
        label: string;
        question: string;
        comment: string;
      } & HydraMember
    >
  >("/steps").then(response =>
    response.data["hydra:member"]
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map<Step>(entry => {
        const viewType = getViewType(entry.code);

        return {
          id: entry["@id"],
          viewType,
          label: entry.label,
          code: entry.code,
          question: entry.question,
          comment: entry.comment
        };
      })
  );
};

const doMORequest = (stepCode: string, optionParams: string | null) =>
  apiGet<
    HydraCollection<
      {
        label: string;
        code: string;
        sortOrder: number;
        picture?: string;
        product: ProductsBrand;
        isNone: true | null;
        isWarning: true | null;
      } & HydraMember
    >
  >(`/collections?option=${optionParams || ""}`).then(({ data }) => {
    return sortBy(prop("sortOrder"), data["hydra:member"]).map<ServerOption>(
      entry => ({
        id: entry["@id"],
        type: stepCode,
        value: entry["@id"],
        label: entry.label,
        picture: entry.picture,
        products: entry.product,
        sortOrder: entry.sortOrder,
        isNone: Boolean(entry.isNone) ? true : undefined,
        isWarning: Boolean(entry.isWarning) ? true : undefined
      })
    );
  });

const doRequest = (stepCode: string, optionParams: string | null) =>
  apiGet<
    HydraCollection<
      {
        label: string;
        labelShort: string;
        code: string;
        sortOrder: number;
        picture?: string;
        isNone: true | null;
        isWarning: true | null;
        comment: string;
      } & HydraMember
    >
  >(`/options?step.code=${stepCode}${optionParams || ""}`).then(({ data }) => {
    return sortWith([ascend(prop("sortOrder")), ascend(prop('label'))], data["hydra:member"]).map<ServerOption>(
      entry => ({
        ...entry,
        id: entry["@id"],
        type: stepCode,
        value: entry["@id"],
        isNone: Boolean(entry.isNone) ? true : undefined,
        isWarning: Boolean(entry.isWarning) ? true : undefined,
        label: entry.label,
        labelShort: entry.labelShort,
        picture: entry.picture,
        sortOrder: entry.sortOrder,
        comment: entry.comment
      })
    );
  });

export const fetchOptionsForStep = async (
  substepId: string | null,
  substeps: Indexed<Substep>,
  steps: StepList
): Promise<Array<AnyServerOption>> => {
  if (!substepId) {
    return doRequest("PI", null);
  }

  const substep = getSubstep(substepId, substeps);
  const stepLevel = substep.stepLevel;
  const step = getStepForLevel(stepLevel, steps);
  const stepCode = step.code;
  const subpartData = getSubsetDataWParentsData(substepId, substeps);

  const configInteriorExterior =
    subpartData[0] && (subpartData[0] as PIOption).isExterior
      ? "configs.isExterior=true"
      : "configs.isInterior=true";

  const interiorExterior =
    subpartData[0] && (subpartData[0] as PIOption).isExterior
      ? "isExterior=true"
      : "isInterior=true";

  switch (stepCode) {
    case "PI":
      return doRequest("PI", null);
    case "TP":
      return doRequest(stepCode, `&configs.optionPI=${subpartData[0]!.value}`);

    // resolve([
    //   { type: "WHATEVS", value: "option/4", label: "plafond" },
    //   { type: "WHATEVS", value: "option/5", label: "sol" },
    //   { type: "WHATEVS", value: "option/6", label: "murs" }
    // ]);
    case "NS":
      return doRequest(
        stepCode,
        `&configs.optionTP=${subpartData[1]!.value}&${configInteriorExterior}`
      );
    case "ES":
      return doRequest(stepCode, null);
    case "TE":
      return doRequest(
        stepCode,
        `&configs.optionPI=${subpartData[0]!.value}&configs.optionTP=${
          subpartData[1]!.value
        }&configs.optionNS=${subpartData[2]!.value}`
      );
    // return Promise.resolve([
    //   { type: "TE", value: "/steps/42", id: "/steps/42", label: "Type Produit" },
    // ]);
    case "MA":
      return doRequest(
        stepCode,
        `&configs.optionTP=${subpartData[1]!.value}&configs.optionNS=${
          subpartData[2]!.value
        }&configs.optionTE=${subpartData[4]!.value}&${interiorExterior}`
      );

    case "PR":
      return doRequest(
        stepCode,
        `&configs.optionTP=${subpartData[1]!.value}&configs.optionNS=${
          subpartData[2]!.value
        }&configs.optionMA=${subpartData[5]!.value}&configs.optionES=${
          subpartData[3]!.value
        }&${interiorExterior}`
      );

    case "PO":
      return doRequest(
        stepCode,
        `&configs.optionTP=${subpartData[1]!.value}&configs.optionPI=${
          subpartData[0]!.value
        }&configs.optionMA=${subpartData[5]!.value}&${interiorExterior}`
      );
    // return Promise.resolve([
    //   { type: "PO", value: "/steps/41", id: "/steps/41", label: "Protection" },
    // ]);
    case "MO":
      return doMORequest(stepCode, subpartData[5]!.value);

    case "CO":
      return fetchNuancerForColorStep(
        subpartData[5]?.value ?? "",
        subpartData[8]?.value ?? null
      ).catch(error => {
        return [];
      });
    // return Promise.resolve([
    //   { type: "PI", value: "dd", id: "dd", label: "todo ddd" },
    //   {
    //     type: "PI",
    //     value: "cuddaqzdisine",
    //     id: "cuddaqzdisine",
    //     label: "todo ez"
    //   },
    //   { type: "PI", value: "sazlon", id: "sazlon", label: "todo fezfg" }
    // ],);

    // return apiGet<HydraCollection<{ label: string, code: string } & HydraMember>>(`/collections?option=${subpartData[5]}`)
    // .then(({ data }) => {
    //   return data["hydra:member"].map<StepOption>(entry => ({
    //     id: entry["@id"],
    //     type: stepCode,
    //     value: entry.code,
    //     label: entry.label
    //   }))
    // });

    default:
      // FIXME
      // eslint-disable-next-line no-throw-literal
      throw "error dans le step level";
  }
};
export type Products = {
  "@id": string | undefined;
  label: string | undefined;
  comment: string | undefined;
  chartRow: number | undefined;
  chartColumn: number | undefined;
  picture: string | undefined;
};

export type ColorsStepState = COOption[];
export const fetchNuancerForColorStep = (
  optionMA: string,
  optionMO: string | null
) => {
  const isWallpaper = optionMO !== null;
  const paramName = isWallpaper ? "collection" : "option";
  const paramValue = isWallpaper ? optionMO : optionMA;

  return apiGet<
    HydraCollection<
      {
        chartSize: number;
        label: string;
        sortOrder: number;
        archived: boolean;
        products: Array<
          HydraMember & {
            label: string;
            comment: string | null;
            chartRow: number | null;
            chartColumn: number | null;
            picture: string;
            isNone: true | null;
            isWarning: true | null;
          }
        >;
      } & HydraMember
    >
  >(`/products?${paramName}=${paramValue}`).then<COOption[]>(
    ({ data }) => {
      const colors = data["hydra:member"]
        .map((entry, index) =>
          entry.products.map<COOption>(prod => ({
            id: prod["@id"],
            value: prod["@id"],
            label: prod.label,
            type: "CO",
            isNone: Boolean(prod.isNone) ? true : undefined,
            isWarning: Boolean(prod.isWarning) ? true : undefined,
            comment: prod.comment,
            groupLabel: entry.label,
            chartRow: prod.chartRow,
            chartColumn: prod.chartColumn,
            picture: prod.picture,
            chartSize: entry.chartSize,
            sortOrder: entry.sortOrder,
            archived: entry.archived
          }))
        );
      const flatten = colors.reduce(function(prev, curr) {
        return prev.concat(curr)
      });
      return flatten;
    }
  );
};
//  id: entry["@id"],
//              value: entry["@id"],
//              type: "CO",
//              chartSize: entry.chartSize,
//              label: entry.label,
