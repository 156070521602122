import React, { useState } from "react";
import styles from "../styles/modules/login.module.scss";
import { useT } from "../containers/i18n-container";
import Carousel from "react-bootstrap/Carousel";
import * as CarouselItem from "react-bootstrap/lib/CarouselItem";

export const CarouselHome = () => {
  const [index, setIndex] = useState(1);
  const { pic } = useT();
  const background = (url: string) => ({
    backgroundImage: `url(${url})`
  });
  const handleNextClick = () => {
    let newIndex = index + 1;
    if (newIndex > 5) newIndex = 1;
    setIndex(newIndex);
  };
  const handlePrevClick = () => {
    let newIndex = index - 1;
    if (newIndex < 1) newIndex = 5;
    setIndex(newIndex);
  };
  return (
    <div className={`${styles["left"]} col-lg-6 col-md-12`}>
      <Carousel className={styles["left_carousel"]}>
        <Carousel.Item className={styles["left_carousel_item"]}>
          <img className={styles.left_picture} src={pic("img-home-slide-1")} />
        </Carousel.Item>
        <Carousel.Item className={styles['left_carousel_item']}>
          <img className={styles.left_picture} src={pic('img-home-slide-2')} />
        </Carousel.Item>
        <Carousel.Item className={styles['left_carousel_item']}>
          <img className={styles.left_picture} src={pic('img-home-slide-3')} />
        </Carousel.Item>
        <Carousel.Item className={styles['left_carousel_item']}>
          <img className={styles.left_picture} src={pic('img-home-slide-4')} />
        </Carousel.Item>
        <Carousel.Item className={styles["left_carousel_item"]}>
          <img className={styles.left_picture} src={pic("img-home-slide-5")} />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
