import groupBy from "ramda/src/groupBy";
import React from "react";
import {
  COOption,
  getQuestion,
  getComment,
  StepViewComponent
} from "../../model/steps/steps-model";
import { getSubstep } from "../../model/substeps/substeps-model";
import styles from "../../styles/modules/co-step-view.module.scss";
import { useStepViewState } from "../../utils/step-views-utils";
import { Color } from "../color";
import { OnlyOneOptionAlert } from "../only-one-option-alert";
import { QuestionTooltip } from "../question-tooltip";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";

export const COStepView: StepViewComponent = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange,
  onSkip
}) => {
  // const inputEl = useRef<HTMLInputElement>(null);

  const [cOformState, onChange, error, pending] = useStepViewState<COOption>(
    substepId,
    substeps,
    steps,
    selectedOptions,
    onOptionsChange,
    onSkip
  );

  const cOformStateValues = Object.values(cOformState);

  const groups = groupBy(entry => {
    return entry.option.groupLabel || "null";
  }, cOformStateValues);

  return (
    <div className="d-flex flex-column position-relative">
      <NoOptionAlert
        show={!pending && (cOformStateValues.length === 0 || error)}
      />
      <MaskingSpinner show={pending} />
      <h3 className="project_new m-auto">
        <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
        <QuestionTooltip
          tooltipText={getComment(getSubstep(substepId, substeps).stepLevel, steps)}
          questionClassName={`ico-question d-inline-block ml-3`}
        />
      </h3>
      <OnlyOneOptionAlert formStateValues={cOformStateValues} />
      <div className={styles["ui-color-body-step"]}>
        <div className={styles["ui-color-step"]}>
          <div className={styles["ui-color-step-grid"]}>
            {Object.values(groups)
              .sort((a, b) => {
                if (a && a[0] && a[0].option && a[0].option.sortOrder && b && b[0] && b[0].option && b[0].option.sortOrder) {
                  return a[0].option.sortOrder <= b[0].option.sortOrder ? -1 : 1
                } else {
                  return a <= b ? -1 : 1
                }
              })
              .map((nuan, idx) => (
                <div className={""} key={`${nuan}-${idx}`}>
                  <h3 className={styles["text"]}>
                    {nuan[0].option.groupLabel}{" "}
                  </h3>
                  <div
                    className={styles["ui-color-board"]}
                    style={{
                      gridTemplateColumns: `repeat(${
                        nuan[0].option.chartSize
                      }, ${
                        nuan[0].option.chartSize && nuan[0].option.chartSize > 0
                          ? 100 / nuan[0].option.chartSize + "%"
                          : "auto"
                      })`
                    }}
                  >
                    {nuan.map(product => (
                      <Color
                        key={product.id.split('/').pop()}
                        id={product.id}
                        onChange={onChange}
                        checked={product.checked}
                        label={product.label}
                        option={product.option}
                        comment={product.comment}
                      />
                    ))}

                    {/* <span>   {product.label}  </span> </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>

    // <div>
    //   Vue de Couleur

    //   {/* {
    //     formState.max ? <input ref={inputEl} type="range" onChange={onChange} value={formState.selected} min="0" max={formState.max}/> : null
    //   } */}

    //   {/* {Object.keys(formState).map(key => (
    //     <div key={key}>
    //       <input
    //         type="checkbox"
    //         name="piece"
    //         onChange={onChange}
    //         checked={formState[key]}
    //         value={key}
    //         id={key}
    //       />
    //       <label htmlFor={key}>{key}</label>
    //     </div>
    //   ))} */}
    // </div>
  );
};
