import { combineReducers } from "redux";
import { createSelector } from "reselect";
import authenticationReducer, {
  AuthenticationState
} from "./authentication/authentication-model";
import { i18nReducer, I18NState } from "./i18n/i18n-model";
import {
  progresBarReducer,
  progresBarState
} from "./progressBar/progress-bar-model";
import { projectsReducer, ProjectsState } from "./projects/projects-model";
import { templatesReducer, TemplatesState } from "./templates/templates-model";
import { stepsReducer, StepsState } from "./steps/steps-model";
import { substepsReducer, SubstepState } from "./substeps/substeps-model";
export interface MainState {
  i18n: I18NState;
  projects: ProjectsState;
  steps: StepsState;
  substeps: SubstepState;
  authentication: AuthenticationState;
  progresBar: progresBarState;
  templates: TemplatesState;
}

// export const initialState: MainState = {
//   i18n: undefined,
//   projects: undefined
// };

// export type MainState = typeof initialState;

export const mainProjectListSelector = createSelector(
  (state: MainState) => state.projects.byId,
  byId => {
    return Object.values(byId);
  }
);

export const makeUniqueProjectSelector = () =>
  createSelector(
    [
      (state: MainState) => state.projects.byId,
      (_: any, itemId: string) => itemId
    ],
    (byId, itemId) => byId[itemId]
  );

export const makeUniqueTemplateSelector = () =>
  createSelector(
    [
      (state: MainState) => state.templates.byId,
      (_: any, itemId: string) => itemId
    ],
    (byId, itemId) => byId[itemId]
  );

export const mainReducer = combineReducers({
  authentication: authenticationReducer,
  steps: stepsReducer,
  i18n: i18nReducer,
  projects: projectsReducer,
  substeps: substepsReducer,
  progresBar: progresBarReducer,
  templates: templatesReducer
});

// export const mainReducer = (state: MainState = initialState, action: AnyMainAction) => {
//   switch (action.type) {
//     case 'toto':
//       return state;
//     default:
//       return {...state, projects: projectsReducer(state.projects, action) };
//   }
// };
