import React, { FunctionComponent } from "react";
import { ProjectState } from "../model/projects/projects-model";
import {
  getSubsetWParents,
  shouldDisplaySubstep,
  Substep
} from "../model/substeps/substeps-model";
import { Indexed } from "../utils/collection-utils";
import { Link } from "react-router-dom";
import styles from "../styles/modules/breadcrumb.module.scss";
import {useT} from "../containers/i18n-container";

export interface OwnProps {
  selectedSubstepId?: string;
  substeps?: Indexed<Substep>;
  project?: ProjectState;
}

export const Breadcrumb: FunctionComponent<OwnProps> = ({
  selectedSubstepId,
  substeps,
  project
}) => {
  // const substep = getSubstep(selectedSubstepId, substeps);

  const { t } = useT();

  const shortenName = (name: string) => {
    return name.length > 22 ? name.substr(0, 20) + '...' : name;
  }

  const entries = [
    { link: "/main", label: t('lbl-project-new'), key: "main" },
    ...(project ? [] : [{ link: null, label: "Mon projet", key: "new" }]),
    ...(project && selectedSubstepId && substeps
      ? getSubsetWParents(selectedSubstepId, substeps)
          .reverse()
          // .slice(1)
          .filter(substep => shouldDisplaySubstep(substep.id, substeps))
          .map(substep => {
            let label;
            switch (substep.stepLevel) {
              case 0 :
                label = shortenName(project.label ?? '');
                break;
              case 1:
                label = substep?.creationData?.label ? shortenName(substep?.creationData?.label) : substep?.creationData?.labelShort ?? substep.name;
                break;
              case 2:
                label = substep?.creationData?.labelShort ?? substep.creationData?.label;
            }
            return {
              link: `/projects/${substep.projectId}/substeps/${substep.id}`,
              label: label,
              key: substep.id
            }
          })
      : [])
  ];

  return (
    <nav className={styles.breadcrumb}>
      <ul>
        {entries.map(({ link, label, key }) => (
          <li key={key}>{link ? <Link to={link}>{label}</Link> : label}</li>
        ))}
      </ul>
    </nav>
  );
};
