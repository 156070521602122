import React from "react";

export function SimpleList<T extends { id: string }>({
  list,
  className,
  entryClassName,
  render
}: {
  list: T[];
  className?: string;
  entryClassName?: string;
  render: (entry: T) => JSX.Element;
}) {
  return (
    <ul className={className || ""}>
      {list.map(entry => (
        <li className={entryClassName || ""} key={entry.id}>{render(entry)}</li>
      ))}
    </ul>
  );
}
