import { combineEpics, Epic } from "redux-observable";
import { from, of, empty } from "rxjs";
import {catchError, filter, map, mapTo, switchMap} from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";
import { MainState } from "../main-model";
import { navigateTo } from "../nav/nav-actions";
import {
  AnyAuthenticationAction, authDefaultUnitSystemError, authDefaultUnitSystemRequest, authDefaultUnitSystemSuccess,
  authDeleteAccountError,
  authDeleteAccountRequest,
  authDeleteAccountSuccess,
  authEditInfosError,
  authEditInfosRequest,
  authEditInfosSuccess,
  authEmailCheckError,
  authEmailCheckRequest,
  authEmailCheckSuccess,
  authGuestError,
  authGuestRequest,
  authGuestSuccess,
  authLanguagesError,
  authLanguagesRequest,
  authLanguagesSuccess,
  authLoginError,
  authLoginRequest,
  authLoginSuccess,
  authLogoutError,
  authLogoutRequest,
  authLogoutSuccess,
  authPasswordEditError,
  authPasswordEditRequest,
  authPasswordEditSuccess,
  authPasswordResetError,
  authPasswordResetRequest,
  authPasswordResetSuccess,
  authPicturesError,
  authPicturesRequest,
  authPicturesSuccess,
  authPreferenceEditError,
  authPreferenceEditRequest,
  authPreferenceEditSuccess,
  authRegisterError,
  authRegisterRequest,
  authUnitSystemError,
  authUnitSystemRequest,
  authUnitSystemSuccess,
  authCountriesError,
  authCountriesRequest,
  authCountriesSuccess,
  authDepartmentsError,
  authDepartmentsRequest,
  authDepartmentsSuccess,
  authProfessionsError,
  authProfessionsRequest,
  authProfessionsSuccess,
  authUserRoleError,
  authUserRoleRequest,
  authUserRoleSuccess
} from "./authentication-actions";
import {
  authDefaultUnitSystemFromApi,
  authDeleteAccountFromApi,
  authEditInfosFromApi,
  authEmailCheckFromApi,
  authLanguagesFromApi,
  authLoginFromApi,
  authPasswordEditFromApi,
  authPasswordResetFromApi,
  authPicturesFromApi,
  authPrefEditFromApi,
  authRegisterAndLoginFromApi,
  authUnitSystemFromApi,
  authCountriesFromApi,
  authDepartmentsFromApi,
  authProfessionsFromApi,
  authUserEditRoleFromApi
} from "./authentication-service";
import {
  authUpdateUserLanguage, USER_ROLES
} from "./authentication-model";

export const authLanguagesEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authLanguagesSuccess | typeof authLanguagesError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authLanguagesRequest)),
    switchMap(action => {
      return from(authLanguagesFromApi()).pipe(
        map(authLanguagesSuccess),
        catchError(error => {
          return of(authLanguagesError(error));
        })
      );
    })
  );

export const authDefaultUnitSystemEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authDefaultUnitSystemSuccess | typeof authDefaultUnitSystemError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authDefaultUnitSystemRequest)),
    switchMap(action => {
      return from(authDefaultUnitSystemFromApi()).pipe(
        map(authDefaultUnitSystemSuccess),
        catchError(error => {
          return of(authDefaultUnitSystemError(error));
        })
      );
    })
  );

export const authPreferenceEditEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authPreferenceEditSuccess | typeof authPreferenceEditError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authPreferenceEditRequest)),
    switchMap(action => {
      const userId = state$.value.authentication.data?.user["@id"];
      if (!userId) {
        return empty();
      }
      return from(authPrefEditFromApi(userId, action.payload)).pipe(
        map(data => authUpdateUserLanguage(state$.value.authentication, data)),
        map(authPreferenceEditSuccess),
        catchError(error => {
          return of(authPreferenceEditError(error));
        })
      );
    })
  );

export const authPasswordEditEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authPasswordEditSuccess | typeof authPasswordEditError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authPasswordEditRequest)),
    switchMap(action => {
      const userId = state$.value.authentication.data?.user["@id"];
      if (!userId) {
        return empty();
      }
      return from(authPasswordEditFromApi(userId, action.payload)).pipe(
        map(authPasswordEditSuccess),
        catchError(error => {
          return of(authPasswordEditError(error));
        })
      );
    })
  );

export const authPasswordResetEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authPasswordResetSuccess | typeof authPasswordResetError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authPasswordResetRequest)),
    switchMap(action => {
      return from(authPasswordResetFromApi(action.payload)).pipe(
        map(authPasswordResetSuccess),
        catchError(error => {
          return of(authPasswordResetError(error));
        })
      );
    })
  );

export const authPasswordResetSuccessEpic: Epic<
  AnyAuthenticationAction,
  any,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authPasswordResetSuccess)),
    map(() => navigateTo("/login"))
  );

export const authEditInfosEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authEditInfosSuccess | typeof authEditInfosError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authEditInfosRequest)),
    switchMap(action => {
      const userId = state$.value.authentication.data?.user["@id"];
      if (!userId) {
        return empty();
      }
      return from(authEditInfosFromApi(userId, action.payload)).pipe(
        map(authEditInfosSuccess),
        catchError(error => {
          return of(authEditInfosError(error));
        })
      );
    })
  );

export const authUnitSystemEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authUnitSystemSuccess | typeof authUnitSystemError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authUnitSystemRequest)),
    switchMap(action => {
      return from(authUnitSystemFromApi()).pipe(
        map(authUnitSystemSuccess),
        catchError(error => {
          return of(authUnitSystemError(error));
        })
      );
    })
  );

export const authCountriesEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authCountriesSuccess | typeof authCountriesError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authCountriesRequest)),
    switchMap(action => {
      return from(authCountriesFromApi()).pipe(
        map(authCountriesSuccess),
        catchError(error => {
          return of(authCountriesError(error));
        })
      );
    })
  );

export const authDepartmentsEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authDepartmentsSuccess | typeof authDepartmentsError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authDepartmentsRequest)),
    switchMap(action => {
      return from(authDepartmentsFromApi()).pipe(
        map(authDepartmentsSuccess),
        catchError(error => {
          return of(authDepartmentsError(error));
        })
      );
    })
  );

export const authProfessionsEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authProfessionsSuccess | typeof authProfessionsError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authProfessionsRequest)),
    switchMap(action => {
      return from(authProfessionsFromApi()).pipe(
        map(authProfessionsSuccess),
        catchError(error => {
          return of(authProfessionsError(error));
        })
      );
    })
  );

export const authPicturesEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authPicturesSuccess | typeof authPicturesError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authPicturesRequest)),
    switchMap(action => {
      return from(authPicturesFromApi()).pipe(
        map(authPicturesSuccess),
        catchError(error => of(authPicturesError(error)))
      );
    })
  );

export const authLoginEpic: Epic<AnyAuthenticationAction, any, MainState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(authLoginRequest)),
    switchMap(action => {
      return from(authLoginFromApi(action.payload.data)).pipe(
        switchMap(data => of(authLoginSuccess(data, action.payload.redirect))),
        catchError(error => of(authLoginError(error)))
      );
    })
  );

export const authLoginSuccessEpic: Epic<
  AnyAuthenticationAction,
  any,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authLoginSuccess)),
    switchMap(action =>
      of(
        navigateTo(action.payload.redirect ?
          action.payload.redirect :
            action.payload.data.user.roles.includes(USER_ROLES.ROLE_ADMIN) || action.payload.data.user.roles.includes(USER_ROLES.ROLE_SUPERADMIN) ?
              "/admin" :
              "/main"
        )
      )
    )
  );

export const authRegisterEpic: Epic<
  AnyAuthenticationAction,
  any,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authRegisterRequest)),
    switchMap(action => {
      return from(authRegisterAndLoginFromApi(action.payload.data)).pipe(
        switchMap(data => of(authLoginSuccess(data, action.payload.redirect))),
        catchError(error => of(authRegisterError(error)))
      );
    })
  );

export const authLogoutEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authLogoutSuccess | typeof authLogoutError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authLogoutRequest)),
    mapTo(authLogoutSuccess())
  );

export const authLogoutSuccessEpic: Epic<
  AnyAuthenticationAction,
  any,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authLogoutSuccess)),
    map(() => navigateTo("/"))
  );

export const authGuestEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authGuestSuccess | typeof authGuestError>,
  MainState
> = (action$, state$) =>
  action$.pipe(filter(isActionOf(authGuestRequest)), mapTo(authGuestSuccess()));

export const authEmailCheckEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authEmailCheckSuccess | typeof authEmailCheckError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authEmailCheckRequest)),
    switchMap(action => {
      return from(authEmailCheckFromApi(action.payload)).pipe(
        map(authEmailCheckSuccess),
        catchError(error => of(authEmailCheckError(error)))
      );
    })
  );

export const authEmailCheckSuccessEpic: Epic<
  AnyAuthenticationAction,
  any,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authEmailCheckSuccess)),
    map(() => navigateTo("/"))
  );

export const authDeleteAccountEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authDeleteAccountSuccess | typeof authDeleteAccountError>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authDeleteAccountRequest)),
    switchMap(action => {
      const userId = state$.value.authentication.data?.user["@id"];
      if (!userId) {
        return empty();
      }
      return from(authDeleteAccountFromApi(userId, action.payload.data)).pipe(
        map(res => authDeleteAccountSuccess(res, action.payload.dispatch)),
        catchError(error => {
          return of(authDeleteAccountError(error));
        })
      );
    })
  );

export const authUserRoleEpic: Epic<
  AnyAuthenticationAction,
  ActionType<typeof authUserRoleSuccess | typeof authUserRoleError>,
  MainState
  > = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(authUserRoleRequest)),
    switchMap(action => {
      return from(authUserEditRoleFromApi(action.payload.id, action.payload.roles)).pipe(
        switchMap(data => of(authUserRoleSuccess(action.payload.id, action.payload.roles))),
        catchError(error => of(authUserRoleError(error, action.payload.id, action.payload.roles)))
      );
    })
  );

export const authenticationEpic = combineEpics(
  authPasswordEditEpic,
  authPasswordResetEpic,
  authPasswordResetSuccessEpic,
  authEditInfosEpic,
  authLanguagesEpic,
  authUnitSystemEpic,
  authCountriesEpic,
  authDepartmentsEpic,
  authProfessionsEpic,
  authDefaultUnitSystemEpic,
  authPicturesEpic,
  authLoginSuccessEpic,
  authPreferenceEditEpic,
  authLoginEpic,
  authRegisterEpic,
  authLogoutEpic,
  authLogoutSuccessEpic,
  authGuestEpic,
  authEmailCheckEpic,
  authEmailCheckSuccessEpic,
  authDeleteAccountEpic,
  authUserRoleEpic
);
