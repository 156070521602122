import axios, { AxiosResponse, AxiosInstance } from "axios";
import { isValidToken } from "../model/authentication/authentication-model";
import { History } from "history";

export type HydraError = {
  "@context": string;
  "@type": string;
  "hydra:description": string;
  "hydra:title": string;
  trace: [];
};

type AxiosError = {
  isAxiosError: boolean;
  toString: () => string;
  response: { data: any; status: number };
};

// const getLocalstorage = () => {
//   return data ? `bearer ${data.token}` : undefined;
// };

const updateInstanceToken = () => {
  const token = sessionStorage.getItem("token");

  if (token && isValidToken(token)) {
    serverGlobals.instance.defaults.headers.common.Authorization = `bearer ${token}`;
  } else {
    sessionStorage.setItem("token", "");
    serverGlobals.instance.defaults.headers.common.Authorization = undefined;
  }
  // const data = JSON.parse(!).authentication.data;)
};

const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError ? true : false;
};

export type AnyServerError = HydraError | AxiosError;

export const apiBaseUrl = process.env.REACT_APP_API_BASEURL;

export interface ApiError {
  data?: any;
}

export const serverGlobals: {
  instance: AxiosInstance;
  routerHistory?: History;
} = {
  instance: axios.create({
    baseURL: apiBaseUrl
  }),
  routerHistory: undefined
};

//instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

const handlErrors = (redirectOn401: boolean) => (error: AnyServerError) => {
  if (isAxiosError(error)) {
    if (error.response.status === 401) {
      sessionStorage.setItem("token", "");
      if (redirectOn401) {
        window.location.href = window.location.origin;
      }
    }
    // FIXME
    // eslint-disable-next-line no-throw-literal
    throw error.response.data
      ? { data: error.response.data }
      : { data: error.toString() };
  } else {
    // FIXME
    // eslint-disable-next-line no-throw-literal
    throw { data: error }; //error["hydra:description"];
  }
};

export const apiGet = <ResponseDataType>(route: string) => {
  updateInstanceToken();

  const locale =
    sessionStorage.getItem("locale") ??
    window.navigator.language.slice(0, 2).toUpperCase();

  return serverGlobals.instance
    .get<null, AxiosResponse<ResponseDataType>>(
      route.includes("?")
        ? `${route}&locale=${locale}`
        : `${route}?locale=${locale}`
    )
    .catch(handlErrors(true));
};

export const apiPost = <BodyType, ResponseDataType>(
  route: string,
  body: BodyType,
  redirectOnAuthError: boolean = true
) => {
  updateInstanceToken();
  return serverGlobals.instance
    .post<BodyType, AxiosResponse<ResponseDataType>>(route, body)
    .catch(handlErrors(redirectOnAuthError));
};

export const apiPut = <BodyType, ResponseDataType>(
  route: string,
  body: BodyType
) => {
  updateInstanceToken();
  return serverGlobals.instance
    .put<BodyType, AxiosResponse<ResponseDataType>>(route, body)
    .catch(handlErrors(true));
};
