import React from "react";
import { useSelector } from "react-redux";
import { MainState } from "../model/main-model";
import {getStepForCode, isLastStep, isSecondToLastStep} from "../model/steps/steps-model";
import { getChildren, Substep, getCurrentSubstepForProject } from "../model/substeps/substeps-model";
import { Indexed } from "../utils/collection-utils";
import { useT } from "../containers/i18n-container";
import styles from "../styles/modules/project-tree.module.scss";
import { getSubstepLink } from "../utils/router-utils";
import { Link, useParams } from "react-router-dom";

export const TreeEntry = ({
  substep,
  substeps,
  className,
  project,
  onClose
}: {
  substep: Substep;
  project: string;
  className: string;
  substeps: Indexed<Substep>;
  onClose: any;
}) => {
  const { t } = useT();
  const params = useParams<{ substep?: string, project?: string}>();
  const steps = useSelector((state: MainState) => state.steps.steps);
  if (!steps) {
    return null;
  }

  const isBlank =
    substep.creationData === null || substep.creationData.value === null;
  const isSecondToLast = isSecondToLastStep(substep.stepLevel, steps);
  const children = getChildren(substep.id, substeps);
  const hasChildren = children.length > 0;
  const isRoot = substep.stepLevel === 0;
  const stepLabel = (substep.stepLevel > 1) ? steps[substep.stepLevel-1].label : steps[0].label;
  const optionName = (substep.stepLevel > 1) ? substep.name : substep?.creationData?.label;
  const name = optionName ?? t("lbl-no-name");
  const isCurrent = getCurrentSubstepForProject(project, substeps)?.id === substep.id;
  const isOpened = params.substep === substep.id;

  const HolderComponent: React.FC = ({ children }) =>
    isRoot ? <React.Fragment>{children}</React.Fragment> : <li>{children}</li>;

  if (!isRoot && isBlank) {
    return (
      <React.Fragment>
        {children.map(entry => (
          <TreeEntry
            key={entry.id}
            project={project}
            className={className}
            substep={entry}
            substeps={substeps}
            onClose={onClose}
          />
        ))}
      </React.Fragment>
    );
  }

  return (
    <HolderComponent>
      {!isRoot ? (
        <Link className={`${isCurrent ? styles.current : ''} ${isOpened ? styles.opened : ''}`} to={getSubstepLink(project, substep?.parent)} onClick={onClose}>
          <em>{stepLabel} :</em> <strong>{name}</strong>
        </Link>
      ) : (
        ""
      )}
      {hasChildren && !isSecondToLast ? (
        <ul className={className}>
          {children.map(entry => (
            <TreeEntry
              project={project}
              key={entry.id}
              className={className}
              substep={entry}
              substeps={substeps}
              onClose={onClose}
            />
          ))}
        </ul>
      ) : null}
    </HolderComponent>
  );
};
