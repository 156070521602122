import React, { Component, FormEvent } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import { Dispatch } from "redux";
import { authEmailCheckRequest } from "../../model/authentication/authentication-actions";
import { MainState } from "../../model/main-model";
import styles from "../../styles/modules/ui-register.module.scss";
import { ApiError } from "../../utils/server-utils";
import { PropsType } from "../../utils/ts-utils";
import { Tra } from "../../components/tra";
import { TranslationContext } from "../i18n-container";
import { I18NState } from "../../model/i18n/i18n-model";

type OwnProps = RouteComponentProps;
export type EmailCheckState = {
  email: string;
  errors: any;
};

type Props = PropsType<
    OwnProps,
    typeof mapStateToProps,
    typeof mapDispatchToProps
    >;

class EmailCheckComponent extends Component<
    Props,
    EmailCheckState & { shown: boolean }
    > {
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef<HTMLFormElement>();
    this.state = {
      email: "",
      errors: {},
      shown: false
    };
  }

  formRef: React.RefObject<HTMLFormElement>;

  handleForm = (e?: FormEvent) => {
    e?.preventDefault();
    const data: EmailCheckState = {
      email: this.state.email,
      errors: this.state.errors
    }

    this.props.setEmailCheck(data);
  };

  handleInput = (e: any) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    this.setState({[name]: value } as any);
    this.setState({errors: {} } as any);
  };

  render() {
    const error = this.props.emailCheckErrors;
    return (
        <React.Fragment>
          <TranslationContext.Consumer>
            {({ texts }: I18NState) => (
                <div>
                  <Link
                      to="#"
                      className={styles.account_pwd}
                      onClick={() => this.setState({ shown: true })}
                  >
                    <Tra code="btn-form-recover-password" />
                  </Link>
                  <Modal
                      show={this.state.shown}
                      className={`${styles["ui-register-modal"]}`}
                      onHide={() => this.setState({ shown: false })}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <h2>
                          <Tra code="title-form-forgotten-password" />
                        </h2>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Tra code="text-form-forgotten-password" />
                      <div className="ui ui-login">
                        <div className={`${styles["ui-login-wrapper"]}`}>
                          <div className={`${styles["ui-register"]}`}>
                            <form ref={this.formRef}>
                              <div>
                                <label>
                                  <Tra code="lbl-form-email" />
                                </label>
                                {handleError(error)}
                                <input
                                    type="text"
                                    name="email"
                                    placeholder={
                                      texts["lbl-form-forgotten-email"]
                                    }
                                    onChange={this.handleInput}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                          variant="secondary"
                          onClick={() => this.setState({ shown: false })}
                      >
                        <Tra code="btn-form-close" />
                      </Button>
                      <Button variant="primary" onClick={() => this.handleForm()}>
                        <Tra code="btn-form-account-recover" />
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
            )}
          </TranslationContext.Consumer>
        </React.Fragment>
    );
  }
}

const handleError = (error: ApiError | undefined) => {
  if ( error?.data ) {
    const alert = (
        <Alert
            variant="warning"
            className={styles["ui-error"]}
            key={`email`}
        >
          {error?.data['hydra:description']}
        </Alert>
    )
    return alert;
  }
  return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEmailCheck: (data: EmailCheckState) => {
    return dispatch(authEmailCheckRequest(data));
  }
});

const mapStateToProps = (state: MainState) => ({
  emailCheckErrors: state.authentication.errors?.check
});

export const EmailCheck = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EmailCheckComponent)
);

