import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { MainState } from "../model/main-model";
import { ProjectState } from "../model/projects/projects-model";
import { getStepList, StepList } from "../model/steps/steps-model";
import {
  getCurrentSubstepForProject,
  getRootSubstep,
  getSubpartsTree,
  makeUniqueProjectSubstepsSelector,
  SubpartsTree,
  Substep
} from "../model/substeps/substeps-model";
import { Indexed } from "../utils/collection-utils";
import { PropsType } from "../utils/ts-utils";

export type SubstepEntryComponent = FunctionComponent<{
  substep: Substep;
  steps: StepList;
  projectName: string | null;
  substeps: Indexed<Substep>;
  isCurrent?: boolean;
  isSelected?: boolean;
}>;

interface OwnProps {
  project: ProjectState;
  displayAll?: boolean;
  SubstepEntryComponent: SubstepEntryComponent;
}

const mapStateToProps = (state: MainState) => {
  const substepsSelector = makeUniqueProjectSubstepsSelector();

  return (state: MainState, props: OwnProps) => ({
    steps: getStepList(state.steps),
    substeps: substepsSelector(state, props.project.id)
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // fetchProjects: () => dispatch(fetchProjectsRequest())
});

const isCurrent = (substep: Substep, substeps: Indexed<Substep>) => {
  const current = getCurrentSubstepForProject(substep.projectId, substeps);
  if (!current) {
    return false;
  }

  return current.id === substep.id;
};

const recursiveChildrenRender = (
  subpartsTree: SubpartsTree,
  props: Props,
  stripRoot?: boolean
) => {
  const { project, substeps, steps, match, SubstepEntryComponent } = props;
  // const substep = getSubstep(substepId, substeps);
  const { substep } = subpartsTree;
  const selectedSubstepId = match.params.substepId;
  console.log("--------->",substep.name, substep.stepLevel);

  if (!steps) {
    return null;
  }

  return (
    <div>
      {!stripRoot ? (
        <SubstepEntryComponent
          substep={substep}
          substeps={substeps}
          steps={steps}
          projectName={project.label}
          isSelected={selectedSubstepId === substep.id}
          isCurrent={isCurrent(substep, substeps)}
        />
      ) : null}
      <ul>
        {subpartsTree.children
          .sort(
            (a, b) =>
              (a.substep.creationData?.sortOrder ?? 0) -
              (b.substep.creationData?.sortOrder ?? 0)
          )
          .map(subpart => {
            // const child = getSubstep(id, substeps);
            const child = subpart.substep;
            // if (!shouldDisplaySubstep(child.id, substeps) && !displayAll) {
            //   return null;
            // }
            return (
              <li key={child.id}>
                {/* <SubstepEntryComponent
                substep={child}
                substeps={substeps}
                steps={steps}
                isSelected={selectedSubstepId === id}
                isCurrent={isCurrent(child, substeps)}
              /> */}
                {recursiveChildrenRender(subpart, props)}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

type Props = PropsType<
  OwnProps,
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps
> &
  RouteComponentProps<{
    substepId?: string;
  }>;

export const Comp = (props: Props) => {
  const { project, substeps, steps } = props;
  if (!steps) {
    return null;
  }

  const rootSubstep = getRootSubstep(project.id, substeps);
  if (!rootSubstep) {
    return null;
  }

  const subpartsTree = getSubpartsTree(rootSubstep.id, substeps);
  return recursiveChildrenRender(subpartsTree, props, true);
};

export const SubstepList = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comp)
);
