import { HydraCollection, HydraMember } from "../../utils/hydra-utils";
import {
  AnyServerError,
  apiGet,
  apiPost,
  apiPut
} from "../../utils/server-utils";
import { MainState } from "../main-model";
import { getStepList } from "../steps/steps-model";
import { saveSubparts, StepForRecap } from "../substeps/substeps-service";
import { SaveProjectRequestPayloadOptions } from "./projects-actions";
import {
  getProjectById,
  hasProjectAlreadyBeenSaved,
  ProjectState
} from "./projects-model";
import cuid from "cuid";

export type ServerProject = HydraMember & {
  label: string;
  dateCreation: string;
  dateEdition: string | null;
  dateLastUpdate: string;
  isTemplate: boolean;
  hash: string;
  isArchived: boolean;
  isDone: boolean;
  isEdited: boolean;
  user: string | null;
  frontendId: string;
  pdf: string | null;
  cartUrl: string | null;
  projectParts: string[];
  isInvalid: boolean;
};

export type ServerStepOption = HydraMember & {
  code: string;
  sortOrder: number;
  label: string;
  labelShort?: string;
};

export type PIServerStepOption = ServerStepOption & {
  isInterior: boolean | null;
  isExterior: boolean | null;
};

export type ServerProjectPart = HydraMember & {
  project: string;
  codePI: number;
  labelPI: string;
  optionPI: PIServerStepOption;
  optionTP: ServerStepOption;
  optionNS: ServerStepOption;
  optionES: ServerStepOption;
  optionTE: ServerStepOption;
  optionMA: ServerStepOption;
  optionPR: ServerStepOption;
  optionPO: ServerStepOption;
  area: number;
  paramArea: {
    total: number;
    in: { length: number; height: number; area: number }[];
    out: { length: number; height: number; area: number }[];
    unitSystem: string;
  };
  isDone: boolean;
  products: [];
  product: any;
};

export const fetchProjectsFromApi = (id?: string) => {
  return apiGet<HydraCollection<ServerProject>>( id ? "/projects?archive=true&id=" + id : "/projects?archive=true" )
    .then(response => response.data["hydra:member"])
    .then(projectsData => {
      return {
        projectsData, subpartsData: []
      };
    });
};

export const fetchProjectFromApi = (id: string) => {
  return apiGet<HydraCollection<ServerProject>>("/projects?archive=true&id=" + id)
    .then(response => response.data["hydra:member"])
    .then(projectsData => {
      return Promise.all(
        projectsData
          .map(serverProj =>
            serverProj.projectParts.map(projectPartId => {
              return apiGet<ServerProjectPart>(projectPartId).then(
                response => response.data
              );
            })
          )
          .flat()
      ).then(subpartsData => {
        return {
          projectsData,
          subpartsData
        };
      });
    });
};

/*export const fetchProjectFromApi = (id: string) => {
  return apiGet<ServerProject>(`/projects/${id}`)
    .then(response => response.data)
    .then(projectData => {
      console.log(projectData);
      return apiGet<HydraCollection<ServerProjectPart>>(
        `project_parts?project=${projectData["@id"]}`
      )
        .then(response => response.data)
        .then(data => {
          console.log(data);

          return { projectData, subpartsData: data["hydra:member"] };
        });
    });
  // .then(({ projectData, projectPartIds }) => {
  //   return Promise.all(
  //     projectPartIds.map(projectPartId => {
  //       return apiGet<ServerProjectPart>(projectPartId).then(
  //         response => response.data
  //       );
  //     })
  //   ).then(subpartsData => ({
  //     projectData,
  //     subpartsData
  //   }));
  // });
};*/

export type SubpartSavingError = { projectBackendId: string };

export const isSubpartSavingError = (error: any): error is SubpartSavingError =>
  Boolean(error.projectBackendId);

export const saveProject = (
  projectId: string,
  options: SaveProjectRequestPayloadOptions,
  state: MainState
) => {
  const project = getProjectById(state.projects, projectId);
  let hasAlreadyBeenSaved = hasProjectAlreadyBeenSaved(
    projectId,
    state.projects
  );

  type SaveProjectBody = {
    label: string;
    frontendId: string;
    isEdited?: boolean;
    isArchived?: boolean;
    isTemplate?: boolean;
    isDisabled?: boolean;
    renewParts?: boolean;
  };

  const body = {
    label: project.label!,
    frontendId: project.id,
    renewParts: true
  };

  const saveProjectPromise = hasAlreadyBeenSaved
    ? apiPut<SaveProjectBody, ServerProject>(`${project.backendId}`, body)
    : apiPost<SaveProjectBody, ServerProject>("/projects", body);

  return saveProjectPromise.then(response => {
    const createdProjectData = response.data;

    const steps = getStepList(state.steps);

    if (!steps) {
      throw new Error("no steps");
    }

    return saveSubparts(
      projectId,
      createdProjectData["@id"],
      state.substeps.byId,
      steps
    )
      .then(() => {
        if (
          options.shouldEdit ||
          options.shouldArchive ||
          options.shouldSetAsTemplate ||
          options.shouldDelete
        ) {
          return apiPut<SaveProjectBody, ServerProject>(
            `${createdProjectData["@id"]}`,
            {
              label: project.label!,
              frontendId: project.id,
              isEdited: options.shouldEdit,
              isArchived: options.shouldArchive,
              isTemplate: options.shouldSetAsTemplate,
              isDisabled: options.shouldDelete
            }
          ).then(response => {
            return {
              data: response.data,
              pdfDownloadURL: response.data.pdf,
              cartUrl: response.data.cartUrl
            };
          });
        }

        return {
          data: createdProjectData,
          pdfDownloadURL: createdProjectData.pdf,
          cartUrl: createdProjectData.cartUrl
        };
      })
      .catch((_error: AnyServerError) => {
        const subpartError: SubpartSavingError = {
          projectBackendId: createdProjectData["@id"]
        };
        throw subpartError;
      });
  });
};

export const duplicateProject = (
  projectId: string,
  projectLabel: string,
  state: MainState
) => {
  const project = getProjectById(state.projects, projectId);
  type DuplicateProjectBody = {
    label: string;
    frontendId: string;
  };
  const body = {
    label: projectLabel,
    frontendId: cuid()
  };
  return apiPost<DuplicateProjectBody, ServerProject>(
    `${project.backendId}/duplicate`,
    body
  ).then(response => response.data);
};

export const fetchItemsList = (project: ProjectState) =>
  apiGet<
    HydraCollection<
      HydraMember & {
        quantity: number;
        project: string;
        article: HydraMember & {
          oxatisId: string;
          reference: string;
          label: string;
          picture: string | null;
          url: string;
          isDisabled: boolean;
        };
        cartAlert: string | null;
        availabilityAlert: string | null;
      }
    >
  >(`/project_articles?archive=true&project=${project.backendId}`).then(
    response => response.data["hydra:member"]
  );

export const recapProjectService = (dataToSentForRecap: StepForRecap[]) => {
  // console.log(dataToSentForRecap)

  return apiPost<StepForRecap[], any>(
    "/project_overview",
    dataToSentForRecap
  ).then(response => response.data);

  // return new Promise((resolve, reject) => {
  //   // temp, appeler ici la route pour les options
  //   window.setTimeout(() => {
  //     resolve([
  //       {
  //         label: "Salle bain",
  //         children: [
  //           {
  //             id: 1,
  //             label: "Plafond",
  //             area: "14m2",
  //             products: [
  //               {
  //                 label: "EBC Lavande",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               },
  //               {
  //                 label: "Primaire A",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               },
  //               {
  //                 label: "Protection A",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               }
  //             ]
  //           },
  //           {
  //             id: 2,
  //             label: "Sol et escalier",
  //             area: "14m2",
  //             products: [
  //               {
  //                 label: "EBC Lavande",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               },
  //               {
  //                 label: "Primaire B",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               },
  //               {
  //                 label: "Protection B",
  //                 quantity: "2,5kg",
  //                 url: "http://google.com",
  //                 picture: "https://dummyimage.com/50x50/000/fff.jpg"
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]);
  //   });
  // });
};

// {
//   "labelPI": "Chambre 2",
//   "optionPI": "/options/22",
//   "optionTP": "/options/24",
//   "optionNS": "/options/26",
//   "optionES": "/options/28",
//   "optionTE": "/options/30",
//   "optionMA": "/options/32",
//   "optionPR": "/options/34",
//   "optionPO": "/options/36",
//   "paramArea": {
//       "unit": "/units/2",
//       "in": [
//           {
//               "length": 10,
//               "height": 2
//           },
//           {
//               "length": 4,
//               "height": 2
//           }
//       ],
//       "out": [
//           {
//               "length": 1,
//               "height": 0.5
//           }
//       ]
//   }
// },
