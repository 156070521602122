import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import { getRootSubstep } from "../model/substeps/substeps-model";
import styles from "../styles/modules/project-tree.module.scss";
import { TreeEntry } from "./project-tree-entry";
import {Tra} from "./tra";

export const ProjectTree = ({ projectId }: { projectId: string }) => {
  const [show, setShow] = useState(false);

  const selector = useMemo(makeUniqueProjectSelector, []);
  const project = useSelector((state: MainState) => selector(state, projectId));
  const substeps = useSelector((state: MainState) => state.substeps.byId);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow} className={styles["tree-button"]}>
        <Tra code="btn-display-tree"/>
      </Button>

      <Modal show={show} onHide={handleClose} dialogClassName={styles.modal90W}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles["modal-body"]}>
          <TreeEntry
            project={projectId}
            substep={getRootSubstep(projectId, substeps)}
            substeps={substeps}
            className={styles.tree}
            onClose={handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <Tra code="btn-close"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
