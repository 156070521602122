import { apiGet } from "../../utils/server-utils";

export const getI18NData = () =>
  apiGet<{ [index: string]: string }>(
    `/translated_texts?type=WWW`
  )
    .then(response => {
      return response.data;
    })
    .then(textData =>
      apiGet<{ [index: string]: string }>(
        `/translated_pictures?type=WWW`
      ).then(response => ({
        texts: textData,
        pictures: response.data
      }))
    );
