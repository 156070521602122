import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import { withRouter } from "react-router-dom";
import {BottomStepName} from "../components/bottom-step-name";
import {MainState, makeUniqueProjectSelector} from "../model/main-model";
import {getSubstep} from "../model/substeps/substeps-model";

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  // guestUser: state.authentication.guest
  return (
    state: MainState,
    props: RouteComponentProps<{
      projectId?: string;
      substepId?: string;
    }>
  ) => {
    const project = props.match.params.projectId
      ? selector(state, props.match.params.projectId)
      : null;

    const substep =
      project && props.match.params.substepId
        ? getSubstep(props.match.params.substepId, state.substeps.byId)
        : null;

    if (substep === null) {
      return {
        projectName: project ? project.label : null,
        piName: null,
        tpName: null,
        urlPicture: null
      }
    }

    const rootPIName = substep!.stepLevel >= 2 ? state.progresBar.progresBarState[0]?.option : null;
    const tpName = substep!.stepLevel >= 3 ? state.progresBar.progresBarState[1]?.option : null;
    const projectName = project ? project.label : null;
    const urlPicture = substep!.stepLevel >= 1 ? state.progresBar.progresBarState[0]?.thumbnail : null;

    return {
      projectName: substep!.stepLevel <= 2 ? projectName : null,
      piName: rootPIName,
      tpName: tpName,
      urlPicture: urlPicture
    };
  };
};

// const Truc = withRouter(BottomStepName);

export const BottomStepNameContainer = withRouter(connect(mapStateToProps)(BottomStepName));
