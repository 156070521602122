import cuid from "cuid";
import { getType } from "typesafe-actions";
import {
  addEntry,
  collectionToIndexed,
  // mergeIndexedRight,
  editEntry,
  Indexed
} from "../../utils/collection-utils";
import { formatDate } from "../../utils/date-utils";
import { AnyStepOptions, StepList, StepOption } from "../steps/steps-model";
import {
  addProjectRequest,
  AnyProjectAction,
  duplicateProjectSuccess,
  fetchProjectsError,
  fetchProjectsRequest,
  fetchProjectsSuccess, fetchProjectSuccess,
  saveProjectError,
  saveProjectRequest,
  // setSubstepOptions
  saveProjectSuccess,
  setNewProjectData
} from "./projects-actions";
import { Substep, isProjectDone } from "../substeps/substeps-model";
import { createSelector } from "reselect";
import { MainState } from "../main-model";
import { duplicateTemplateSuccess } from "../templates/templates-actions";
import { AnyMainAction } from "../main-actions";
import { groupBy } from "ramda";

const initialState = {
  byId: {},
  dirty: false,
  error: null,
  newProjectData: null
};

export interface ProjectState {
  id: string;
  backendId: string | null;
  // subProjectsTree: Substep;
  // currentStep: string;
  dirty: boolean;
  label: string | null;
  dateCreation: string;
  dateEdition: string | null;
  dateLastUpdate: string;
  isTemplate: boolean;
  isArchived: boolean;
  pdf: string | null;
  cartUrl: string | null;
  isEdited: boolean;
  isDone: boolean;
  user: string | null;
  hash: string | null;
  error: {} | null;
  isInvalid: boolean;
}
export interface ProjectsState {
  byId: Indexed<ProjectState>;
  dirty: boolean;
  error: null | string;
  newProjectData: {
    name: string;
    selectedOptions: AnyStepOptions[] | null;
  } | null;
  // selected?: string;
}
export type SubStepRecap = { id: number }[];
export type RecapResponse = { label: string; children: SubStepRecap }[];

export const getNewProjectId = (projects: ProjectsState) => cuid();

export const getProjectList = (state: ProjectsState, user?: string) => {
  if (user) {
    return Object.values(state.byId).filter(proj =>
      proj.user ? proj.user === user : true
    );
  }
  return Object.values(state.byId);
};

export const projectListSelector = createSelector(
  (state: MainState) => state.projects,
  getProjectList
);

export const getProjectById = (state: ProjectsState, id: string) =>
  state.byId[id];

export const hasProjectAlreadyBeenSaved = (
  projectId: string,
  state: ProjectsState
) => getProjectById(state, projectId).backendId !== null;

export const createNewProject = (
  id: string,
  label: string,
  steps: StepList
): ProjectState => ({
  id,
  // currentStep: 'PI',
  // subProjectsTree: {
  //   stepID: steps[0].id,
  //   substeps: null,
  dateCreation: formatDate(new Date()),
  dateEdition: null,
  //   options: [],
  //   completed: false
  // },
  label,
  dirty: false,
  dateLastUpdate: formatDate(new Date()),
  isTemplate: false,
  isArchived: false,
  error: null,
  pdf: null,
  user: null,
  cartUrl: null,
  isDone: false,
  isEdited: false,
  backendId: null,
  hash: null,
  isInvalid: false
});

export const isFinishedProject = (
  project: ProjectState,
  substeps: Indexed<Substep>
) =>
  isProjectDone(project.id, substeps) &&
  /*project.isDone &&*/
  !project.isArchived &&
  !project.isTemplate;

export const isCurrentProject = (
  project: ProjectState,
  substeps: Indexed<Substep>
) =>
  !isProjectDone(project.id, substeps) &&
  !project.isArchived &&
  !project.isTemplate;

export const projectsReducer = (
  state: ProjectsState = initialState,
  action: AnyMainAction
): ProjectsState => {
  switch (action.type) {
    case getType(fetchProjectsRequest):
      return {
        ...state,
        dirty: true
      };
    case getType(fetchProjectsError):
      return {
        ...state,
        dirty: false,
        error: action.payload.error
      };
    case getType(fetchProjectsSuccess):
    case getType(fetchProjectSuccess):
      const unsavedProjects = Object.values(state.byId).filter(
        proj => !Boolean(proj.backendId)
      );

      const updatedProjects = Object.values(state.byId)
        .filter(proj =>
          action.payload.projectsData.find(
            servProj => proj.backendId === servProj["@id"]
          )
        )
        .map(
          (proj: ProjectState): ProjectState => {
            const servProj = action.payload.projectsData.find(
              servProj => proj.backendId === servProj["@id"]
            )!;
            return {
              ...proj,
              label: servProj.label,
              backendId: servProj["@id"],
              dateCreation: servProj.dateCreation,
              dateEdition: servProj.dateEdition,
              dateLastUpdate: servProj.dateLastUpdate,
              isArchived: servProj.isArchived,
              isTemplate: servProj.isTemplate,
              isDone: servProj.isDone,
              isEdited: servProj.isEdited,
              hash: servProj.hash,
              isInvalid: servProj.isInvalid
            };
          }
        );

      const newFromServerProjects = action.payload.projectsData
        .filter(
          servProj =>
            !updatedProjects.find(proj => proj.backendId === servProj["@id"])
        )
        .map(servProj => ({
          /*id: servProj["@id"].replace(/\//gi, "_"),*/
          id: servProj.frontendId,
          label: servProj.label,
          backendId: servProj["@id"],
          dateCreation: servProj.dateCreation,
          dateEdition: servProj.dateEdition,
          pdf: servProj.pdf,
          user: servProj.user,
          cartUrl: servProj.cartUrl,
          dateLastUpdate: servProj.dateLastUpdate,
          isArchived: servProj.isArchived,
          isTemplate: servProj.isTemplate,
          isDone: servProj.isDone,
          isEdited: servProj.isEdited,
          hash: servProj.hash,
          dirty: false,
          error: null,
          isInvalid: servProj.isInvalid
        }));

      // TODO sync entre id et backendId

      return {
        ...state,
        byId: collectionToIndexed([
          ...unsavedProjects,
          ...updatedProjects,
          ...newFromServerProjects
        ]),
        dirty: false,
        error: null
      };

    case getType(addProjectRequest):
      return {
        ...state,
        byId: addEntry(
          createNewProject(
            action.payload.id,
            action.payload.label,
            action.payload.steps
          ),
          state.byId
        )
      };

    case getType(saveProjectRequest):
      return {
        ...state,
        byId:
          editEntry(
            action.payload.id,
            project => ({
              ...project,
              error: null,
              dirty: true
            }),
            state.byId
          ) || state.byId
      };

    case getType(saveProjectSuccess):
      return {
        ...state,
        byId:
          editEntry(
            action.payload.id,
            project => ({
              ...project,
              error: null,
              dirty: false,
              backendId: action.payload.data["@id"],
              pdf: action.payload.data.pdf,
              isDone: action.payload.data.isDone,
              isArchived: action.payload.data.isArchived,
              isTemplate: action.payload.data.isTemplate,
              isEdited: action.payload.data.isEdited,
              cartUrl: action.payload.data.cartUrl,
              hash: action.payload.data.hash
            }),
            state.byId
          ) || state.byId
      };

    case getType(saveProjectError):
      return {
        ...state,
        byId:
          editEntry(
            action.payload.id,
            project => ({
              ...project,
              dirty: false,
              backendId: action.payload.projectBackendId || project.backendId,
              error: action.payload.error
            }),
            state.byId
          ) || state.byId
      };

    // case getType(finishProjectRequest):
    //   return {
    //     ...state,
    //     byId:
    //       editEntry(
    //         action.payload.id,
    //         project => ({ ...project, dirty: true }),
    //         state.byId
    //       ) || state.byId
    //   };

    // case getType(finishProjectSuccess):
    //   return {
    //     ...state,
    //     byId:
    //       editEntry(
    //         action.payload.id,
    //         project => ({ ...project, dirty: false, isDone: true }),
    //         state.byId
    //       ) || state.byId
    //   };

    case getType(duplicateProjectSuccess):
      return {
        ...state,
        byId: addEntry(
          {
            ...state.byId[action.payload.copiedProjectId],
            id: action.payload.newId
          },
          state.byId
        )
      };

    case getType(setNewProjectData):
      return {
        ...state,
        newProjectData: {
          name: action.payload.projectName,
          selectedOptions: action.payload.options
        }
        // byId:
        //   editEntry(
        //     action.payload.projectName,
        //     entry => ({ ...entry, label: action.payload.projectName }),
        //     state.byId
        //   ) || state.byId
      };

    case getType(duplicateTemplateSuccess):
      const options = groupBy(
        part => part.optionPI.code,
        action.payload.templateParts
      );
      let counter = 0;
      const selectedOptions: AnyStepOptions[] = Object.values(options).map(
        option => {
          return {
            id: option[0].optionPI["@id"],
            codePI: counter++,
            label: option[0].optionPI.label,
            labelShort: option[0].optionPI.labelShort,
            value: option[0].optionPI["@id"],
            type: "PI",
            sortOrder: option[0].optionPI.sortOrder,
            isInterior: option[0].optionPI.isInterior,
            isExterior: option[0].optionPI.isExterior
          };
        }
      );
      return {
        ...state,
        byId: addEntry(
          createNewProject(
            action.payload.projectId,
            action.payload.projectLabel,
            []
          ),
          state.byId
        ),
        newProjectData: {
          name: action.payload.projectLabel,
          selectedOptions: selectedOptions
        }
      };
  }
  return state;
};
