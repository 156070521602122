import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Header } from "../components/header";
import { MainState } from "../model/main-model";

const mapStateToProps = (state: MainState) => ({
  isGuest: state.authentication.guest,
  user: state.authentication.data?.user ?? null
});

const mapDispatchToProps = (dispatch: Dispatch) => ({

});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
