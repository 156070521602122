import cuid from "cuid";
import { getType } from "typesafe-actions";
import {
  addEntry,
  collectionToIndexed,
  // mergeIndexedRight,
  editEntry,
  Indexed
} from "../../utils/collection-utils";
import { formatDate } from "../../utils/date-utils";
import {AnyStepOptions, getNewSubstepConfigurations, StepList} from "../steps/steps-model";
import {
  AnyTemplateAction, duplicateTemplateRequest, duplicateTemplateSuccess,
  fetchTemplatesError,
  fetchTemplatesRequest,
  fetchTemplatesSuccess
} from "./templates-actions";
import { Substep, isProjectDone } from "../substeps/substeps-model";
import { createSelector } from "reselect";
import { MainState } from "../main-model";
import {ProjectsState} from "../projects/projects-model";
import {duplicateProjectSuccess, fetchProjectsSuccess} from "../projects/projects-actions";
import {reconstructTree} from "../../utils/substep-utils";
import {ServerProject} from "../projects/projects-service";

const initialState = {
  byId: {},
  dirty: false,
  error: null
};

export interface TemplateState {
  id: string;
  backendId: string | null;
  dirty: boolean;
  label: string | null;
  comment: string | null;
  dateCreation: string;
  error: {} | null;
}
export interface TemplatesState {
  byId: Indexed<TemplateState>;
  dirty: boolean;
  error: null | string;
}

export const getTemplateList = (state: TemplatesState) =>
  Object.values(state.byId);

export const getTemplateById = (state: TemplatesState, id: string) =>
  state.byId[id];

export const templatesReducer = (
  state: TemplatesState = initialState,
  action: AnyTemplateAction
): TemplatesState => {
  switch (action.type) {
    case getType(fetchTemplatesRequest):
      return {
        ...state,
        dirty: true
      };
    case getType(fetchTemplatesError):
      return {
        ...state,
        dirty: false,
        error: action.payload.error
      };
    case getType(fetchTemplatesSuccess):
      const newFromServerTemplates = action.payload.templatesData
        .map(servTpl => ({
          id: servTpl["@id"].replace(/\//gi, "_"),
          label: servTpl.label,
          comment: servTpl.comment,
          backendId: servTpl["@id"],
          dateCreation: servTpl.dateCreation,
          dirty: false,
          error: null
        }));

      return {
        ...state,
        byId: collectionToIndexed([
          ...newFromServerTemplates
        ]),
        dirty: false,
        error: null
      };
    case getType(duplicateTemplateRequest):
      return {
        ...state,
        dirty: true
      };
    case getType(duplicateTemplateSuccess):
      return {
        ...state,
        dirty: false
      };
  }
  return state;
};
