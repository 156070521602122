import { useEffect, useRef, useState } from "react";

/**
 * https://juliangaramendy.dev/use-promise-subscription/
 * custom hook to manage data you retrieve from promises
 */

export const usePromise = <T>(
  promiseFunction: () => Promise<T>,
  defaultValue: T,
  dependencies: any[]
) => {
  console.log("usePromise");
  const [state, setState] = useState<{
    value: T;
    error: any;
    isPending: boolean;
  }>({
    value: defaultValue,
    error: null,
    isPending: true
  });

  useEffect(() => {
    const promise =
      typeof promiseFunction === "function"
        ? promiseFunction()
        : promiseFunction;

    let isSubscribed = true;
    promise
      .then(value =>
        isSubscribed ? setState({ value, error: null, isPending: false }) : null
      )
      .catch(error =>
        isSubscribed
          ? setState({ value: defaultValue, error: error, isPending: false })
          : null
      );

    return () => {
      isSubscribed = false;
    };
    // suppression du linting car la dependance sur default value n'est pas une bonne idée mais attention aux dépendances !!!!
    // eslint-disable-next-line no-use-before-define
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // eslint-disable-line no-use-before-define

  const { value, error, isPending } = state;

  type ReturnTuple = [typeof value, typeof error, typeof isPending];

  const toReturn: ReturnTuple = [value, error, isPending];

  return toReturn;
};
