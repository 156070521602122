import { ActionType, createAction } from "typesafe-actions";
import { ServerTemplate, ServerTemplatePart } from "./templates-service";

/**************** fetch templates **********************/

export const FETCH_TEMPLATES_REQUEST = "FETCH_TEMPLATES_REQUEST";
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS";
export const FETCH_TEMPLATES_ERROR = "FETCH_TEMPLATES_ERROR";

export const fetchTemplatesRequest = createAction(FETCH_TEMPLATES_REQUEST);
export const fetchTemplatesSuccess = createAction(
  FETCH_TEMPLATES_SUCCESS,
  action => (data: {
    templatesData: ServerTemplate[];
  }) =>
    action(data)
);
export const fetchTemplatesError = createAction(
  FETCH_TEMPLATES_ERROR,
  action => (error: string) =>
    action({
      error
    })
);

/**************** duplicate template **********************/

export const DUPLICATE_TEMPLATE_REQUEST = "DUPLICATE_TEMPLATE_REQUEST";
export const DUPLICATE_TEMPLATE_SUCCESS = "DUPLICATE_TEMPLATE_SUCCESS";
export const DUPLICATE_TEMPLATE_ERROR = "DUPLICATE_TEMPLATE_ERROR";

export const duplicateTemplateRequest = createAction(
  DUPLICATE_TEMPLATE_REQUEST,
  action => (projectId: string, projectLabel: string, templateId?: string ) =>
    action({
      projectId,
      projectLabel,
      templateId
    })
);

export const duplicateTemplateSuccess = createAction(
  DUPLICATE_TEMPLATE_SUCCESS,
  action => (
    templateParts: ServerTemplatePart[],
    projectId: string,
    projectLabel: string
  ) =>
    action({
      templateParts,
      projectId,
      projectLabel
    })
);

export const duplicateTemplateError = createAction(
  DUPLICATE_TEMPLATE_ERROR,
  action => (id: string) =>
    action({
      id
    })
);

const all = {
  fetchTemplatesRequest,
  fetchTemplatesSuccess,
  fetchTemplatesError,
  duplicateTemplateRequest,
  duplicateTemplateSuccess,
  duplicateTemplateError
};

export type AnyTemplateAction = ActionType<typeof all>;
