import React, { Component, FormEvent } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import {
  authRegisterRequest,
  authUnitSystemRequest,
  authCountriesRequest,
  authDepartmentsRequest,
  authProfessionsRequest
} from "../../model/authentication/authentication-actions";
import {
  EntityCountry,
  EntityDepartment,
  EntityProfession,
  EntityUnitSystem
} from "../../model/authentication/authentication-model";
import { MainState } from "../../model/main-model";
import styles from "../../styles/modules/ui-register.module.scss";
import { ApiError } from "../../utils/server-utils";
import { PropsType } from "../../utils/ts-utils";
import { Tra } from "../../components/tra";
import { TranslationContext, useT } from "../i18n-container";
import { I18NState } from "../../model/i18n/i18n-model";
import { isNil } from "ramda";
import queryString from "query-string";

type OwnProps = RouteComponentProps;
export type RegisterState = {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  agreeConditions: boolean;
  language: string;
  errors: any;
  unitSystem: string;
  country: string | null;
  department: string | null;
  profession: string| null;
  phoneNumber: string;
};

type Props = PropsType<
  OwnProps,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>;

class RegisterComponent extends Component<
  Props,
  RegisterState & { shown: boolean; shownConditions: boolean }
> {
  constructor(props: any) {
    super(props);
    this.formRef = React.createRef<HTMLFormElement>();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      agreeConditions: false,
      language: "",
      unitSystem: "",
      country: props.defaultCountry,
      department: null,
      profession: null,
      phoneNumber: "",
      errors: {},
      shown: false,
      shownConditions: false
    };

    this.props.getUnitSystems();
    this.props.getCountries();
    this.props.getDepartments();
    this.props.getProfessions();
  }

  isPending: boolean = false;
  formRef: React.RefObject<HTMLFormElement>;

  setLocalState = (field: string, value: string) => {
    const sessionState = sessionStorage.getItem("register");
    let localState;
    if (isNil(sessionState)) {
      localState = new Array();
    } else {
      localState = JSON.parse(sessionState);
    }
    localState = {
      ...localState
    };
    localState[field] = value;
    sessionStorage.setItem("register", JSON.stringify(localState));
  };

  getLocalState = (field: string): any => {
    const sessionState = sessionStorage.getItem("register");
    let localState;
    if (isNil(sessionState)) {
      return "";
    } else {
      localState = JSON.parse(sessionState);
      return localState[field] ?? "";
    }
  };

  handleForm = (e?: FormEvent) => {
    e?.preventDefault();
    const data: RegisterState = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      passwordConfirm: this.state.passwordConfirm,
      agreeConditions: this.state.agreeConditions,
      language: this.props.currentLanguage ? this.props.currentLanguage : "",
      errors: this.state.errors,
      unitSystem: this.props.unitSys ? this.props.unitSys : "",
      country: this.state.country ?? null,
      department: this.state.department ?? null,
      profession: this.state.profession ?? null,
      phoneNumber: this.state.phoneNumber ?? null
    };

    if (!data.country) {
      data.country = this.props.defaultCountry ?? null;
    }

    const parsedQueryString = queryString.parse(this.props.location.search);
    if (
      parsedQueryString.redirect &&
      typeof parsedQueryString.redirect === "string"
    ) {
      this.props.setRegister(data, parsedQueryString.redirect);
    } else {
      this.props.setRegister(data);
    }
  };

  handleInput = (e: any) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value } as any);
    this.setLocalState(name, value);
  };

  handleCheckbox = (e: any) => {
    const name = e.target.name;
    const value = e.target.checked;

    this.setState({ [name]: value } as any);
    this.setLocalState(name, value);
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.registerPending !== prevProps.registerPending &&
      this.props.registerPending === false &&
      !this.props.registerErrors &&
      this.state.shown === true
    ) {
      this.setState({ shown: false });
    }
  }

  render() {
    const error = this.props.registerErrors;
    let sortedCountries = this.props.countries.sort((a, b) => {
      if (!a.id) return -1;
      if (!b.id) return 1;
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    })
    return (
      <React.Fragment>
        <TranslationContext.Consumer>
          {({ texts }: I18NState) => (
            <div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => this.setState({ shown: true })}
              >
                <Tra code="btn-form-new-account" />
              </button>
              <Modal
                size="lg"
                show={this.state.shown}
                className={`${styles["ui-register-modal"]}`}
                onHide={() => this.setState({ shown: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h2>
                      <Tra code="title-form-register" />
                    </h2>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="ui ui-login">
                    {this.state.shownConditions ? (
                      <div>
                        <Tra code="text-conditions" />
                      </div>
                    ) : (
                      <div className={`${styles["ui-login-wrapper"]}`}>
                        <div className={`${styles["ui-register"]}`}>
                          <form ref={this.formRef}>
                            <div>
                              <label>
                                <Tra code="lbl-form-lastname" />
                              </label>
                              {handleError(error, "lastName")}
                              <input
                                type="text"
                                name="lastName"
                                placeholder={
                                  texts["lbl-form-placeholder-lastname"]
                                }
                                onChange={this.handleInput}
                                value={this.getLocalState("lastName") ?? ""}
                              />
                            </div>

                            <div>
                              <label>
                                <Tra code="lbl-form-firstname" />
                              </label>
                              {handleError(error, "firstName")}
                              <input
                                type="text"
                                name="firstName"
                                placeholder={
                                  texts["lbl-form-placeholder-firstname"]
                                }
                                onChange={this.handleInput}
                                value={this.getLocalState("firstName") ?? ""}
                              />
                            </div>

                            <div>
                              <label>
                                <Tra code="lbl-form-email" />
                              </label>
                              {handleError(error, "email")}
                              <input
                                type="text"
                                name="email"
                                placeholder={
                                  texts["lbl-form-placeholder-email"]
                                }
                                onChange={this.handleInput}
                                value={this.getLocalState("email") ?? ""}
                              />
                            </div>

                            <div>
                              <label>
                                <Tra code="lbl-form-password" />
                              </label>
                              {handleError(error, "password")}
                              <input
                                type="password"
                                name="password"
                                onChange={this.handleInput}
                                value={this.getLocalState("password") ?? ""}
                              />
                            </div>

                            <div>
                              <label>
                                <Tra code="lbl-form-password-confirm" />
                              </label>
                              {handleError(error, "passwordConfirm")}
                              <input
                                type="password"
                                name="passwordConfirm"
                                onChange={this.handleInput}
                                value={
                                  this.getLocalState("passwordConfirm") ?? ""
                                }
                              />
                            </div>

                            <hr style={{ width: '100%', borderTop: '1px solid #313d57', marginTop: '50px' }}/>

                            <div>
                              <label>
                                <Tra code="lbl-form-phoneNumber" />
                              </label>
                              <input
                                type="text"
                                name="phoneNumber"
                                placeholder={
                                  texts["lbl-form-placeholder-phoneNumber"]
                                }
                                onChange={this.handleInput}
                                value={this.getLocalState("phoneNumber") ?? ""}
                              />
                            </div>

                            <div>
                              <label>
                                <Tra code="lbl-form-country" />
                              </label>
                              <select
                                name="country"
                                id="country"
                                onChange={this.handleInput}
                              >
                                {sortedCountries.map((country: EntityCountry, idx: any) => (
                                  <option value={country["@id"]} key={`${country}-${idx}`} selected={country.isDefault}>
                                    {country.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            { ((this.state.country === null) || (this.state.country === this.props.defaultCountry)) ? (<div>
                              <label>
                                <Tra code="lbl-form-department" />
                              </label>
                              <select
                                name="department"
                                id="department"
                                onChange={this.handleInput}
                              >
                                {this.props.departments.map((department: EntityDepartment, idx: any) => (
                                  <option value={department["@id"]} key={`${department}-${idx}`}>
                                    {department.label}
                                  </option>
                                ))}
                              </select>
                            </div>) : ''}

                            <div>
                              <label>
                                <Tra code="lbl-form-profession" />
                              </label>
                              <select
                                name="profession"
                                id="profession"
                                onChange={this.handleInput}
                              >
                                {this.props.professions.map((profession: EntityProfession, idx: any) => (
                                  <option value={profession["@id"]} key={`${profession}-${idx}`}>
                                    {profession.label}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div>
                              <Link
                                to="#"
                                onClick={() =>
                                  this.setState({ shownConditions: true })
                                }
                              >
                                <Tra code="btn-form-conditions" />
                              </Link>
                              {handleError(error, "agreeConditions")}
                              <label
                                className={`${styles["linecheckbox_container"]} linecheckbox_container`}
                                htmlFor="agreeConditions"
                              >
                                <input
                                  type="checkbox"
                                  id="agreeConditions"
                                  name="agreeConditions"
                                  onChange={this.handleCheckbox}
                                />
                                <span className="linecheckbox_container_checkmark">
                                  {" "}
                                </span>
                                <label className="mt-1">
                                  {" "}
                                  <Tra code="lbl-form-conditions" />{" "}
                                </label>
                              </label>
                            </div>

                            {/* <div className={`${styles["return-submit-button"]}`}> </div> */}
                            {/* <input type="submit" value="ENREGISTRER" /> */}
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </Modal.Body>
                {this.state.shownConditions ? (
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.setState({ shownConditions: false })}
                    >
                      <Tra code="btn-return" />
                    </Button>
                  </Modal.Footer>
                ) : (
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.setState({ shown: false })}
                    >
                      <Tra code="btn-form-close" />
                    </Button>
                    <Button variant="primary" onClick={() => this.handleForm()}>
                      <Tra code="btn-form-register" />
                    </Button>
                  </Modal.Footer>
                )}
              </Modal>
            </div>
          )}
        </TranslationContext.Consumer>
      </React.Fragment>
    );
  }
}

const handleError = (error: ApiError | undefined, name: string) => {
  return (
    error?.data?.violations?.map((res: any, idx: any) =>
      res.propertyPath === name ? (
        <Alert
          variant="warning"
          className={styles["ui-error"]}
          key={`${res}-${idx}`}
        >
          <Tra code={res.message} />
        </Alert>
      ) : (
        ""
      )
    ) ?? ""
  );
};

const checkforDefaultUts = (entityUnitSystem: EntityUnitSystem[]) => {
  let uts = entityUnitSystem.find(res => res.isDefault === true);
  return uts && uts["@id"];
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setRegister: (data: RegisterState, redirect: string | null = null) => {
    return dispatch(authRegisterRequest(data, redirect));
  },
  getUnitSystems: () => {
    return dispatch(authUnitSystemRequest());
  },
  getCountries: () => {
    return dispatch(authCountriesRequest())
  },
  getDepartments: () => {
    return dispatch(authDepartmentsRequest())
  },
  getProfessions: () => {
    return dispatch(authProfessionsRequest())
  }
});

const mapStateToProps = (state: MainState) => {
  const defaultCountry = state.authentication.countries.filter(item => item.isDefault);
  return {
    registerErrors: state.authentication.errors?.register,
    currentLanguage: state.authentication.currentLanguage,
    registerPending: state.authentication.registerPending,
    unitSys: checkforDefaultUts(state.authentication.unitSystems),
    countries: [{id: 0, label: ''}, ...state.authentication.countries],
    departments: [{id: 0, label: ''}, ...state.authentication.departments],
    professions: [{id: 0, label: ''}, ...state.authentication.professions],
    defaultCountry: (defaultCountry.length ? defaultCountry[0]['@id'] : null)
}};

export const Register = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterComponent)
);
