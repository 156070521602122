import {
  CO_VIEW,
  ES_VIEW,
  MA_VIEW,
  MS_VIEW,
  DEFAULT_VIEW,
  PI_VIEW,
  RADIO_VERTICAL_VIEW,
  StepViewComponent,
  TP_VIEW
} from "../../model/steps/steps-model";
import { COStepView } from "./co-step-view";
import { ESStepView } from "./es-step-view";
import { MAStepView } from "./ma-step-view";
import { MSStepView } from "./ms-step-view";
import { DefaultStepView } from "./default-step-view";
import { PIStepView } from "./pi-step-view";
import { TPStepView } from "./tp-step-view";
import { RadioVerticalListStepView } from "./radio-vertical-list-step-view";

const views: { [index: string]: StepViewComponent } = {
  [TP_VIEW]: TPStepView,
  [DEFAULT_VIEW]: DefaultStepView,
  [RADIO_VERTICAL_VIEW]: RadioVerticalListStepView,
  [MA_VIEW]: MAStepView,
  [PI_VIEW]: PIStepView,
  [ES_VIEW]: ESStepView,
  [MS_VIEW]: MSStepView,
  [CO_VIEW]: COStepView
};

export default views;
