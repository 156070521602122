import T from "ramda/es/T";

// import * as R from 'ramda';

export type IndexedEntry = { id: string };

export interface Indexed<T extends IndexedEntry> {
  [key: string]: T;
}

export const mapEntries = <T extends IndexedEntry>(
  fn: (entry: T) => T,
  entries: Indexed<T>
): Indexed<T> =>
  Object.keys(entries).reduce(
    (byId, key) => ({
      ...byId,
      [key]: fn(entries[key])
    }),
    {}
  );

export const removeEntries = <T extends IndexedEntry>(
  toRemove: string[],
  entries: Indexed<T>
): Indexed<T> =>
  collectionToIndexed(
    Object.values(entries).filter(entry => !toRemove.includes(entry.id))
  );

export const editEntry = <T extends IndexedEntry>(
  id: string,
  fn: (entry: T) => T,
  entries: Indexed<T>
): Indexed<T> | null =>
  entries[id]
    ? {
        ...entries,
        [id]: fn(entries[id])
      }
    : null;

export const addEntry = <T extends IndexedEntry>(
  entry: T,
  entries: Indexed<T>
): Indexed<T> => ({
  ...entries,
  [entry.id]: entry
});

export const collectionToIndexed = <T extends IndexedEntry>(
  array: T[]
): Indexed<T> =>
  array.reduce((indexed, entry) => ({ ...indexed, [entry.id]: entry }), {});

export const appendEntries = <T extends IndexedEntry>(
  newEntries: T[],
  entries: Indexed<T>
) => ({
  ...collectionToIndexed(newEntries),
  ...entries
});

// export const mergeIndexedRight = <T>(left: Indexed<T>, right: Indexed<T>) => {
//   return R.mergeRight(left, right);
// };
