import React, { ReactType } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Dispatch } from "redux";
import { authLoginError } from "../../../model/authentication/authentication-actions";
import { MainState } from "../../../model/main-model";

const AuthRoute = ({
  component: MyComponent,
  ...rest
}: RouteProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & { component: ReactType }) => {
  return (
    <Route
      {...rest}
      render={props =>
        tokenVerification(
          rest.guest,
          rest.data ? rest.data.token : undefined,
          rest.dispatchError
        ) ? (
          <MyComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/languages",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const tokenVerification = (
  guest: boolean,
  token: string | undefined,
  dispatchError: (error: string) => void
) => {
  if (guest) {
    return true;
  }
  if (token) {
    //   let isGoodToken = true;
    //   jwt.verify(token, publicKey, { algorithms: ["RS256"] }, function(
    //     err: any,
    //     decoded: any
    //   ) {
    //     if (err) {
    //       dispatchError(err);
    //       isGoodToken = false;
    //     }
    //   });
    // console.log(guest, isGoodToken, token);
    // // debugger;

    // FIXME

    return true;
  }
};

const mapStateToProps = (state: MainState) => {
  return {
    data: state.authentication.data,
    guest: state.authentication.guest
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchError: (errorMessage: string) => {
    dispatch(
      authLoginError({
        data: errorMessage
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
