import { filter, switchMap, map, catchError } from "rxjs/operators";
import { Epic, combineEpics } from "redux-observable";
import {
  fetchStepsRequest,
  fetchStepsSuccess,
  fetchStepsError,
  AnyStepsAction
} from "./steps-actions";
import { MainState } from "../main-model";
import { isActionOf, ActionType } from "typesafe-actions";
import { of, from } from "rxjs";
import { fetchSteps } from "./steps-service";

export const fetchStepsEpic: Epic<
  AnyStepsAction,
  ActionType<typeof fetchStepsSuccess | typeof fetchStepsError>,
  MainState
> = (action$ /*, state$ */) =>
  action$.pipe(
    filter(isActionOf(fetchStepsRequest)),
    switchMap(() =>
      from(fetchSteps()).pipe(
        map(fetchStepsSuccess),
        catchError(error => {
          console.log("error", error);
          return of(fetchStepsError(error.toString()));
        })
      )
    )
  );

export const stepsEpic = combineEpics(fetchStepsEpic);
