import cuid from "cuid";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MainState } from "../model/main-model";
import { addProjectRequest } from "../model/projects/projects-actions";
import {
  AnyStepOptions,
  getStepList,
  StepList
} from "../model/steps/steps-model";
import styles from "../styles/modules/new-project-bottom-bar.module.scss";
import { PropsType } from "../utils/ts-utils";
import {Tra} from "../components/tra";

interface OwnProps {}

const mapStateToProps = (state: MainState) => ({
  newProjectData: state.projects.newProjectData,
  steps: getStepList(state.steps)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addProject: (
    projectName: string,
    projectOptions: AnyStepOptions[],
    steps: StepList
  ) => dispatch(addProjectRequest(projectName, projectOptions, cuid(), steps))
});

const Comp: FunctionComponent<PropsType<
  OwnProps,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>> = ({ newProjectData, addProject, steps }) => {
  if (!steps) {
    return null;
  }

  const displayNextBtn =
    Boolean(newProjectData &&
    newProjectData.selectedOptions &&
    newProjectData.selectedOptions.length > 0 &&
    newProjectData.name);

  return (
    <div className={styles.bottombar}>
      <div
        className={`${styles["bottombar_right"]} d-flex justify-content-end`}
      >
        <button
          disabled={!displayNextBtn}
          className="btn btn-primary btn-bar-right"
          onClick={() =>
            addProject(
              newProjectData!.name,
              newProjectData!.selectedOptions!,
              steps
            )
          }
        >
          <Tra code="btn-footer-next" />
        </button>
      </div>
    </div>
  );
};

export const NewProjectBottomBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp);
