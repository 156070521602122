import { Store } from "redux";
import { MainState } from "./model/main-model";

export const saveInSessionStorage = (store: Store<MainState>) => {
  // store.subscribe(() => {});

  store.subscribe(() => {
    const stateLocale = store.getState().authentication.currentLocale;
    if (stateLocale) {
      sessionStorage.setItem("locale", stateLocale);
    } else {
      sessionStorage.removeItem("locale");
    }
  });

  store.subscribe(() => {
    sessionStorage.setItem("state", JSON.stringify(store.getState()));
  });
};
