
import React from "react";
import { ReactComponent as IcoLeftArrow } from "../assets/left-arrow.svg";
import styles from '../styles/modules/back-btn.module.scss';
import {Tra} from "./tra";

export const BackBtn = () => (
  <button
    type="button"
    className={`${styles.back_link} btn btn-link`}
    onClick={() => window.history.back()}
  >
    <IcoLeftArrow></IcoLeftArrow>
    <Tra code="btn-return"/>
  </button>
);
