import { createAction, ActionType } from "typesafe-actions";
import { StepList } from "./steps-model";

/**************** fetch project **********************/

export const FETCH_STEPS_REQUEST = "FETCH_STEPS_REQUEST";
export const FETCH_STEPS_SUCCESS = "FETCH_STEPS_SUCCESS";
export const FETCH_STEPS_ERROR = "FETCH_STEPS_ERROR";

export const fetchStepsRequest = createAction(FETCH_STEPS_REQUEST);
export const fetchStepsSuccess = createAction(
  FETCH_STEPS_SUCCESS,
  action => (steps: StepList) => action(steps)
);

export const fetchStepsError = createAction(
  FETCH_STEPS_ERROR,
  action => (error: string) =>
    action({
      error
    })
);

const all = {
  fetchStepsRequest,
  fetchStepsSuccess,
  fetchStepsError
};

export type AnyStepsAction = ActionType<typeof all>;
