import React from "react";
import * as dayjsModule from "dayjs";
import "dayjs/locale/fr";
import { HeaderContainer } from "../containers/header-container";
import { AutoSizer, List } from "react-virtualized";
import {AdminProjectData, AdminUserData} from "../model/administration/administration-service";
import styles from "../styles/modules/admin-page.module.scss";
import {ReactComponent as IcoProjectArchived} from "../assets/archive-icon.svg";
import {ReactComponent as IcoProjectFinished} from "../assets/finished-icon.svg";
import {ReactComponent as IconProjectCurrent} from "../assets/edit-notofficial.svg";
import {ReactComponent as IcoRightArrow} from "../assets/arrow-point-to-right.svg";
import {USER_ROLES} from "../model/authentication/authentication-model";
import {useSelector} from "react-redux";
import {MainState} from "../model/main-model";

// @ts-ignore
const dayjs = dayjsModule.default;
dayjs.locale("fr");

export const AdminPage = (
  {
    projectsData,
    usersData,
    onProjectSearch,
    onUserSearch,
    onProjectEntryClick,
    onUserEntryClick,
    totalProjects,
    totalUsers
  }: {
    projectsData: AdminProjectData[],
    usersData: AdminUserData[],
    onProjectSearch: (text: string) => void,
    onUserSearch: (text: string) => void,
    onProjectEntryClick: (id: number) => void,
    onUserEntryClick: (id: number, role: string[]) => void
    totalProjects: number,
    totalUsers: number
  }) => {

  const user = useSelector(
    (state: MainState) => state.authentication.data?.user
  );
  const userIsSuperAdmin = user?.roles.includes(USER_ROLES.ROLE_SUPERADMIN);

  const rowProjectRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style // Style object to be applied to row (to position it)
  }: {
    key: string;
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    style: {};
  }) => {
    let icon;

    if (projectsData[index].isArchived) {
      icon = <IcoProjectArchived/>;
    } else if (projectsData[index].isEdited) {
      icon = <IcoProjectFinished className={styles["logo-inversed"]}/>;
    } else {
      icon = <IconProjectCurrent/>;
    }
    return (
      <li className="list-group-item" key={key}>
        <div key={key} className={styles["project-list-entry"]}>
          <div className="d-flex">
            <div className={styles["project-svg"]}>
              {icon}
            </div>
            <div>
              <strong>
                {projectsData[index].label}
              </strong>
              {" "}
              <em>({projectsData[index].hash})</em>
              {" "}
              <em>[{projectsData[index].firstName} {projectsData[index].lastName}]</em>
              <div>
                <small>
                  { projectsData[index].isEdited ? (
                    <span>Edité le</span>
                  ) : (
                    <span>Créé le</span>
                  )}
                  {" "}
                  {projectsData[index].isEdited && projectsData[index].dateEdition ?
                    dayjs(projectsData[index].dateEdition).format("dddd D MMMM YYYY") :
                    dayjs(projectsData[index].dateCreation).format("dddd D MMMM YYYY")
                  }
                </small>
              </div>
            </div>
          </div>
          <div className="mt-auto mb-auto">
            <span>
              <button
                type="button"
                title="Voir"
                className="btn btn-link"
                onClick={() => onProjectEntryClick(projectsData[index].id)}
              >
                <IcoRightArrow></IcoRightArrow>
              </button>
            </span>
          </div>
        </div>
      </li>
    );
  };

  const rowUserRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style // Style object to be applied to row (to position it)
  }: {
    key: string;
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    style: {};
  }) => {
    const user = usersData[index];
    const isAdmin = user.roles.includes(USER_ROLES.ROLE_ADMIN);
    const isSuperAdmin = user.roles.includes(USER_ROLES.ROLE_SUPERADMIN);
    const btnMakeAdmin = (
      <button className="btn btn-primary" onClick={() => onUserEntryClick(user.id, [USER_ROLES.ROLE_ADMIN])}>
        + Administrateur
      </button>
    );
    const btnMakeSuperAdmin = (
      <button className="btn btn-primary" onClick={() => onUserEntryClick(user.id, [USER_ROLES.ROLE_SUPERADMIN])}>
        + Super Administrateur
      </button>
    );
    const btnRemoveRole = (
      <button className="btn btn-primary" onClick={() => onUserEntryClick(user.id, [])}>
        Retirer le droit
      </button>
    );
    let btnUser;
    if (userIsSuperAdmin) {
      if (isAdmin) {
        btnUser = btnRemoveRole;
      } else if (isSuperAdmin) {
        btnUser = btnRemoveRole;
      } else {
        btnUser = (<React.Fragment>{ btnMakeAdmin }{" "}{ btnMakeSuperAdmin }</React.Fragment>);
      }
    } else {
      if (isAdmin) {
        btnUser = (
          <button className="btn btn-primary disabled" disabled={true}>
            Admin
          </button>
        )
      } else if (isSuperAdmin) {
        btnUser = (
          <button className="btn btn-primary disabled" disabled={true}>
            Super admin
          </button>
        )
      } else {
        btnUser = null;
      }
    };
    return (
      <li className="list-group-item" key={key}>
        <div key={key} className={styles["project-list-entry"]}>
          <div>
            <div>
              Prénom : <strong>{user.firstName}</strong>
            </div>
            <div>
              Nom : <strong>{user.firstName}</strong>
            </div>
            <div>
              Email : <strong>{user.email}</strong>
            </div>
            <div>
              Langue : <strong>{user.language.label}</strong>
            </div>
            <div>
              Système d'unité : <strong>{user.unitSystem.label}</strong>
            </div>
          </div>
          <div className="mt-0 text-right">
              <div>
                { isAdmin ? (
                  <strong><em>* Administrateur *</em></strong>
                ) : null }
                { isSuperAdmin ? (
                  <strong><em>*** Super Administrateur ***</em></strong>
                ) : null }
              </div>
              <div className="mt-5">
                { btnUser }
              </div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className={styles["admin-page-container"]}>
      <HeaderContainer />
      <div className={styles["admin-page-main"]}>
        <div className="row">
          <div className="col-6">
            <div>
              <form>
                <div className="form-inline mb-5">
                  <label htmlFor="search-admin-projects">Recherche projet :&nbsp;</label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="search-admin-projects"
                      className="form-control form-control-sm"
                      onChange={(event) => onProjectSearch(event.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => onProjectSearch('')}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  {" "}
                  <div className="ml-5">{projectsData.length} projets affichés sur {totalProjects}</div>
                </div>
              </form>
            </div>
            <div className={styles["admin-page-results"]}>
              <AutoSizer>
                {({ width, height }) => (
                  <ul className={`${styles.projects_list} list-group mb-5`}>
                    <List
                      width={width}
                      height={height}
                      rowCount={projectsData.length}
                      rowHeight={80}
                      rowRenderer={rowProjectRenderer}
                    />
                  </ul>
                )}
              </AutoSizer>
            </div>
          </div>
          <div className="col-6">
            <div>
              <form>
                <div className="form-inline mb-5">
                  <label htmlFor="search-admin-users">Recherche client :&nbsp;</label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="search-admin-users"
                      className="form-control"
                      onChange={(event) => onUserSearch(event.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onUserSearch('')}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <div className="ml-5">{usersData.length} utilisateurs affichés sur {totalUsers}</div>
                </div>
              </form>
            </div>
            <div className={styles["admin-page-results"]}>
              <AutoSizer>
                {({ width, height }) => (
                  <ul className={`${styles.projects_list} list-group mb-5`}>
                    <List
                      width={width}
                      height={height}
                      rowCount={usersData.length}
                      rowHeight={150}
                      rowRenderer={rowUserRenderer}
                      isScrolling={false}
                    />
                  </ul>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
