import React, { FunctionComponent } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import { PropsType } from "../utils/ts-utils";
import { SaveProject } from "./save-project";
import { BottomBar } from "../components/bottom-bar";
import { hasValidToken } from "../model/authentication/authentication-model";
import {Tra} from "../components/tra";
import {Link} from "react-router-dom";
import { useT } from "./i18n-container";
import {getRootPI, getRootSubstep} from "../model/substeps/substeps-model";
import styles from "../styles/modules/project-items.module.scss";

type OwnProps = RouteComponentProps<{ id: string; projectId: string }> & {};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  // guestUser: state.authentication.guest
  return (state: MainState, props: OwnProps) => ({
    substeps: state.substeps.byId,
    project: selector(state, props.match.params.projectId),
    hasValidToken: hasValidToken(state.authentication)
  });
};

type ComponentProps = OwnProps &
  PropsType<
    OwnProps,
    ReturnType<typeof mapStateToProps>,
    typeof mapDispatchToProps
  >;

const Component: FunctionComponent<PropsType<
  OwnProps,
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps
  >> = ({ project, substeps }) => {
  const { t } = useT();

  return (
    <BottomBar>
      <div/>
      <div
        className={`${styles["bottombar_right"]} d-flex justify-content-end`}
      >
        <SaveProject
          loginModalText={t("text-login-to-edit")}
          shouldEdit={true}
          shouldRedirect={true}
          buttonComponent={({ onClick }:{
            onClick: () => void
          }) => (
            <Button variant="primary" className=" btn-bar" onClick={onClick}>
              <Tra code="btn-project-edit" />
            </Button>
          )}
        />
      </div>
    </BottomBar>
  );
};

export const RecapBottomBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
