import React, { useState } from "react";
import styles from "../styles/modules/co-step-view.module.scss";
import { ReactComponent as IcoSearch } from "../assets/search-ico-notofficial.svg";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { COOption } from "../model/steps/steps-model";

export const Color = ({
  option,
  label,
  onChange,
  checked,
  comment
}: {
  checked: boolean;
  option: COOption;
  label: string;
  picture?: string | undefined;
  id: string;
  onChange: (optionId: string, checked: boolean) => void;
  comment: string | undefined;
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleValidate = () => {
    setShow(false);
    onChange(option.id, !checked);
  };

  return (
    <div
      style={{
        gridColumn: `${option.chartColumn}`,
        gridRow: `${option.chartRow}`
      }}
    >
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip id={`tooltip-bottom`}>{label}</Tooltip>}
      >
        <div
          className={styles["ui-color-box"]}
          data-checked={checked ? "true" : "false"}
          onClick={handleShow}
        >
          <IcoSearch className={styles["search-ico"]}></IcoSearch>
          <img src={`${option.picture}`} alt={label} />

          {/* <input
                            type="checkbox"
                            name="colorBoxChoice"
                            checked={checked}
                            value={id}
                            id={id}
                            onChange={event =>
                              onChange(event.target.value, event.target.checked)
                            }
                          />
                          <label htmlFor={id}>{id}</label> */}
        </div>
      </OverlayTrigger>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Button className={styles["close-btn"]} onClick={handleClose}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img className={`${styles['modal-color-picture']} mb-3`} src={`${option.picture}`} alt="" />
          <div className={styles["modal-color_label"]}>{label}</div>
          <div>{comment}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="m-auto" onClick={handleValidate}>
            {checked ? "Déselectionner" : "Choisir cette couleur"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
