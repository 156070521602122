import React, { Component, FormEvent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MainState } from "../../model/main-model";
import { HeaderContainer } from "../header-container";
import {authPasswordResetRequest} from "../../model/authentication/authentication-actions";
import {RouteComponentProps} from "react-router";
import {PropsType} from "../../utils/ts-utils";
import styles from "../../styles/modules/reset-password.module.scss";
import {TranslationContext, useT} from "../i18n-container";
import {Tra} from "../../components/tra";
import {I18NState} from "../../model/i18n/i18n-model";
import {ApiError} from "../../utils/server-utils";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";

type OwnProps = RouteComponentProps;
export type ResetPasswordState = {
  token: string;
  newPassword: string;
  passwordConfirm: string;
  errors: any;
};

type Props = PropsType<
  OwnProps,
  typeof mapStateToProps,
  typeof mapDispatchToProps
  >;

class ResetPassword extends Component<
  Props,
  ResetPasswordState
  > {
  constructor(props: any) {
    super(props);

    let regex = /[?&]([^=#]+)=([^&#]*)/g, token = "", match;
    while ((match = regex.exec(this.props.location.search))) {
      token = match[2];
    }

    this.state = {
      token: token,
      newPassword: "",
      passwordConfirm: "",
      errors: { errors: "" }
    };
  }

  handleForm = (e: FormEvent) => {
    e.preventDefault();
    /* eslint-disable */
    const data = {
      token: this.state.token,
      newPassword: this.state.newPassword,
      passwordConfirm: this.state.passwordConfirm,
      errors: {}
    };

    this.props.resetPassword(data);
  };

  handleInput = (e: any) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value } as any);
  };

  render() {
    const error = this.props.resetPasswordErrors;
    return (
      <React.Fragment>
        <TranslationContext.Consumer>
          {({ texts }: I18NState) => (
            <div className={styles['ui-reset-password']}>
              <HeaderContainer />
              <div className={styles['ui-reset-password-wrapper']}>
                <div className="main-body">
                  <div className="ui-register">
                    <h2>{texts['title-form-reset-password']}</h2>
                    <form onSubmit={this.handleForm}>
                      <h3>{texts['text-form-reset-password']}</h3>
                      {error && error.data && error.data['hydra:description'] ? (
                        <Alert variant="warning" className={styles["ui-error"]}>
                          <Tra code={error.data['hydra:description']} />{" "}
                        </Alert>
                      ) : (
                        ""
                      )}
                      <label className={styles.blue_label}><Tra code="lbl-form-password" /></label>
                      {handleError(error, "newPassword")}
                      <input
                        className="mb-2"
                        type="password"
                        name="newPassword"
                        placeholder={texts['lbl-form-placeholder-password']}
                        onChange={this.handleInput}
                      />
                      <br/>
                      <label className={styles.blue_label}><Tra code="lbl-form-password-confirm" /></label>
                      {handleError(error, "passwordConfirm")}
                      <input
                        className="mb-2"
                        type="password"
                        name="passwordConfirm"
                        placeholder={texts['lbl-form-placeholder-password']}
                        onChange={this.handleInput}
                      />
                      <div className={styles["button_container"]}>
                        <Link to="/" className="btn btn-primary btn-link">
                          Retour
                        </Link>{" "}
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value={texts['btn-form-password']}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </TranslationContext.Consumer>
      </React.Fragment>
    );
  }
}

const handleError = (error: ApiError | undefined, name: string) => {
  return (
      error?.data?.violations?.map((res: any, idx: any) =>
          res.propertyPath === name ? (
              <Alert
                  variant="warning"
                  className={styles["ui-error"]}
                  key={`${res}-${idx}`}
              >
                <Tra code={res.message} />
              </Alert>
          ) : (
              ""
          )
      ) ?? ""
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPassword: (data: ResetPasswordState) => dispatch(authPasswordResetRequest(data))
});

const mapStateToProps = (state: MainState) => ({
  resetPasswordErrors: state.authentication.errors?.reset
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
