import { createAction, ActionType } from "typesafe-actions";

export const NAVIGATE_TO_CURRENT_SUBSTEP = "NAVIGATE_TO_CURRENT_SUBSTEP";
export const NAVIGATE_TO = "NAVIGATE_TO";

export const navigateToCurrentSubstep = createAction(NAVIGATE_TO_CURRENT_SUBSTEP, action => (projectId: string, substepId: string) => action({ projectId, substepId }));

export const navigateTo = createAction(NAVIGATE_TO, action => (path: string) => action({
  path
}))

export const all = {
  navigateToCurrentSubstep,
  navigateTo
};

export type AnyNavigateAction = ActionType<typeof all>;
