import React, { FunctionComponent, useCallback, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import icoArrow from "../assets/simplelist_arrow@2x.png";
import icoCurrent from "../assets/simplelist_current@2x.png";
import icoFinish from "../assets/simplelist_finish@2x.png";
import icoTemplate from "../assets/simplelist_template@2x.png";
import { SubstepList } from "../containers/substeps-list";
import { ProjectState } from "../model/projects/projects-model";
import styles from "../styles/modules/sidebar-list-entry.module.scss";
import { SidebarSubstepEntry } from "./sidebar-substep-entry";
import { getProjectLink } from "../utils/router-utils";
import { ProjectTree } from "./project-tree";
import {Dispatch} from "redux";
import {
  duplicateProjectRequest, fetchProjectRequest
} from "../model/projects/projects-actions";
import {PropsTypeNoState} from "../utils/ts-utils";
import {connect} from "react-redux";
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import {Tra} from "./tra";
import {useT} from "../containers/i18n-container";
import {MenuPopupContext} from "../containers/menu-popup-context";

type OwnProps = RouteComponentProps<{ id?: string; projectId?: string }> & {
  project: ProjectState;
  active: boolean;
  shouldDuplicate?: boolean;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  duplicate: (projectId: string, projectLabel: string) =>
    dispatch(duplicateProjectRequest(projectId, projectLabel)),
  loadProject: (projectId: string) =>
    dispatch(fetchProjectRequest(projectId))
});

const Comp: FunctionComponent<OwnProps &
  PropsTypeNoState<OwnProps, typeof mapDispatchToProps>> = ({
  project,
  active,
  shouldDuplicate,
  duplicate,
  loadProject
}) => {
  const [show, setShow] = useState(false);
  const [projectLabel, setProjectLabel] = useState("");
  const [saving, setSaving] = useState(false);

  let ico: string;
  if (project.isDone) {
    ico = icoFinish;
  } else if (project.isTemplate) {
    ico = icoTemplate;
  } else {
    ico = icoCurrent;
  }

  const projectId = project?.id;

  const shortenName = (name: string | undefined) => {
    if (name) {
      return name.length > 22 ? name.substr(0, 20) + "..." : name;
    }
    return "";
  };

  const openProject = useCallback(() => {
    if (project.backendId) {
      loadProject(project.backendId.split('/')[2]);
    }
  }, [loadProject, project.backendId]);

  const saveClick = useCallback(() => {
    setSaving(true);
    duplicate(projectId, projectLabel);
    setShow(false);
  }, [duplicate, projectId, projectLabel]);

  const { t } = useT();

  return (
    <MenuPopupContext.Consumer>
      {({showMenuPopup, toggleMenuPopup}) => (
        <React.Fragment>
          <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Body>
              {!project.error ? (
                <React.Fragment>
                  {t("lbl-project-duplicate")} {project.label} ?
                  <div className={styles["project-save"]}>
                    <form>
                      <label>{t("lbl-project-duplication-label")}</label>
                      <input
                        type="text"
                        name="label"
                        placeholder={t("lbl-form-placeholder-duplication-label")}
                        onChange={e => setProjectLabel(e.target.value)}
                        value={projectLabel}
                      />
                    </form>
                  </div>
                </React.Fragment>
              ) : (
                <Alert variant="danger">
                  <Tra code="lbl-error" />
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={saveClick}>
                {project.dirty ? (
                  <span>
                    <Spinner
                      size="sm"
                      className="mr-1"
                      role="status"
                      animation="border"
                    ></Spinner>
                    <Tra code="lbl-saving" />
                  </span>
                ) : (
                  t("lbl-duplicate")
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setShow(false);
                }}
              >
                <Tra code="btn-cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
          <div className={active ? styles.sidebarlistentry_bordered : ""}>
            {shouldDuplicate ? (
              <div
                className={styles.sidebarlistentry_link}
                onClick={() => {
                  setShow(true);
                }}
              >
                <img src={ico} alt="" />
                {shortenName(project.label ?? "")}
                {active ? (
                  <img
                    src={icoArrow}
                    alt=""
                    className={styles.sidebarlistentry_arrow_active}
                  />
                ) : (
                  <img
                    src={icoArrow}
                    alt=""
                    className={styles.sidebarlistentry_arrow}
                  />
                )}
              </div>
            ) : (
              <Link
                className={styles.sidebarlistentry_link}
                to={project.backendId ? "#" : getProjectLink(project)}
                onClick={() => {openProject(); toggleMenuPopup(false)}}
              >
                <img src={ico} alt="" />
                {shortenName(project.label ?? "")}
                {active ? (
                  <img
                    src={icoArrow}
                    alt=""
                    className={styles.sidebarlistentry_arrow_active}
                  />
                ) : (
                  <img
                    src={icoArrow}
                    alt=""
                    className={styles.sidebarlistentry_arrow}
                  />
                )}
              </Link>
            )}
            {active ? <ProjectTree projectId={project.id} /> : null}
            {active ? (
              <SubstepList
                SubstepEntryComponent={SidebarSubstepEntry}
                project={project}
              />
            ) : null}
          </div>
        </React.Fragment>
      )}
    </MenuPopupContext.Consumer>
  );
};

export const SidebarListEntry = withRouter(
  connect(null, mapDispatchToProps)(Comp)
);
