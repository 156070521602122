import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import { ItemsListBottomBar } from "../components/items-list-bottom-bar";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

type OwnProps = RouteComponentProps<{
  projectId?: string;
}>;

export const mapStateToProps = () => {
  const selector = makeUniqueProjectSelector();
  return (state: MainState, props: OwnProps) => {
    const project = props.match.params.projectId
        ? selector(state, props.match.params.projectId) : null;
    const pdfDownloadLink = project ? project.pdf : null;
    const hash = project ? project.hash : null;

    return {
      hash,
      pdfDownloadLink,
      cartUrl: project ? project.cartUrl : null
    };
  };
};

export const ItemsListBottomBarContainer = connect(mapStateToProps)(
  ItemsListBottomBar
);
