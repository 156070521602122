import { combineEpics, Epic } from "redux-observable";
import { of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";
import { AnyMainAction } from "../main-actions";
import { MainState } from "../main-model";
import { navigateToCurrentSubstep, navigateTo } from "../nav/nav-actions";
import { validateSubstep } from "./substeps-actions";
import {
  getRemainingOpenSubstepsForProject,
  getSubstep
} from "./substeps-model";
import { getRecapLink } from "../../utils/router-utils";
import { getProjectById } from "../projects/projects-model";

export const validateSubstepEpic: Epic<
  AnyMainAction,
  ActionType<typeof navigateToCurrentSubstep | typeof navigateTo>,
  MainState
> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(validateSubstep)),
    switchMap(action => {
      const substeps = state$.value.substeps.byId;
      const substep = getSubstep(action.payload.substepId, substeps);

      const hasRemaining =
        getRemainingOpenSubstepsForProject(
          substep.projectId,
          state$.value.substeps.byId
        ).filter(sub => sub.id !== substep.id).length > 0;

      return of(
        hasRemaining
          ? navigateToCurrentSubstep(substep.projectId, substep.id)
          : navigateTo(
              getRecapLink(
                getProjectById(state$.value.projects, substep.projectId)
              )
            )
      );
    })
  );

export const substepsEpic = combineEpics(validateSubstepEpic);
