import {
  EditDeleteAccountState,
  EditProfileInfoState,
  EditProfilePasswordState,
  EditProfilePreferencesState
} from "../../containers/authentication/edit-profile";
import { EmailCheckState } from "../../containers/authentication/email-check";
import { LoginState } from "../../containers/authentication/login";
import { RegisterState } from "../../containers/authentication/register";
import { apiGet, apiPost, apiPut } from "../../utils/server-utils";
import {
  HydraCollectionResponse,
  UserData,
  EntityLanguage, EntityUnitSystem, EntityCountry, EntityDepartment, EntityProfession
} from "./authentication-model";
import { HydraCollection, HydraMember } from "../../utils/hydra-utils";
import {ResetPasswordState} from "../../containers/authentication/reset-password";

export type ServerLanguages = HydraCollection<HydraMember & EntityLanguage>;
export const authLanguagesFromApi = () => {
  return apiGet<ServerLanguages>("/languages")
    .then(response => response.data)
    .then(data => ({
      ...data,
      ["hydra:member"]: data["hydra:member"].sort((a, b) =>
        a.isDefault ? -1 : b.isDefault ? 1 : 0
      )
    }));
};

export type ServerUnitSystems = HydraCollection<HydraMember & EntityUnitSystem>;
export const authUnitSystemFromApi = () => {
  return apiGet<HydraCollectionResponse>("/unit_systems").then(
    response => response.data
  );
};
export const authDefaultUnitSystemFromApi = () => {
  return apiGet<HydraCollectionResponse>("/unit_systems?isDefault=true").then(
    response => response.data
  );
};

export type ServerCountries = HydraCollection<HydraMember & EntityCountry>;
export const authCountriesFromApi = () => {
  return apiGet<HydraCollectionResponse>("/countries").then(
    response => response.data
  );
};

export type ServerDepartments = HydraCollection<HydraMember & EntityDepartment>;
export const authDepartmentsFromApi = () => {
  return apiGet<HydraCollectionResponse>("/departments?order[label]=asc").then(
    response => response.data
  );
};

export type ServerProfessions = HydraCollection<HydraMember & EntityProfession>;
export const authProfessionsFromApi = () => {
  return apiGet<HydraCollectionResponse>("/professions").then(
    response => response.data
  );
};

export const authPicturesFromApi = () => {
  return apiGet<HydraCollectionResponse>("/pictures").then(
    response => response.data
  );
};

export const authLoginFromApi = (loginState: LoginState) => {
  return apiPost<LoginState, UserData>("/login_check", loginState, false).then(
    response => {
      sessionStorage.setItem("token", response.data.token ?? "");
      return response.data;
    }
  );
};

export const authRegisterFromApi = (registerState: RegisterState) => {
  return apiPost<LoginState, unknown>("/register", registerState).then(
    response => response.data
  );
};

export const authRegisterAndLoginFromApi = (registerState: RegisterState) => {
  return apiPost<LoginState, unknown>("/register", registerState)
      .then(response => response.data)
      .then(data => {
        return apiPost<LoginState, UserData>("/login_check", {email: registerState.email, password: registerState.password}, false)
            .then(
                response => {
                  sessionStorage.setItem("token", response.data.token ?? "");
                  return response.data;
                }
            )
      })
}

export const authEmailCheckFromApi = (emailCheckState: EmailCheckState) => {
  return apiPost<EmailCheckState, unknown>(
    "/forgot-password",
      emailCheckState
  ).then(response => response.data);
};

export const authPrefEditFromApi = (
  userId: string,
  editProfilePreferencesState: EditProfilePreferencesState
) => {
  return apiPut<EditProfilePreferencesState, unknown>(
    `${userId}/update-preferences`,
    editProfilePreferencesState
  ).then(response => response.data);
};

export const authPasswordEditFromApi = (
  userId: string,
  editProfilePasswordState: EditProfilePasswordState
) => {
  return apiPut<EditProfilePasswordState, unknown>(
    `${userId}/reset-password`,
    editProfilePasswordState
  ).then(response => response.data);
};

export const authPasswordResetFromApi = (
  ResetPasswordState: ResetPasswordState
) => {
  return apiPut<ResetPasswordState, unknown>(
    `/reset-forgotten-password`,
    ResetPasswordState
  ).then(response => response.data);
};

export const authEditInfosFromApi = (
  userId: string,
  editProfileInfoState: EditProfileInfoState
) => {
  return apiPut<EditProfileInfoState, unknown>(
    `${userId}/update-infos`,
    editProfileInfoState
  ).then(response => response.data);
};

export const authDeleteAccountFromApi = (
  userId: string,
  deleteAccountState: EditDeleteAccountState
) => {
  return apiPut<EditDeleteAccountState, unknown>(
    `${userId}/delete-account`,
    deleteAccountState
  ).then(response => {
    return response.data;
  });
};

export const authUserEditRoleFromApi = (
  userId: number,
  roles: string[]
) => {
  return apiPut(
    `/users/${userId}`,
    {
      roles: roles
    }
  ).then(response => {
    return response.data;
  });
}
