import adjust from "ramda/src/adjust";
import remove from "ramda/src/remove";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AnyStepOptions, getComment,
  getQuestion,
  optionIsPIServerOption,
  PIOption,
  PIServerOption,
  StepViewComponent
} from "../../model/steps/steps-model";
import { fetchOptionsForStep } from "../../model/steps/steps-service";
import { getSubstep } from "../../model/substeps/substeps-model";
import styles from "../../styles/modules/pi-step-view.module.scss";
import {
  collectionToIndexed,
  editEntry,
  Indexed
} from "../../utils/collection-utils";
import { QuestionTooltip } from "../question-tooltip";
import { usePromise } from "../../utils/react-utils";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";
import {Tra} from "../tra";

export type PIStepFormStateEntry = {
  roomNames: { name: string; id: string }[];
  option: PIServerOption;
  label: string;
  labelShort?: string;
  picture?: string;
  id: string;
  comment?: string;
};
export type PIStepFormState = Indexed<PIStepFormStateEntry>;

const PIEntry = ({
                   onOptionsChange,
                   formState,
                   setFormState,
                   check
                 }: {
  check: PIStepFormStateEntry;
  formState: PIStepFormState;
  onOptionsChange: (options: AnyStepOptions[]) => void;
  setFormState: React.Dispatch<React.SetStateAction<PIStepFormState>>;
}) => {
  const [modalShow, setModalShow] = useState(false);

  const sendOptions = (state: PIStepFormState) => {
    onOptionsChange(
      Object.values(state).reduce<PIOption[]>(
        (acc, val) => [
          ...acc,
          ...val.roomNames.map(({ name }, index) => ({
            type: "PI",
            id: val.id,
            codePI: index,
            isInterior: val.option.isInterior,
            isExterior: val.option.isExterior,
            label: name,
            labelShort: val.option.labelShort,
            sortOrder: val.option.sortOrder,
            value: val.id
          }))
        ],
        []
      )
    );
  };

  const addPI = (label: string, piType: string) => {
    const newState =
      editEntry(
        piType,
        entry => ({
          ...entry,
          roomNames: [
            ...entry.roomNames,
            {
              name: `${label}-${entry.roomNames.length + 1}`,
              id: String(entry.roomNames.length)
            }
          ]
        }),
        formState
      ) || formState;

    setFormState(newState);
    sendOptions(newState);
  };

  const removePI = (piType: string, index: number) => {
    const newState =
      editEntry(
        piType,
        entry => ({
          ...entry,
          roomNames: remove(index, 1, entry.roomNames)
        }),
        formState
      ) || formState;

    setFormState(newState);
    sendOptions(newState);
  };

  const updateRoomName = (PIId: string, index: number, name: string) => {
    const newState =
      editEntry(
        PIId,
        entry => ({
          ...entry,
          roomNames: adjust(
            index,
            roomName => ({ ...roomName, name }),
            entry.roomNames
          )
        }),
        formState
      ) || formState;

    setFormState(newState);
    sendOptions(newState);
  };

  const shortenName = (name: string) => {
    return name.length > 22 ? name.substr(0, 20) + '...' : name;
  }

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={`${styles["modal_title"]}`}
          >
            <div>+</div>
            <h3>{check.labelShort ?? check.label}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          {check.roomNames.length > 0 ? (
            <div>
              {/* <div className={styles.stepview_label}>{check.label}</div> */}
              <Tra code="txt-room-selection" />

              {check.roomNames.map(({ name, id }, i) => (
                <div
                  key={id}
                  className="input-group"
                  onClick={() => setModalShow(true)}
                >
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={evt =>
                      updateRoomName(check.id, i, evt.target.value)
                    }
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      onClick={() => removePI(check.id, i)}
                    >
                      -
                    </button>
                  </div>
                </div>
              ))}
              <div className="d-flex mt-3 justify-content-center">
                <button
                  className="btn btn-secondary"
                  onClick={() => addPI(check.labelShort ?? check.label, check.id)}
                >
                  +
                </button>
              </div>
            </div>
          ) : (
            <Button
              onClick={() => addPI(check.labelShort ?? check.label, check.id)}
              className={`${styles["modal_button"]}`}
            >
              <Tra code="btn-add-room" />
            </Button>
          )}
        </Modal.Body>
        <Modal.Footer>
          {check.roomNames.length > 0 ? (
            <Button
              onClick={() => {
                console.log("click");
                setModalShow(false);
              }}
            >
              <Tra code="btn-add-room-ok" />
            </Button>
          ) : null }
        </Modal.Footer>
      </Modal>
      <div
        onClick={() => {
          setModalShow(true);
        }}
      >
        <div className={styles.stepview_container} key={check.id}>
          <div className={`${styles["stepview_block"]} ${check.roomNames.length ? styles["stepview_block_active"] : ''}  project-common_block"`}>
            <div
              className={styles.stepview_bg}
              style={{
                backgroundImage: check.picture
                  ? `url(${check.picture})`
                  : "none"
              }}
            ></div>
            <div className="d-flex mt-3 justify-content-center">
              <div className={styles.stepview_label}>{check.label}</div>
            </div>
          </div>
          <QuestionTooltip
            tooltipText={check.comment ?? null}
            questionClassName={`${styles.stepview_ico} ico-question ico-xs`}
          />
        </div>
        <div className="d-flex flex-column p-3 align-items-center">
          {check.roomNames.map(room => (
            <div key={room.id}>{shortenName(room.name)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const PIStepView: StepViewComponent = ({
                                                substepId,
                                                substeps,
                                                steps,
                                                selectedOptions,
                                                onOptionsChange
                                              }) => {
  const [formState, setFormState] = useState<PIStepFormState>({});
  const [init, setInit] = useState(false)

  const [options, error, pending] = usePromise(
    () => fetchOptionsForStep(substepId, substeps, steps),
    null,
    []
  );


  useEffect(() => {
    if (!options || init) {
      return;
    }
    setFormState(
      collectionToIndexed(
        options.map(option => {
          if (!optionIsPIServerOption(option)) {
            throw new Error("typing problem in fetch request");
          }
          const item = {
            label: option.label,
            labelShort: option.labelShort,
            id: option.id,
            picture: option.picture,
            comment: option.comment,
            option,
            roomNames: selectedOptions
              ? selectedOptions
                .filter(opt => opt.value === option.value)
                .map((opt, index) => ({
                  name: opt.label,
                  id: index.toString()
                }))
              : []
          }
          setInit(true)
          return item;
        })
      )
    );
  }, [options, selectedOptions, init]);

  return (
    <div className="position-relative">
      <NoOptionAlert
        show={!pending && (Object.values(formState).length === 0 || error)}
      />
      <MaskingSpinner show={pending} />
      <h3 className="project_new mx-auto mb-4">
        { substepId ? (
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
            <QuestionTooltip
              tooltipText={getComment(getSubstep(substepId, substeps).stepLevel, steps)}
              questionClassName={`ico-question d-inline-block ml-3`}
            />
          </React.Fragment>
        ) : null }
      </h3>
      <div className={styles.stepview}>
        {Object.values(formState).map(check => (
          <PIEntry
            key={check.id}
            check={check}
            formState={formState}
            onOptionsChange={onOptionsChange}
            setFormState={setFormState}
          />
        ))}
      </div>
    </div>
  );
};
