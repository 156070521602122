import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const QuestionTooltip = ({
  tooltipText,
  questionClassName,
  placement
}: {
  tooltipText: string | null;
  questionClassName?: string;
  placement?: 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
}) => {
    const plainTooltipText = tooltipText?.replace(/<br\s*\/?>/mg,"\n");
    const tooltip = (
    <OverlayTrigger
        placement={placement ?? "left"}
        overlay={(props: any) => (
            <Tooltip {...props} show={props.show.toString()}>
                {plainTooltipText}
            </Tooltip>
        )}
    >
        <span className={questionClassName || "ico-question"}>?</span>
    </OverlayTrigger>);
    return (tooltipText ?? '') === '' ? null : tooltip;
};
