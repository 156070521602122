// @ts-ignore
import * as dayjsModule from "dayjs";
import "dayjs/locale/fr";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReactComponent as IcoDuplicate } from "../assets/duplicate-icon.svg";
import { ReactComponent as IcoTemplate } from "../assets/project-template-icon.svg";
import { useT } from "../containers/i18n-container";
import { CreateFromTemplate } from "../containers/create-from-template";
import { TemplateState } from "../model/templates/templates-model";
import styles from "../styles/modules/project-list-entry.module.scss";
import { Tra } from "./tra";
import {QuestionTooltip} from "./question-tooltip";

// @ts-ignore
const dayjs = dayjsModule.default;
dayjs.locale("fr");

interface OwnProps {
  template: TemplateState;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // onDuplicate: (projectID: string) =>
  //   dispatch(duplicateProjectRequest(projectID))
});

export const Component: FunctionComponent<OwnProps &
  ReturnType<typeof mapDispatchToProps>> = ({
  template,
  // onDuplicate,
}) => {
  const { t } = useT();
  return (
    <div className={styles["project-list-entry"]}>
      <div className="d-flex">
        <div className={styles["template-svg"]}>
          <IcoTemplate />
        </div>
        <div>
          <strong>
            {template.label}
          </strong>
          <div>
            <small>
              <Tra code="lbl-project-created-on" />{" "}
              {dayjs(template.dateCreation).format("dddd D MMMM YYYY")}
            </small>
          </div>
        </div>
        <div className={styles["project-tooltip"]}>
          <QuestionTooltip
            tooltipText={template.comment}
            questionClassName={`${styles["newproject_icoquestion"]} ico-question`}
            placement="auto"
          />
        </div>
      </div>
      <div className="mt-auto mb-auto">
        <span className={styles["blue_label"]}>{t('lbl-project-state-template')}</span>
        <CreateFromTemplate
          templateId={template.id}
          loginModalText={t("text-login-to-duplicate")}
          saveModalText={t("lbl-project-duplicate")}
          saveButtonLabel={t("lbl-duplicate")}
          shouldDuplicate={true}
          buttonComponent={({ onClick }:{
            onClick: () => void
          }) => (
            <button
              type="button"
              title={t("lbl-duplicate")}
              className="btn btn-link"
              onClick={onClick}
            >
              <IcoDuplicate></IcoDuplicate>
            </button>
          )}
        />
      </div>
    </div>
  );
};

export const TemplateListEntry = connect(null, mapDispatchToProps)(Component);
