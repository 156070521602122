import React, {FormEvent} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { authLogoutRequest } from "../../model/authentication/authentication-actions";
import { MainState } from "../../model/main-model";
import icoExit from "../../assets/nav-ico_exit@2x.png";

type OwnProps = {};

type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const logout = ({
    isLogged,
    authLogout
  }: PropsType) => {

  const handleLogout = (e: FormEvent) => {
    authLogout();
  };

  return ( isLogged ? (
    <Link
      to="#"
      onClick={handleLogout}
    >
      <img
        src={icoExit}
        alt=""
        className="main-navbar_links_exit"
      />
    </Link>
  ) : null );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authLogout: () => {
    return dispatch(authLogoutRequest());
  }
});

const mapStateToProps = (state: MainState) => ({
  isLogged: state.authentication.data !== null
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)
(logout);
