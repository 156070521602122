import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { MainState, makeUniqueTemplateSelector } from "../model/main-model";
import { duplicateTemplateRequest } from "../model/templates/templates-actions";
import { PropsType } from "../utils/ts-utils";
import { Tra } from "../components/tra";
import { useT } from "./i18n-container";
import styles from "../styles/modules/project-list-entry.module.scss";
import cuid from "cuid";

type OwnProps = RouteComponentProps<{ id?: string; projectId?: string }> & {
  buttonComponent: (props: { onClick: () => void }) => JSX.Element;
  shouldDuplicate?: boolean;
  templateId?: string;
  loginModalText?: string;
  saveModalText?: string;
  saveButtonLabel?: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  duplicate: (projectLabel: string, templateId?: string) =>
    dispatch(duplicateTemplateRequest(cuid(), projectLabel, templateId))
});

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueTemplateSelector();
  // guestUser: state.authentication.guest
  return (state: MainState, props: OwnProps) => {
    const id = props.templateId;
    return {
      template: id ? selector(state, id) : null
    };
  };
};

const Comp: FunctionComponent<OwnProps &
  PropsType<
    OwnProps,
    ReturnType<typeof mapStateToProps>,
    typeof mapDispatchToProps
  >> = ({
  match,
  history,
  template,
  duplicate,
  buttonComponent,
  loginModalText,
  saveModalText,
  shouldDuplicate,
  saveButtonLabel
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [projectLabel, setProjectLabel] = useState("");

  const ButtonComponent = buttonComponent;

  const templateDirty = template?.dirty;
  const templateId = template?.id;
  const templateBackendId = template?.backendId;

  useEffect(() => {
    if (templateDirty === false && saving === true) {
      setSaving(false);
      setShow(false);
    }
  }, [templateDirty, saving]);

  const { t, ht } = useT();

  const saveClick = useCallback(() => {
    if (!templateId) {
      return null;
    }

    setSaving(true);
    duplicate(projectLabel, templateBackendId ?? '');
  }, [templateId, duplicate, projectLabel, templateBackendId]);

  if (!template) {
    return null;
  }

  return (
    <span>
      <ButtonComponent
        onClick={() => {
          setShow(true);
        }}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          {!template.error ? (
            <React.Fragment>
              {saveModalText ?? ht("lbl-project-save")} {template.label} ?
              <div className={styles["project-save"]}>
                <form>
                  <label>{t("lbl-project-duplication-label")}</label>
                  <input
                    type="text"
                    name="label"
                    placeholder={t("lbl-form-placeholder-duplication-label")}
                    onChange={e => setProjectLabel(e.target.value)}
                    value={projectLabel}
                  />
                </form>
              </div>
            </React.Fragment>
          ) : (
            <Alert variant="danger">
              <Tra code="lbl-error" />
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveClick}>
            {template.dirty ? (
              <span>
                <Spinner
                  size="sm"
                  className="mr-1"
                  role="status"
                  animation="border"
                ></Spinner>
                <Tra code="lbl-saving" />
              </span>
            ) : (
              saveButtonLabel ??
              (shouldDuplicate ? ht("lbl-edit-pdf") : ht("lbl-save"))
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            <Tra code="btn-cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export const CreateFromTemplate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comp)
);
