import React, { FunctionComponent, useState } from "react";
import { BottomBar } from "./bottom-bar";
import { Tra } from "./tra";
import { Link } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import { useT } from "../containers/i18n-container";
import styles from "../styles/modules/project-items.module.scss";

export type OwnProps = {
  hash: string | null;
  pdfDownloadLink: string | null;
  cartUrl: string | null;
  shown: boolean;
};

export const ItemsListBottomBar: FunctionComponent<OwnProps> = ({
  hash,
  pdfDownloadLink,
  cartUrl
}) => {
  const { t, ht } = useT();
  const [shown, setShown] = useState(false);

  return (
    <BottomBar>
      <div
        className={`${styles["bottombar_right"]} justify-content-end`}
      >
        <a
          href={pdfDownloadLink || "#"}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-secondary mr-3 btn-bar"
        >
          {t("btn-project-print")}
        </a>
        <Link to="/new-project" className="btn btn-primary btn-link mr-2 btn-bar">
          {t("btn-new-project")}
        </Link>
        <a href="#" className="btn btn-primary btn-link btn-bar" onClick={() => {
          navigator.clipboard.writeText(hash ?? '');
          setShown(true);
        }}>
          {t("btn-project-buy")}
        </a>
        <Modal show={shown} onHide={() => setShown(false)}>
          <Modal.Body>{ht("text-cart")}</Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-outline-secondary" onClick={() => setShown(false)}>
              {t("btn-cancel")}
            </Button>
            <a
              href={cartUrl || "#"}
              className="btn btn-primary btn-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("btn-cart-continue")}
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    </BottomBar>
  );
};
