import { HydraCollection, HydraMember } from "../../utils/hydra-utils";
import {
  apiGet
} from "../../utils/server-utils";
import {getTemplateById, TemplateState} from "./templates-model";
import {ProjectState} from "../projects/projects-model";
import {MainState} from "../main-model";

export type ServerStepOption = HydraMember & {
  code: string;
  sortOrder: number;
  label: string;
  labelShort?: string;
};

export type PIServerStepOption = ServerStepOption & {
  isInterior: boolean | null;
  isExterior: boolean | null;
};

export type ServerTemplate = HydraMember & {
  label: string;
  dateCreation: string;
  comment: string | null;
  projectTemplateParts: string[];
};

export type ServerTemplatePart = HydraMember & {
  template: string;
  codePI: number;
  labelPI: "Chambre-0";
  optionPI: PIServerStepOption;
  optionTP: ServerStepOption;
  optionNS: ServerStepOption;
  optionES: ServerStepOption;
  optionTE: ServerStepOption;
  optionMA: ServerStepOption;
  optionPR: ServerStepOption;
  optionPO: ServerStepOption;
}

export const fetchTemplatesFromApi = () => {
  return apiGet<HydraCollection<ServerTemplate>>("/project_templates?order[sortOrder]=asc")
    .then(response => response.data["hydra:member"])
    .then(templatesData => {
      return {
        templatesData
      };
    });
};

export const fetchTemplateFromApi = (templateIri?: string) => {
  return apiGet<HydraCollection<ServerTemplatePart>>(`/project_template_parts?projectTemplate=${templateIri}`)
    .then(response => response.data["hydra:member"])
    .then (templatePartData => templatePartData);
}

export const createProjectFromTemplate = (
  templateId: string,
  projectLabel: string,
  state: MainState
) => {
  const template = getTemplateById(state.templates, templateId);
}
