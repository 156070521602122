import React, { FunctionComponent, useCallback, useEffect } from "react";
import {connect, useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { MaskingSpinner } from "../components/masking-spinner";
import { SidebarListEntry } from "../components/sidebar-list-entry";
import { SimpleList } from "../components/simple-list";
import { mainProjectListSelector, MainState } from "../model/main-model";
import { fetchProjectsRequest } from "../model/projects/projects-actions";
import {
  isCurrentProject,
  isFinishedProject,
  ProjectState
} from "../model/projects/projects-model";
import { indexedSubstepsSelector } from "../model/substeps/substeps-model";
import spinnerStyles from "../styles/modules/masking-spinner.module.scss";
import styles from "../styles/modules/sidebar.module.scss";
import { PropsType } from "../utils/ts-utils";
import {hasValidToken, USER_ROLES} from "../model/authentication/authentication-model";
import {useT} from "./i18n-container";

type OwnProps = {} & RouteComponentProps<{
  projectId: string;
  substepId: string;
}>;

const mapStateToProps = (state: MainState) => ({
  loading: state.projects.dirty,
  substeps: indexedSubstepsSelector(state),
  hasValidToken: hasValidToken(state.authentication),
  projectsList: mainProjectListSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProjects: () => dispatch(fetchProjectsRequest())
});

// const ProjectsList = ({
//   project,
//   isActiveProject
// }: {
//   project: ProjectState;
//   isActiveProject: boolean;
// }) =>
//   isActiveProject ? (
//     <Route
//       path={["/projects/:id/substeps/:substep", "/projects/:id", "/"]}
//       render={({ match }) => (
//         <SubstepList
//           SubstepEntryComponent={SidebarSubstepEntry}
//           selectedSubstepId={match.params.substep}
//           project={project}
//         />
//       )}
//     />
//   ) : (
//     <Link
//       to={{
//         pathname: `/projects/${project.id}`
//       }}
//     >
//       {project.label}
//     </Link>
//   );

const SimpleComponent: FunctionComponent<PropsType<
  OwnProps,
  typeof mapStateToProps,
  typeof mapDispatchToProps
>> = props => {
  const {
    projectsList,
    match,
    fetchProjects,
    hasValidToken,
    loading,
    substeps
  } = props;

  const user = useSelector(
    (state: MainState) => state.authentication.data?.user
  );

  const isAdmin = user?.roles.includes(USER_ROLES.ROLE_ADMIN) || user?.roles.includes(USER_ROLES.ROLE_SUPERADMIN);

  useEffect(() => {
    if (hasValidToken) {
      isAdmin ? fetchProjects() : fetchProjects();
    }
  }, [fetchProjects, hasValidToken, isAdmin]);

  const { t } = useT();

  const renderListEntry = useCallback(
    (project: ProjectState) => (
      <SidebarListEntry
        project={project}
        active={match.params.projectId === project.id}
      />
    ),
    [match.params.projectId]
  );
  const renderDuplicableListEntry = useCallback(
    (project: ProjectState) => (
      <SidebarListEntry
        project={project}
        active={match.params.projectId === project.id}
        shouldDuplicate={true}
      />
    ),
    [match.params.projectId]
  );
  return (
    <div
      className={`${styles.sidebarmenu} ${spinnerStyles.masking_spinner_container}`}
    >
      <MaskingSpinner show={loading} />
      <section>
        <h1>{t('title-project-pending')}</h1>
        <div className={styles.sidebarmenu_separator}></div>
        <SimpleList
          className={styles.sidebar_list}
          list={projectsList.filter(proj => isCurrentProject(proj, substeps))}
          render={renderListEntry}
        />
      </section>

      <section>
        <h1>{t('title-project-template')}</h1>
        <div className={styles.sidebarmenu_separator}></div>
        <SimpleList
          className={styles.sidebar_list}
          list={projectsList.filter(project => project.isTemplate)}
          render={renderDuplicableListEntry}
        />
      </section>

      <section>
        <h1>{t('title-project-done')}</h1>
        <div className={styles.sidebarmenu_separator}></div>
        <SimpleList
          className={styles.sidebar_list}
          list={projectsList.filter(project =>
            isFinishedProject(project, substeps) && (!isAdmin || project.id === match.params.projectId)
          )}
          render={renderListEntry}
        />
      </section>
    </div>
    // <ul>
    //   {projectsList.map(project => (
    //     <li key={project.id}>
    //       {match.params.projectId === project.id ? (
    //         <Route
    //           path={["/projects/:id/substeps/:substep", "/projects/:id", "/"]}
    //           render={({ match }) => (
    //             <SubstepState
    //               SubstepEntryComponent={SidebarSubstepEntry}
    //               selectedSubstepId={match.params.substep}
    //               project={project}
    //             />
    //           )}
    //         />
    //       ) : (
    //         <Link
    //           to={{
    //             pathname: `/projects/${project.id}`
    //           }}
    //         >
    //           {project.label}
    //         </Link>
    //       )}

    //       {/* {`${project.id} - ${project.label}`} */}
    //     </li>
    //   ))}
    // </ul>
  );
};

export const Sidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleComponent);
