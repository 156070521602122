import React from "react";
import styles from "../styles/modules/project-bottom-bar.module.scss";
import { useT } from "../containers/i18n-container";
import { isNil } from "ramda";

// export type OwnProps = { name: string } & RouteComponentProps<{
//   projectId?: string;
//   substepId?: string;
// }>;

export type OwnProps = {
  projectName: string | null;
  piName: string | null;
  tpName: string | null;
  urlPicture: string | null;
};

export const BottomStepName = ({
  projectName,
  piName,
  tpName,
  urlPicture
}: OwnProps) => {
  const { pic } = useT();
  return (
    <div
      className={`${styles["main-footer_left"]} main-footer_left`}
      style={{
        backgroundImage:
          urlPicture === null
            ? `url(${pic("img-footer-project")})`
            : `url(${urlPicture})`
      }}
    >
      <div className="main-footer_left_overlay">
        <p
          className={`${isNil(piName) && isNil(tpName) ? "label-0" : "label-1"}`}
        >
          {projectName !== null ? projectName : piName}
        </p>
        {!isNil(piName) || !isNil(tpName) ? (
          <p className={"label-2"}>
            {piName !== null && projectName !== null ? piName : tpName}
          </p>
        ) : null}
      </div>
    </div>
  );
};
