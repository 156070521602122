import { Alert } from "react-bootstrap";
import React from "react";
import {Tra} from "./tra";

export const OnlyOneOptionAlert = ({
  formStateValues
}: {
  formStateValues: Array<unknown>;
}) => {
  const hasOnlyOneOption = formStateValues && formStateValues.length === 1;

  return hasOnlyOneOption ? (
    <Alert variant="info">
      <Tra code="alert-only-one-option" />
    </Alert>
  ) : null;
};
