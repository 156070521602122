import { ActionType, createAction } from "typesafe-actions";
import { AnyStepOptions, StepList } from "../steps/steps-model";

/* delete subpart */

export const DELETE_SUBPART = "DELETE_SUBPART";
export const DELETE_SUBPART_SUCCESS = "DELETE_SUBPART_SUCCESS";
export const DELETE_SUBPART_ERROR = "DELETE_SUBPART_ERROR";

export const deleteSubpart = createAction(
  DELETE_SUBPART,
  action => (id: string) =>
    action({
      id
    })
);

/* set options */

export const SET_SUBSTEP_OPTIONS = "SET_SUBSTEP_OPTIONS";

export const setSubstepOptions = createAction(
  SET_SUBSTEP_OPTIONS,
  action => (substepId: string, options: AnyStepOptions[]) =>
    action({ options, substepId })
);

/* validate */

export const VALIDATE_SUBSTEP = "VALIDATE_SUBSTEP";

export const validateSubstep = createAction(
  VALIDATE_SUBSTEP,
  action => (substepId: string, steps: StepList) => action({ substepId, steps })
);

export const all = {
  setSubstepOptions,
  validateSubstep,
  deleteSubpart
};

export type AnySubstepAction = ActionType<typeof all>;
