import React, { FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import leftLogo from "../assets/login-left_logo@2x.png";
import { MainState } from "../model/main-model";
import styles from "../styles/modules/language-choice.module.scss";
import {getSubstepLink, useRouter} from "../utils/router-utils";
import { useT } from "./i18n-container";
import { HeaderContainer } from "./header-container";
import { Tra } from "../components/tra";
import {Link} from "react-router-dom";

type OwnProps = {};

const mapStateToProps = (state: MainState) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Component = () => {
  const { t, ht, pic } = useT();
  const router = useRouter();

  return (
    <div className={`${styles["container"]} container-fluid`}>
      <HeaderContainer />
      <div className={`${styles["container_row"]} row`}>
        <div
          style={{ backgroundImage: `url(${pic("img-home")})` }}
          className={`${styles["left"]} col-sm-12 col-md-6`}
        >
          <img className={styles.left_logo} src={pic("img-home-logo")} alt="" />
          <div className={styles.left_pic02}>
            <ul>
              <li>
                <span></span> {t("txt-home-list-1")}
              </li>
              <li>
                <span></span> {t("txt-home-list-2")}
              </li>
              <li>
                <span></span> {t("txt-home-list-3")}
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles["right"]} col-sm-12 col-md-6`}>
          <div className={styles.right_title}>
            <h1>{t("title-404")}</h1>
            <p>{ht("text-404")}</p>
            <Link
              to="/main"
            >
              <button className="btn btn-primary">
                <Tra code="btn-404" />
              </button>
            </Link>
          </div>
        </div>
        <div className="ui-languages-footer"></div>
      </div>
    </div>
  );
};

export const Page404 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
