import React, { useEffect, useState } from "react";
import {
  getQuestion,
  getComment,
  MAServerOption,
  StepViewComponent
} from "../../model/steps/steps-model";
import { getSubstep } from "../../model/substeps/substeps-model";
import { getBrandsFromApi } from "../../model/substeps/substeps-service";
import style from "../../styles/modules/ma-step.module.scss";
import styles from "../../styles/modules/pi-step-view.module.scss";
import { usePromise } from "../../utils/react-utils";
import { useStepViewState } from "../../utils/step-views-utils";
import { OnlyOneOptionAlert } from "../only-one-option-alert";
import { QuestionTooltip } from "../question-tooltip";
import { Alert, Spinner } from "react-bootstrap";
import { NoOptionAlert } from "../no-option-alert";
import { MaskingSpinner } from "../masking-spinner";
import { relative } from "path";
import {Tra} from "../tra";

export const MAStepView: StepViewComponent = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange,
  onSkip
}) => {
  // const [possibleOptions, setPossibleOptions] = useState({});
  const [filtersState, updateFiltersState] = useState<{
    [index: string]: { label: string; checked: boolean; picture: string; comment: string; };
  }>({});
  const onFilterChange = (event: any) => {
    updateFiltersState({
      ...filtersState,
      [event.target.value]: {
        ...filtersState[event.target.value],
        checked: !filtersState[event.target.value].checked
      }
    });
  };

  const [formState, onChange, error, pending] = useStepViewState<
    MAServerOption
  >(substepId, substeps, steps, selectedOptions, onOptionsChange, onSkip);

  const [brands] = usePromise(() => getBrandsFromApi(), [], []);

  useEffect(() => {
    updateFiltersState(
      brands.reduce(
        (acc, brand) => ({
          ...acc,
          [brand["@id"]]: {
            checked: true,
            label: brand.label,
            picture: brand.picture,
            comment: brand.comment
          }
        }),
        {}
      )
    );
  }, [brands]);

  if (substepId === null) {
    return null;
  }

  const formStateValues = Object.values(formState);

  const itemsBrand = Object.entries(filtersState).filter(([key, val]) =>
    Object.values(formState).find(
      entry => entry.option.product.brand["@id"] === key
    )
  );

  return (
    <div className="d-flex flex-column position-relative">
      <NoOptionAlert
        show={!pending && (formStateValues.length === 0 || error)}
      />
      <MaskingSpinner show={pending} />
      <h3 className="project_new mx-auto mb-4">
        <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
        <QuestionTooltip
          tooltipText={getComment(getSubstep(substepId, substeps).stepLevel, steps)}
          questionClassName={`ico-question d-inline-block ml-3`}
        />
      </h3>
      <OnlyOneOptionAlert formStateValues={formStateValues} />
      {/* <div className={style["ui-tp-grid"]}>
        <div className={style["ui-ns"]}> */}
      <div className={style.content_container}>
        <div className={`${styles["stepview"]} ${style["ui-ns"]}`}>
          {Object.values(formState)
            .filter(formStateVal =>
              filtersState[formStateVal.option.product!.brand["@id"]]
                ? filtersState[formStateVal.option.product!.brand["@id"]]
                    .checked
                : false
            )
            .map(check => (
              <div key={check.id} className={styles.stepview_container}>
                <div
                  className={`${styles["stepview_block"]} ${check.checked ? styles["stepview_block_active"] : ''} ${style["stepview_block-matiere"]} control custom-checkbox"`}
                >
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    name="piece"
                    onChange={event =>
                      onChange(event.target.value, event.target.checked)
                    }
                    checked={check.checked}
                    value={check.id}
                    id={check.id}
                  />
                  <label className={styles.stepview_label} htmlFor={check.id}>
                    <span
                      className={styles.stepview_bg}
                      style={{
                        backgroundImage: check.picture
                          ? `url(${check.picture})`
                          : "none"
                      }}
                    ></span>
                    {check.label}
                  </label>
                </div>
                <QuestionTooltip
                  tooltipText={check.comment ?? null}
                  questionClassName={`${styles.stepview_ico} ico-question ico-xs`}
                  placement="auto"
                />
              </div>
            ))}
        </div>

        {itemsBrand.length > 1 ? (
          <div className={`${style["ui-tp-filtre"]}`}>
            <span className={`text-center`}><Tra code="title-filter-brand" /></span>
            <div className={`${style["ma-step--card-body"]} card-body`}>
              {itemsBrand.map(([key, val]) => (
                <div
                  key={key}
                  className={`${style["brand-filter"]} custom-checkbox`}
                >
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id={key}
                    name="filtreBrands"
                    checked={val.checked}
                    value={key}
                    onChange={e => onFilterChange(e)}
                  ></input>
                  <label
                    className={`${style["custom-control-label--matiere"]} custom-control-label`}
                    htmlFor={key}
                  >
                    <div
                      className={`${styles.stepview_bg} ${style["stepview_bg--ma-step-view"]}`}
                      style={{
                        backgroundImage: `url(${val.picture})`
                      }}
                    ></div>
                    <QuestionTooltip
                      tooltipText={val.comment ?? null}
                      questionClassName={`ico-question ico-xs`}
                      placement="left"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
