import React, { FunctionComponent, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { MainState } from "../model/main-model";
import { deleteSubpart } from "../model/substeps/substeps-actions";
import { PropsType } from "../utils/ts-utils";
import {Tra} from "../components/tra";

type OwnProps = RouteComponentProps<{ id?: string; projectId?: string }> & {
  buttonComponent: (props: { onClick: () => void }) => JSX.Element;
  substepId: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchDeleteSubpart: (substepId: string) => {
    console.log("ooooo");
    dispatch(deleteSubpart(substepId));
  }
});

const mapStateToProps = (state: MainState) => ({});

const Comp: FunctionComponent<OwnProps &
  PropsType<OwnProps, typeof mapStateToProps, typeof mapDispatchToProps>> = ({
  match,
  history,
  dispatchDeleteSubpart,
  buttonComponent,
  substepId
}) => {
  const [show, setShow] = useState(false);
  const ButtonComponent = buttonComponent;

  return (
    <span>
      <ButtonComponent
        onClick={() => {
          setShow(true);
        }}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <Tra code="text-subproject-delete" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              dispatchDeleteSubpart(substepId);
            }}
          >
            <Tra code="btn-delete" />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            <Tra code="btn-cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export const DeleteSubpart = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comp)
);
