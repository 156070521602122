import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const GestResetRoute = ({ component: Component, ...rest }: any) => {
    console.log(rest);
  return (
    <Route
      {...rest}
      render={props =>
        isVerifiedEmail(rest) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const isVerifiedEmail = (rest: any) => {
    return true;
  // if (rest.data.verified) {
  //   if (!rest.data.token) {
  //     return true;
  //   }
  // } else {
  //   return false;
  // }
};

const mapStateToProps = (state: { authentication: { data: {} } }) => {
  return {
    data: state.authentication.data
  };
};

export default connect(mapStateToProps)(GestResetRoute);
