import { groupBy, propEq, find } from "ramda";
import {
  ServerProjectPart,
  ServerStepOption,
  ServerProject
} from "../model/projects/projects-service";
import {
  AnyStepOptions,
  createBlankStepOption,
  getStepLevelForType,
  isMeasuringStepOption,
  isPIOption,
  MeasuringstepOptions,
  PIOption,
  StepOption,
  stepTypesMap,
  COOption
} from "../model/steps/steps-model";
import { Substep, SubstepCreationData } from "../model/substeps/substeps-model";

export const convertServerOptionToStepOption = (
  subpart: ServerProjectPart,
  code: string
): StepOption | null => {
  // @ts-ignore
  const serverOption = subpart[`option${code}`] as ServerStepOption;
  if (!serverOption) {
    const nextCode = stepTypesMap[getStepLevelForType(code) + 1];
    if (nextCode && getSubstepOption(subpart, nextCode)) {
      return createBlankStepOption(code);
    } else {
      return null;
    }
  }
  return {
    id: serverOption["@id"],
    label: serverOption.label || serverOption.code,
    labelShort: serverOption.labelShort,
    value: serverOption["@id"],
    sortOrder: serverOption.sortOrder,
    type: code
  };
};

export const convertServerOptionToPIOption = (
  subpart: ServerProjectPart
): PIOption => {
  return {
    id: subpart.optionPI["@id"],
    label: subpart.labelPI,
    value: subpart.optionPI["@id"],
    sortOrder: subpart.optionPI.sortOrder,
    isInterior: subpart.optionPI.isInterior,
    isExterior: subpart.optionPI.isExterior,
    type: "PI",
    codePI: subpart.codePI
  };
};

export const convertServerOptionToCOOption = (
  subpart: ServerProjectPart
): COOption | null => {
  if (!subpart.product) return null;
  return {
    id: subpart.product ? subpart.product['@id'] : null,
    type: "CO",
    value: subpart.product ? subpart.product['@id'] : null,
    label: subpart.product ? subpart.product.label : null,
    groupLabel: null,
    sortOrder: null,
    chartSize: null,
    comment: null,
    chartRow: null,
    chartColumn: null,
    picture: null,
    archived: null
  };
};

export const convertServerOptionToMSOption = (
  subpart: ServerProjectPart
): MeasuringstepOptions | null => {
  if (Array.isArray(subpart.paramArea) && subpart.paramArea.length === 0) {
    return null;
  }

  return {
    id: "MS",
    type: "MS",
    label: "mesure",
    value: String(subpart.paramArea.total),
    data: subpart.paramArea,
    sortOrder: null
  };
};

export const getSubstepOption = (
  subpart: ServerProjectPart,
  code: string
): AnyStepOptions | null => {
  switch (code) {
    case "PI":
      return convertServerOptionToPIOption(subpart);

    case "TP":
      return convertServerOptionToStepOption(subpart, "TP");

    case "NS":
      return convertServerOptionToStepOption(subpart, "NS");

    case "ES":
      return convertServerOptionToStepOption(subpart, "ES");

    case "TE":
      return convertServerOptionToStepOption(subpart, "TE");

    case "MA":
      return convertServerOptionToStepOption(subpart, "MA");

    case "PR":
      return convertServerOptionToStepOption(subpart, "PR");

    case "PO":
      return convertServerOptionToStepOption(subpart, "PO");

    case "MO":
      return convertServerOptionToStepOption(subpart, "MO"); // todo

    case "CO":
      return convertServerOptionToCOOption(subpart); // todo

    case "MS":
      return convertServerOptionToMSOption(subpart);
  }

  throw new Error(`wrong code ${code}`);
};

type TreeNode = {
  subparts: ServerProjectPart[];
  code: string | null;
  id: string;
  option: AnyStepOptions | null;
  children: TreeNode[] | null;
};

export const replaceSlash = (str: string) => str.replace(/\//gi, "_");

export const recursiveCreateTree = (
  subparts: ServerProjectPart[],
  code: string,
  idToConcat: string
): TreeNode[] => {
  const grouped = groupBy(subpart => {
    const currentOption = getSubstepOption(subpart, code);

    if (!currentOption) {
      return "null";
    }

    const optionId = replaceSlash(currentOption.id);

    if (isPIOption(currentOption)) {
      return `${idToConcat}-${optionId}-${currentOption.codePI}`;
    }

    if (isMeasuringStepOption(currentOption)) {
      return `${idToConcat}-measure`;
    }

    return `${idToConcat}-${optionId}`;
  }, subparts);

  const nextCode = stepTypesMap[stepTypesMap.indexOf(code) + 1];

  return Object.keys(grouped)
    .filter(key => key !== "null")
    .map((id, index) => {
      const nodeOption = getSubstepOption(grouped[id][0], code);

      return {
        code,
        id,
        option: getSubstepOption(grouped[id][0], code),
        subparts,
        children:
          nextCode && nodeOption
            ? recursiveCreateTree(
                grouped[id],
                nextCode,
                idToConcat + "-" + String(index)
              )
            : null
      };
    });
};

export const reconstructTree = (
  subparts: ServerProjectPart[],
  serverProjects: ServerProject[],
  oldSubsteps: Substep[]
): Substep[] => {
  const byProjectId = groupBy(subpart => {
    // @ts-ignore
    return find(propEq('@id', subpart.project))(serverProjects)?.frontendId;
  }, subparts);

  const trees = Object.values(byProjectId).map<TreeNode>((parts, index) => ({
    code: null,
    id: `${index}`,
    subparts: parts,
    option: createBlankStepOption("root"),
    children: recursiveCreateTree(parts, "PI", String(index))
  }));

  return trees
    .map(tree => convertTreeNodeToSubsteps(tree, null, serverProjects, oldSubsteps))
    .flat();
};

export const addProjectToTree = (
    subparts: ServerProjectPart[],
    serverProjects: ServerProject[],
    oldSubsteps: Substep[],
    projectId: string
): Substep[] => {
  const byProjectId = groupBy(subpart => {
    // @ts-ignore
    return find(propEq('@id', subpart.project))(serverProjects)?.frontendId;
  }, subparts);

  const trees = Object.values(byProjectId).map<TreeNode>((parts, index) => ({
    code: null,
    id: projectId,
    subparts: parts,
    option: createBlankStepOption("root"),
    children: recursiveCreateTree(parts, "PI", projectId)
  }));

  return trees
      .map(tree => convertTreeNodeToSubsteps(tree, null, serverProjects, oldSubsteps))
      .flat();
};

// const getUpdatedChildren = (newCurrSubstep: Substep, oldSusbteps: Substep[]): Substep[] => {

// }

// const syncSubsteps = (
//   previousSubsteps: Substep[],
//   newSubsteps: Substep[]
// ): Substep[] => {
//   const updatedSubsteps = newSubsteps
//     .filter(
//       substep =>
//         substep.stepLevel === 0 &&
//         previousSubsteps.find(prev => prev.backendProjectId === substep.backendProjectId)
//     )
//     .map();
// };

export const isSameCreationData = (
  optionA: SubstepCreationData,
  optionB: SubstepCreationData
): boolean => {
  if (optionA === null && optionB === null) {
    return true;
  }

  if (optionA === null) {
    return false;
  }

  if (optionB === null) {
    return false;
  }

  if (
    isPIOption(optionA) &&
    isPIOption(optionB) &&
    optionA.id === optionB.id &&
    optionA.codePI === optionB.codePI
  ) {
    return true;
  }

  if (isMeasuringStepOption(optionA) && isMeasuringStepOption(optionB)) {
    return true;
  }

  return optionA.id === optionB.id;
};

export const convertTreeNodeToSubsteps = (
  treeNode: TreeNode,
  parent: string | null,
  serverProjects: ServerProject[],
  oldSubsteps: Substep[]
): Substep[] | null => {
  if (!treeNode.option) {
    return null;
  }

  const selectedOptions =
    treeNode.children &&
    treeNode.children.length > 0 &&
    treeNode.children[0].option
      ? (treeNode.children
          .map(node => node.option)
          .filter(opt => opt !== null) as AnyStepOptions[])
      : null;

  const backendProjectId = treeNode.subparts[0].project;
  const stepLevel = treeNode.code ? getStepLevelForType(treeNode.code) : 0;
  const substepId = !parent
    ? (
        oldSubsteps.find(
          sub =>
            sub.stepLevel === 0 && sub.backendProjectId === backendProjectId
        ) || treeNode
      ).id
    : (
        oldSubsteps.find(
          old =>
            old.parent === parent &&
            isSameCreationData(old.creationData, treeNode.option)
        ) || treeNode
      ).id;

  return [
    {
      id: substepId,
      name: treeNode.option.labelShort ?? treeNode.option.label,
      creationData: treeNode.option,
      stepLevel,
      projectId: serverProjects.find(proj => proj["@id"] === backendProjectId)?.frontendId ?? replaceSlash(backendProjectId),
      backendProjectId,
      parent,
      selectedOptions,
      completed:
        stepLevel === stepTypesMap.length ? true : Boolean(selectedOptions)
    },
    ...(treeNode.children
      ? treeNode.children
          .map(node => convertTreeNodeToSubsteps(node, substepId, serverProjects, oldSubsteps))
          .flat()
      : [])
  ];

  // id: `${subpart["@id"]}-PI`,
  // name: subpart.labelPI,
  // creationData: convertServerOptionToStepOption(subpart.optionPI, "PI"),
  // stepLevel: getStepLevelForType("PI"),
  // projectId: subpart.project,
  // parent: rootSubstep.id,
  // substeps: [],
  // selectedOptions: null,
  // completed: false
};

/*
export const convertServerSubpartToSubsteps = (
  subparts: ServerProjectPart[]
): Substep[] => {
  const rootID = `${subparts[0].project}-root`;

  const substeps = subparts
    .map(subpart => {
      const stepOptions = {
        PI: convertServerOptionToPIOption(subpart.optionPI, subpart.codePI),
        TP: convertServerOptionToStepOption(subpart.optionTP, "TP"),
        NS: convertServerOptionToStepOption(subpart.optionNS, "NS"),
        ES: convertServerOptionToStepOption(subpart.optionES, "ES"),
        TE: convertServerOptionToStepOption(subpart.optionTE, "TE"),
        MA: convertServerOptionToStepOption(subpart.optionMA, "MA"),
        PR: convertServerOptionToStepOption(subpart.optionPR, "PR"),
        PO: convertServerOptionToStepOption(subpart.optionPO, "PO")
      };

      const optionPO: Substep = createSubstep(subpart, stepOptions["PO"], "PO");

      const piStep: Substep = {
        id: `${subpart["@id"]}-PI`,
        name: subpart.labelPI,
        creationData: convertServerOptionToStepOption(subpart.optionPI, "PI"),
        stepLevel: getStepLevelForType("PI"),
        projectId: subpart.project,
        parent: rootSubstep.id,
        substeps: [],
        selectedOptions: null,
        completed: false
      };

      return [piStep];
    })
    .flat();

  const rootSubstep: Substep = {
    id: rootID,
    stepLevel: 0,
    name: "root",
    creationData: null,
    parent: null,
    projectId: subparts[0].project,
    selectedOptions: action.payload.options,
    completed: true
  };

  return [rootSubstep, ...substeps];
};
*/
