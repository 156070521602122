import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {RouteComponentProps} from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import {
  saveProjectRequest,
  SaveProjectRequestPayloadOptions,
  duplicateProjectRequest
} from "../model/projects/projects-actions";
import { PropsType } from "../utils/ts-utils";
import { hasValidToken } from "../model/authentication/authentication-model";
import { Tra } from "../components/tra";
import { useT } from "./i18n-container";
import styles from "../styles/modules/project-list-entry.module.scss";

type OwnProps = RouteComponentProps<{ id?: string; projectId?: string }> & {
  buttonComponent: (props: { onClick: () => void, label?: string }) => JSX.Element;
  shouldEdit?: boolean;
  shouldArchive?: boolean;
  shouldDuplicate?: boolean;
  shouldSetAsTemplate?: boolean;
  shouldDelete?: boolean;
  shouldRedirect?: boolean;
  projectId?: string;
  loginModalText?: string;
  saveModalText?: string;
  saveButtonLabel?: string;
  buttonLabel?: string;
  connectButtonLabel?: string;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // finish: (projectId: string) => dispatch(finishProjectRequest(projectId))
  save: (projectId: string, options: SaveProjectRequestPayloadOptions) =>
    dispatch(saveProjectRequest(projectId, options)),
  duplicate: (projectId: string, projectLabel: string) =>
    dispatch(duplicateProjectRequest(projectId, projectLabel))
});

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  // guestUser: state.authentication.guest
  return (state: MainState, props: OwnProps) => {
    const id = props.projectId || props.match.params.projectId;
    return {
      project: id ? selector(state, id) : null,
      hasValidToken: hasValidToken(state.authentication)
    };
  };
};

const Comp: FunctionComponent<OwnProps &
  PropsType<
    OwnProps,
    ReturnType<typeof mapStateToProps>,
    typeof mapDispatchToProps
  >> = ({
  match,
  history,
  hasValidToken,
  project,
  save,
  duplicate,
  buttonComponent,
  loginModalText,
  saveModalText,
  shouldEdit,
  shouldDuplicate,
  shouldSetAsTemplate,
  shouldDelete,
  saveButtonLabel,
  shouldArchive,
  shouldRedirect,
  buttonLabel,
  connectButtonLabel
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [projectLabel, setProjectLabel] = useState("");

  const ButtonComponent = buttonComponent;

  const projectDirty = project?.dirty;
  const projectId = project?.id;

  useEffect(() => {
    if (projectDirty === false && saving === true) {
      setSaving(false);
      setShow(false);
      setProjectLabel("");
    }
  }, [projectDirty, saving]);

  const { t, ht } = useT();

  const saveClick = useCallback(() => {
    if (!projectId) {
      return null;
    }

    setSaving(true);
    if (shouldDuplicate) {
      duplicate(projectId, projectLabel);
    } else {
      save(projectId, {
        shouldEdit,
        shouldArchive,
        shouldDuplicate,
        shouldSetAsTemplate,
        shouldDelete,
        shouldRedirect
      });
    }
  }, [projectId, shouldDuplicate, duplicate, projectLabel, save, shouldEdit, shouldArchive, shouldSetAsTemplate, shouldDelete, shouldRedirect]);

  if (!project) {
    return null;
  }

  if (!hasValidToken) {
    const redir = encodeURI(match.url);

    return (
      <span>
        {/* <Button variant="primary">Editer le projet</Button> */}
        <ButtonComponent onClick={() => setShow(true)} label={connectButtonLabel}/>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Body>{loginModalText ?? ht("text-login-to-save")}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => history.push(`/login?redirect=${redir}`)}
            >
              <Tra code="btn-form-login" />
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              <Tra code="btn-cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }

  return (
    <span>
      <ButtonComponent
        onClick={() => {
          setShow(true);
        }}
        label={buttonLabel}
      />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          {!project.error ? (
            <React.Fragment>
              {saveModalText ?? ht("lbl-project-save")} {project.label} ?
              {shouldEdit ? (
                <Alert variant="warning">{ht("text-project-save")}</Alert>
              ) : null}
              { shouldDuplicate ? (
                <div className={styles["project-save"]}>
                  <form>
                    <label>{t("lbl-project-duplication-label")}</label>
                    <input
                      type="text"
                      name="label"
                      placeholder={t("lbl-form-placeholder-duplication-label")}
                      onChange={e => setProjectLabel(e.target.value)}
                      value={projectLabel}
                    />
                  </form>
                </div>
              ) : null }
            </React.Fragment>
          ) : (
            <Alert variant="danger">
              <Tra code="lbl-error" />
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveClick} disabled={projectDirty}>
            {project.dirty ? (
              <span>
                <Spinner
                  size="sm"
                  className="mr-1"
                  role="status"
                  animation="border"
                ></Spinner>
                <Tra code="lbl-saving" />
              </span>
            ) : (
              saveButtonLabel ??
              (shouldEdit ? ht("lbl-edit-pdf") : ht("lbl-save"))
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            <Tra code="btn-cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export const SaveProject = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comp)
);
