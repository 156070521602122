import { Dispatch } from "redux";
import { ActionType, createAction } from "typesafe-actions";
import {
  EditDeleteAccountState,
  EditProfileInfoState,
  EditProfilePasswordState,
  EditProfilePreferencesState
} from "../../containers/authentication/edit-profile";
import { EmailCheckState } from "../../containers/authentication/email-check";
import { LoginState } from "../../containers/authentication/login";
import { RegisterState } from "../../containers/authentication/register";
import { ApiError } from "../../utils/server-utils";
import { HydraCollectionResponse, UserData, EntityLanguage } from "./authentication-model";
import {ServerLanguages, ServerUnitSystems, ServerCountries, ServerDepartments, ServerProfessions} from "./authentication-service";
import {ResetPasswordState} from "../../containers/authentication/reset-password";

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR";

export const AUTH_REGISTER_REQUEST = "AUTH_REGISTER_REQUEST";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_ERROR = "AUTH_REGISTER_ERROR";

export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGOUT_ERROR";

export const AUTH_GUEST_REQUEST = "AUTH_GUEST_REQUEST";
export const AUTH_GUEST_SUCCESS = "AUTH_GUEST_SUCCESS";
export const AUTH_GUEST_ERROR = "AUTH_GUEST_ERROR";

export const AUTH_EMAIL_CHECK_REQUEST = "AUTH_EMAIL_CHECK_REQUEST";
export const AUTH_EMAIL_CHECK_SUCCESS = "AUTH_EMAIL_CHECK_SUCCESS";
export const AUTH_EMAIL_CHECK_ERROR = "AUTH_EMAIL_CHECK_ERROR";

export const AUTH_LANGUAGES_REQUEST = "AUTH_LANGUAGES_REQUEST";
export const AUTH_LANGUAGES_SUCCESS = "AUTH_LANGUAGES_SUCCESS";
export const AUTH_LANGUAGES_ERROR = "AUTH_LANGUAGES_ERROR";

export const AUTH_UNIT_SYSTEM_REQUEST = "AUTH_UNIT_SYSTEM_REQUEST";
export const AUTH_UNIT_SYSTEM_SUCCESS = "AUTH_UNIT_SYSTEM_SUCCESS";
export const AUTH_UNIT_SYSTEM_ERROR = "AUTH_UNIT_SYSTEM_ERROR";

export const AUTH_COUNTRIES_REQUEST = "AUTH_COUNTRIES_REQUEST";
export const AUTH_COUNTRIES_SUCCESS = "AUTH_COUNTRIES_SUCCESS";
export const AUTH_COUNTRIES_ERROR = "AUTH_COUNTRIES_ERROR";

export const AUTH_DEPARTMENTS_REQUEST = "AUTH_DEPARTMENTS_REQUEST";
export const AUTH_DEPARTMENTS_SUCCESS = "AUTH_DEPARTMENTS_SUCCESS";
export const AUTH_DEPARTMENTS_ERROR = "AUTH_DEPARTMENTS_ERROR";

export const AUTH_PROFESSIONS_REQUEST = "AUTH_PROFESSIONS_REQUEST";
export const AUTH_PROFESSIONS_SUCCESS = "AUTH_PROFESSIONS_SUCCESS";
export const AUTH_PROFESSIONS_ERROR = "AUTH_PROFESSIONS_ERROR";

export const AUTH_PICTURES_REQUEST = "AUTH_PICTURES_REQUEST";
export const AUTH_PICTURES_SUCCESS = "AUTH_PICTURES_SUCCESS";
export const AUTH_PICTURES_ERROR = "AUTH_PICTURES_ERROR";

export const AUTH_TO_DEFAULT = "AUTH_TO_DEFAULT";

export const AUTH_LANG_VALIDATION_REQUEST = "AUTH_LANG_VALIDATION_REQUEST";

export const AUTH_PASSWORD_EDIT_REQUEST = "AUTH_PASSWORD_EDIT_REQUEST";
export const AUTH_PASSWORD_EDIT_SUCCESS = "AUTH_PASSWORD_EDIT_SUCCESS";
export const AUTH_PASSWORD_EDIT_ERROR = "AUTH_PASSWORD_EDIT_ERROR";

export const AUTH_PASSWORD_RESET_REQUEST = "AUTH_PASSWORD_RESET_REQUEST";
export const AUTH_PASSWORD_RESET_SUCCESS = "AUTH_PASSWORD_RESET_SUCCESS";
export const AUTH_PASSWORD_RESET_ERROR = "AUTH_PASSWORD_RESET_ERROR";

export const AUTH_PREFERENCE_EDIT_REQUEST = "AUTH_PREFERENCE_EDIT_REQUEST";
export const AUTH_PREFERENCE_EDIT_SUCCESS = "AUTH_PREFERENCE_EDIT_SUCCESS";
export const AUTH_PREFERENCE_EDIT_ERROR = "AUTH_PREFERENCE_EDIT_ERROR";

export const AUTH_EDIT_INFOS_REQUEST = "AUTH_EDIT_INFOS_REQUEST";
export const AUTH_EDIT_INFOS_SUCCESS = "AUTH_EDIT_INFOS_SUCCESS";
export const AUTH_EDIT_INFOS_ERROR = "AUTH_EDIT_INFOS_ERROR";

export const AUTH_DELETE_ACCOUNT_REQUEST = "AUTH_DELETE_ACCOUNT_REQUEST";
export const AUTH_DELETE_ACCOUNT_SUCCESS = "AUTH_DELETE_ACCOUNT_SUCCESS";
export const AUTH_DELETE_ACCOUNT_ERROR = "AUTH_DELETE_ACCOUNT_ERROR";

export const AUTH_DEFAULT_UNIT_SYSTEM_REQUEST = "AUTH_DEFAULT_UNIT_SYSTEM_REQUEST";
export const AUTH_DEFAULT_UNIT_SYSTEM_SUCCESS = "AUTH_DEFAULT_UNIT_SYSTEM_SUCCESS";
export const AUTH_DEFAULT_UNIT_SYSTEM_ERROR = "AUTH_DEFAULT_UNIT_SYSTEM_ERROR";

export const AUTH_USER_ROLE_REQUEST = "AUTH_USER_ROLE_REQUEST";
export const AUTH_USER_ROLE_SUCCESS = "AUTH_USER_ROLE_SUCCESS";
export const AUTH_USER_ROLE_ERROR = "AUTH_USER_ROLE_ERROR";

export const authPreferenceEditRequest = createAction(
  AUTH_PREFERENCE_EDIT_REQUEST,
  action => (data: EditProfilePreferencesState) => action(data)
);

export const authPreferenceEditSuccess = createAction(
  AUTH_PREFERENCE_EDIT_SUCCESS,
  action => (data: any) => action(data)
);

export const authPreferenceEditError = createAction(
  AUTH_PREFERENCE_EDIT_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authUserRoleRequest = createAction(
  AUTH_USER_ROLE_REQUEST,
  action => (id: number, roles: string[]) => action({
    id,
    roles
  })
);

export const authUserRoleSuccess = createAction(
  AUTH_USER_ROLE_SUCCESS,
  action => (id: number, roles: string[]) => action({
    id,
    roles
  })
);

export const authUserRoleError = createAction(
  AUTH_USER_ROLE_ERROR,
  action => (errors: any, id: number, roles: string[]) =>
    action({
      errors,
      id,
      roles
    })
);

export const authPasswordResetRequest = createAction(
  AUTH_PASSWORD_RESET_REQUEST,
  action => (data: ResetPasswordState) => action(data)
);

export const authPasswordResetSuccess = createAction(
  AUTH_PASSWORD_RESET_SUCCESS,
  action => (data: any) => action(data)
);

export const authPasswordResetError = createAction(
  AUTH_PASSWORD_RESET_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authPasswordEditRequest = createAction(
  AUTH_PASSWORD_EDIT_REQUEST,
  action => (data: EditProfilePasswordState) => action(data)
);

export const authPasswordEditSuccess = createAction(
  AUTH_PASSWORD_EDIT_SUCCESS,
  action => (data: any) => action(data)
);

export const authPasswordEditError = createAction(
  AUTH_PASSWORD_EDIT_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authLangValidateRequest = createAction(
  AUTH_LANG_VALIDATION_REQUEST,
  action => (data: EntityLanguage) => action(data)
);

export const authLanguagesRequest = createAction(AUTH_LANGUAGES_REQUEST);

export const authLanguagesSuccess = createAction(
  AUTH_LANGUAGES_SUCCESS,
  action => (data: ServerLanguages) => action(data)
);

export const authLanguagesError = createAction(
  AUTH_LANGUAGES_ERROR,
  action => (data: any) => action(data)
);

export const authUnitSystemRequest = createAction(AUTH_UNIT_SYSTEM_REQUEST);

export const authUnitSystemSuccess = createAction(
  AUTH_UNIT_SYSTEM_SUCCESS,
  action => (data: HydraCollectionResponse) => action(data)
);

export const authUnitSystemError = createAction(
  AUTH_UNIT_SYSTEM_ERROR,
  action => (data: any) => action(data)
);

export const authCountriesRequest = createAction(AUTH_COUNTRIES_REQUEST);

export const authCountriesSuccess = createAction(
  AUTH_COUNTRIES_SUCCESS,
  action => (data: HydraCollectionResponse) => action(data)
);

export const authCountriesError = createAction(
  AUTH_COUNTRIES_ERROR,
  action => (data: any) => action(data)
);

export const authDepartmentsRequest = createAction(AUTH_DEPARTMENTS_REQUEST);

export const authDepartmentsSuccess = createAction(
  AUTH_DEPARTMENTS_SUCCESS,
  action => (data: HydraCollectionResponse) => action(data)
);

export const authDepartmentsError = createAction(
  AUTH_DEPARTMENTS_ERROR,
  action => (data: any) => action(data)
);

export const authProfessionsRequest = createAction(AUTH_PROFESSIONS_REQUEST);

export const authProfessionsSuccess = createAction(
  AUTH_PROFESSIONS_SUCCESS,
  action => (data: HydraCollectionResponse) => action(data)
);

export const authProfessionsError = createAction(
  AUTH_PROFESSIONS_ERROR,
  action => (data: any) => action(data)
);

export const authPicturesRequest = createAction(AUTH_PICTURES_REQUEST);

export const authPicturesSuccess = createAction(
  AUTH_PICTURES_SUCCESS,
  action => (data: HydraCollectionResponse) => action(data)
);

export const authPicturesError = createAction(
  AUTH_PICTURES_ERROR,
  action => (data: any) => action(data)
);

export const authLoginRequest = createAction(
  AUTH_LOGIN_REQUEST,
  action => (data: LoginState, redirect: string | null = null) =>
    action({ data, redirect })
);
export const authLoginSuccess = createAction(
  AUTH_LOGIN_SUCCESS,
  action => (data: UserData, redirect: string | null = null) =>
    action({ data, redirect })
);

export const authLoginError = createAction(
  AUTH_LOGIN_ERROR,
  action => (errors: ApiError) =>
    action({
      errors
    })
);

export const authRegisterRequest = createAction(
  AUTH_REGISTER_REQUEST,
  action => (data: RegisterState, redirect: string | null = null) =>
    action({ data, redirect })
);
export const authRegisterSuccess = createAction(AUTH_REGISTER_SUCCESS);
export const authRegisterError = createAction(
  AUTH_REGISTER_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authToDefault = createAction(AUTH_TO_DEFAULT);

export const authLogoutRequest = createAction(AUTH_LOGOUT_REQUEST);
export const authLogoutSuccess = createAction(AUTH_LOGOUT_SUCCESS);
export const authLogoutError = createAction(AUTH_LOGOUT_ERROR);

export const authGuestRequest = createAction(AUTH_GUEST_REQUEST);
export const authGuestSuccess = createAction(AUTH_GUEST_SUCCESS);
export const authGuestError = createAction(AUTH_GUEST_ERROR);

export const authEmailCheckRequest = createAction(
  AUTH_EMAIL_CHECK_REQUEST,
  action => (emailState: EmailCheckState) => action(emailState)
);

export const authEmailCheckSuccess = createAction(AUTH_EMAIL_CHECK_SUCCESS);

export const authEmailCheckError = createAction(
  AUTH_EMAIL_CHECK_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authEditInfosRequest = createAction(
  AUTH_EDIT_INFOS_REQUEST,
  action => (emailState: EditProfileInfoState) => action(emailState)
);

export const authEditInfosSuccess = createAction(AUTH_EDIT_INFOS_SUCCESS);

export const authEditInfosError = createAction(
  AUTH_EDIT_INFOS_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authDeleteAccountRequest = createAction(
  AUTH_DELETE_ACCOUNT_REQUEST,
  action => (data: EditDeleteAccountState, dispatch: Dispatch) =>
    action({ data, dispatch })
);

export const authDeleteAccountSuccess = createAction(
  AUTH_DELETE_ACCOUNT_SUCCESS,
  action => (data: any, dispatch: Dispatch) => {
    dispatch(authLogoutRequest());
    return action(data);
  }
);

export const authDeleteAccountError = createAction(
  AUTH_DELETE_ACCOUNT_ERROR,
  action => (errors: any) =>
    action({
      errors
    })
);

export const authDefaultUnitSystemRequest = createAction(AUTH_DEFAULT_UNIT_SYSTEM_REQUEST);

export const authDefaultUnitSystemSuccess = createAction(
  AUTH_DEFAULT_UNIT_SYSTEM_SUCCESS,
  action => (data: ServerUnitSystems) => action(data)
);

export const authDefaultUnitSystemError = createAction(
  AUTH_DEFAULT_UNIT_SYSTEM_ERROR,
  action => (data: any) => action(data)
);




const all = {
  authLanguagesRequest,
  authLanguagesSuccess,
  authLanguagesError,
  authLangValidateRequest,
  authUnitSystemRequest,
  authUnitSystemSuccess,
  authUnitSystemError,
  authCountriesRequest,
  authCountriesSuccess,
  authCountriesError,
  authDepartmentsRequest,
  authDepartmentsSuccess,
  authDepartmentsError,
  authProfessionsRequest,
  authProfessionsSuccess,
  authProfessionsError,
  authPreferenceEditRequest,
  authPreferenceEditSuccess,
  authPreferenceEditError,
  authPasswordEditRequest,
  authPasswordEditSuccess,
  authPasswordEditError,
  authPasswordResetRequest,
  authPasswordResetSuccess,
  authPasswordResetError,
  authEditInfosRequest,
  authEditInfosSuccess,
  authEditInfosError,
  authDeleteAccountRequest,
  authDeleteAccountSuccess,
  authDeleteAccountError,
  // authLanguagesValidateRequest,
  // authLanguagesValidateSuccess,
  // authLanguagesValidateError,
  authPicturesRequest,
  authPicturesSuccess,
  authPicturesError,
  authLoginRequest,
  authLoginSuccess,
  authLoginError,
  authRegisterRequest,
  authRegisterSuccess,
  authRegisterError,
  authLogoutRequest,
  authLogoutSuccess,
  authLogoutError,
  authGuestRequest,
  authGuestSuccess,
  authGuestError,
  authEmailCheckRequest,
  authEmailCheckSuccess,
  authEmailCheckError,
  authDefaultUnitSystemRequest,
  authDefaultUnitSystemSuccess,
  authDefaultUnitSystemError,
  authUserRoleRequest,
  authUserRoleSuccess,
  authUserRoleError,
  authToDefault
};

export type AnyAuthenticationAction = ActionType<typeof all>;
